@use '../abstracts' as a;

/* SLA custom tag */
.custella-prog-tag{
    color: a.$white;
    font-size: a.$s-fontsize;
    font-family: a.$form-font;
    border-radius: 2px;
    &.prog-start{
        background: a.$bg-prog-tag-start;
    }
    &.prog-risk{
        background: a.$bg-prog-tag-risk;
    }
    &.prog-violate{
        background: a.$bg-prog-tag-violate;
    }
}

/* Teams Indicator Colors */
.team-status{
    border-radius: 0.5rem;
    padding: 4px 12px;
    text-align: center;
    &.open{
        background: a.$bg-team-status-open;
    }
    &.cancel{
        background: a.$bg-team-status-cancel;
    }
    &.leave{
        background: a.$bg-team-status-leave;
    }
    &.completed{
        background: a.$bg-team-status-completed;
    }
    &.in-progress{
        background: a.$bg-team-status-progress;
    }
    &.incomplete{
        background: a.$bg-team-status-incomplete;
    }
    &.pending{
        background: a.$bg-team-status-pending;
    }
}

.p-tag {
    /* Task Indicator Colors */
    &.p-tag-open{
        background: a.$bg-team-status-open;
    }
    &.p-tag-cancel{
        background: a.$bg-team-status-cancel;
    }
    &.p-tag-leave{
        background: a.$bg-team-status-leave;
    }
    &.p-tag-completed{
        background: a.$bg-team-status-completed;
    }
    &.p-tag-in-progress{
        background: a.$bg-team-status-progress;
    }
    &.p-tag-incomplete{
        background: a.$bg-team-status-incomplete;
    }
    &.p-tag-pending{
        background: a.$bg-team-status-pending;
    }

    &.pending-survey{
        background-color: rgb(230, 230, 230); color: rgb(35, 39, 37);
    }
    &.collect{
        background-color: rgb(196, 255, 225); color: rgb(31, 68, 49);
    }


    &.p-tag-in-low{
        background: #6FBC60
    }
    &.p-tag-medium{
        background: #E88901
    }
    &.p-tag-high{
        background: #D34747
    }

}
.p-tag {
    /* Planih hub  Indicator Colors */
    &.p-tag-draft{
        background: #c4c8cb;
    }
    &.p-tag-dispatched{
        background: #dcabff;
    }
    &.p-tag-confirmed{
        background: #84c8f3;;
    }
    &.p-tag-Inprogress {
        background: #fecd55;
    }
    &.p-tag-in-progress{
        background: a.$bg-team-status-progress;
    }
    &.p-tag-incomplete{
        background: a.$bg-team-status-incomplete;
    }
    &.p-tag-cancel{
        background: a.$bg-team-status-cancel;
    }
    &.p-tag-completed{
        background: a.$bg-team-status-completed;
    }

    &.p-checklist-tag-new{
        background:#C4E5EA;
    }
    &.p-checklist-tag-draft{
        background:#E4E4E4;
    }
    &.p-checklist-tag-completed{
        background:#C6FDC6;
    }
    &.p-checklist-tag-cancelled{
        background:#B83C08;
    }

}

.p-tag{
    /* Okay */
    &.active, &.complete, &.approve, &.received{
        background-color: #aaf7bd !important ;
    }
    /* Caution */
    &.pending, &.maintenance, &.enroute, &.on-loan{
        background-color: #f7eb83 !important ;
    }
    /* Unavailable */
    &.expire, &.on-rent, &.permanent, &.under-repair, &.sold, &.written-off{
        background-color: #c9c9c9 !important ;
    }
    /* Alert */
    &.terminate, &.overdue, &.rejected{
        background-color: #ff716c !important ;
    }
    /* Available */
    &.draft, &.inprogress, &.applied, &.depot, &.new, &.contract-new, &.return, &.spare, &.used-machine{
        background-color: #9de7ff !important ;
    }
    /* Warning */
    &.cancel, &.incomplete, &.cancelled{
        background-color: #ffa77f !important ;
    }


    &.maintenance-two{
        background-color: #ce4343 !important ;
    }
    &.jmp-submit{
        background-color: #C4C8CB50 !important ;
    }
    &.jmp-approve{
        background-color: #1aac2050 !important ;
    }
    &.jmp-def{
        background-color: #db538350 !important ;
    }
    // &.new {
    //     background-color: #B4FFEE !important ;
    //     color: #B4FFEE
    // }
}

.title-tag{
    &.p-tag{
        border-radius: 5px 0 0 5px !important;
    }
}
.slider-tag{
    &.p-tag{
        border-radius: 4px 0 0 4px !important;
    }
}