@use '../abstracts' as a;

/* Tooltip width in pre-assign */
.tooltip-big-width {
   width: 350px !important;
   height: 50px !important;
}

/* Tooltip reset */
.p-tooltip {
    .p-tooltip-text {
        background: a.$dark-color2 !important;
        color: a.$white !important;
        font-family: a.$form-font !important;
        font-size: a.$s-fontsize;
        // padding: 10px !important;
        // border-radius: 4px !important;
    }

    &.p-tooltip-right {
        .p-tooltip-arrow {
            border-right-color: transparent !important;
        }
    }

    &.p-tooltip-left {
        .p-tooltip-arrow {
            border-left-color: transparent !important;
        }
    }

    &.p-tooltip-top {
        .p-tooltip-arrow {
            border-top-color: transparent !important;
        }
    }

    &.p-tooltip-bottom {
        .p-tooltip-arrow {
            border-bottom-color: transparent !important;
        }
    }
}


.b-gridbase.b-outer{
    width: 100%;
}
.b-sch-style-border.b-sch-custom-color > .b-sch-event:not(.b-milestone) .b-sch-event-content{
    color: #000 !important;
}


/***Brythum Tooltip***/
.b-tooltip-content{
    background: transparent;
    color:#000 !important;
    padding: 0 !important;
}

/* Task Map */
.taskMap{
    .gm-ui-hover-effect{
        margin: 10px !important;
        position: absolute !important;
        right: -15px !important;
        top: -15px !important;
        bottom: 0 !important;
    }
    .gm-style-iw-ch{
        padding-top: 0px !important;
        display: none !important;
    }
    .gm-style-iw-d{
        width: 250px !important;
    }
} 