@use '../abstracts' as a;

.p-message .p-message-wrapper{
    padding: 0.3rem 1rem !important;
}

.max-size .p-fileupload .p-messages{    
    position: absolute;
    right: 21px; //CS-4641
    transform: translate(10px, 31px);
    z-index: 99;
}



/* ---------------- CHATBOT STYLINGS ---------------- */
.show-bot {
    .chatbot {
      transform: scale(1);
      opacity: 1;
      pointer-events: auto;
      transition: all 0.3s ease; // Smooth transition for visibility
    }
    
    .toggle-bot {
      i {
        &:first-child {
          opacity: 0; // Hide the message-bot icon
          transition: opacity 0.3s ease;
        }
        &:last-child {
          opacity: 1; // Show the chevron-down icon
          transition: opacity 0.3s ease;
        }
      }
    }
  }
.chatbot{
    position: fixed;
    right: 40px;
    bottom: 90px;
    width: 380px;
    background: a.$white;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: a.$form-shadow;
    transform: scale(0.9);
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease; // Initial hidden state
    header{
        background: a.$primary-color;
        text-align: left;
        padding: 12px 18px;
        display: flex;
        gap: 6px;
        h3{
            color: a.$white;
            // font-family: a.$form-font-medium;
            font-size: 14px;
            margin-bottom: 0px;
        }
        i{
            color: #FFD43B;
        }
    }
    .chatbox {
        height: 275px;
        overflow-y: scroll;
        padding: 15px 0 50px 15px;
      
        table {
          width: 100%;
          border-collapse: collapse;
          margin: 10px 0;
          thead, tbody, tfoot, tr, td, th {
            border-color: inherit;
            border-style: solid;
            border-width: 0;
          }
          tbody > tr > td {
              text-align: left;
              border: 1px solid #e9ecef;
              border-width: 0 0 1px 0;
              padding: 0.5rem 1rem;
          }

          tbody tr{
            background: #ffffff;
            color: #495057;
            transition: box-shadow 0.2s;
            outline-color: #c1c2f9;
          }
          th {
            text-align: left;
            padding: 0.5rem 1rem;
            border: 1px solid #e9ecef;
            border-width: 0 0 1px 0;
            font-weight: 600;
            color: #495057;
            background: #f8f9fa;
            transition: box-shadow 0.2s;
            font-size: 12px;
          }
        }
        .chat {
          display: flex;
      
          /* Default outgoing message styles */
          .chat-message {
            font-family: "Inter-Regular";
            background: a.$primary-color;
            color: a.$white;
            font-size: 12px;
            border-radius: 10px 10px 0 10px;
            padding: 14px;
            max-width: 78%;
            p{
              font-family: "Inter-Regular";
              color: #212121;
            }
            b, strong {
              font-weight: bold !important;
              color: #212121;
              font-family: "Inter-SemiBold";
              font-weight: 600 !important;
            }
          }
      
          /* Incoming message styles */
          &.incoming {
            .chat-message {
              background: #f2f2f2;
              color: a.$black;
              border-radius: 10px 10px 10px 0;
            }
      
            i {
              height: 25px;
              width: 25px;
              color: #39a8c9;
              background: a.$black;
              border-radius: 50%;
              text-align: center;
              line-height: 29px;
              margin: 0px 10px 7px 0px;
              align-self: flex-end;
            }
          }
      
          /* Outgoing message styles */
          &.outgoing {
            justify-content: flex-end;
            margin: 15px 0;
          }
        }
      }
      
    .chat-input{
        position: absolute;
        bottom: 0;
        border-top: 1px solid #ccc;
        width: 100%;
        background: a.$white;
        padding: 5px 12px;

        display: flex;
        gap: 5px;
        textarea{
            border: none;
            outline: none;
            font-size: 12px;
            resize: none;
            height: 55px;
            padding: 8px 15px 16px 8px;
            &.chat-textarea{
                min-height: unset;
                height: 55px;
            }
        }
        span{
            align-self: flex-end;
            height: 55px;
            line-height: 55px;
            cursor: pointer;
            color: a.$grey6;
            font-size: 14px;
            &:hover{
                color: a.$primary-color;
            }
        }
    }
}
.toggle-bot{
    position: fixed;
    right: 40px;
    bottom: 30px;
    height: 50px;
    width: 50px;
    color: #39a8c9;
    background: a.$black;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    i {
        position: absolute;
        &:first-child {
          opacity: 1; // Initially show the message-bot icon
        }
        &:last-child {
          opacity: 0; // Initially hide the chevron-down icon
        }
    }
}
