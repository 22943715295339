@use '../abstracts' as a;

.b-gridbase.b-grid-translate .b-grid-row, .b-gridbase.b-grid-translate3d .b-grid-row{
    min-height: 40px;
}
.b-gridbase .b-timeline-subgrid .b-grid-row.b-selected:not(.b-group-row){
    min-height: 40px;
}
.b-sch-event-wrap:not(.b-milestone-wrap){
    height: 32px;
}
.viewRoute{
    position: absolute;
    right: 10px
}

.b-tree-cell-value, .b-sch-header-row:last-child .b-sch-header-timeaxis-cell{
    font-size: 13px;
}
.b-sch-style-rounded-1 .b-sch-dirty-new{
    background: #324955;
    border-radius: 7px;

}
.b-sch-style-rounded-2 .b-sch-dirty-new{
    background: #cb5859;
    border-radius: 7px;

}

.b-panel {
    .b-panel-body-wrap{
        background: transparent !important;
    }
    &.b-html{
        .b-panel-content{
            margin: 0 !important;
            padding: 0 !important;
        }
    } 

}

.b-panel .b-panel-body-wrap{}
// BRYNTHUM CSS TIMELINE TRAVEL -- START

.b-sch-style-taskBufferStart {
    border-top: 2px dashed rgb(99, 102, 241);
    border-left: 2px dashed rgb(99, 102, 241);
    border-bottom: 2px dashed rgb(99, 102, 241);

    border-radius: 3px;

    font-size: 10px;
    color: white;
    width: fit-content;
}

.b-sch-style-taskBufferStart .b-sch-event {
    background: none;
}

.b-sch-style-taskBufferStart .b-sch-event .b-sch-event-content {
    font-size: 12px;
    color: rgb(99, 102, 241);
    font-weight: bold;
}

.b-sch-style-taskBufferEnd {
    border-top: 2px dashed rgb(99, 102, 241);
    border-right: 2px dashed rgb(99, 102, 241);
    border-bottom: 2px dashed rgb(99, 102, 241);

    border-radius: 3px;
  
    font-size: 10px;
    color: white;
    width: fit-content;
}

.b-sch-style-taskBufferEnd .b-sch-event {
    background: none;
}

.b-sch-style-taskBufferEnd .b-sch-event .b-sch-event-content {
    font-size: 12px;
    color: rgb(99, 102, 241);
    font-weight: bold;
}

.b-sch-style-taskBufferStartNext {
    border-top: 2px dashed #000000;
    border-left: 2px dashed #000000;
    border-bottom: 2px dashed #000000;

    border-radius: 3px;
  
    font-size: 10px;
    color: white;
    width: fit-content;
}

.b-sch-style-taskBufferStartNext .b-sch-event {
    background: none;
}

.b-sch-style-taskBufferStartNext .b-sch-event .b-sch-event-content {
    font-size: 12px;
    color: rgb(99, 102, 241);
    font-weight: bold;
}

.b-sch-style-taskBufferEndNext {
    border-top: 2px dashed #000000;
    border-right: 2px dashed #000000;
    border-bottom: 2px dashed #000000;

    border-radius: 3px;
  
    font-size: 10px;
    color: white;
    width: fit-content;
}

.b-sch-style-taskBufferEndNext .b-sch-event {
    background: none;
}

.b-sch-style-taskBufferEndNext .b-sch-event .b-sch-event-content {
    font-size: 12px;
    color: rgb(99, 102, 241);
    font-weight: bold;
}


// BRYNTHUM CSS TIMELINE TRAVEL -- END
