@use '../abstracts' as a;

/* Dashboard Styling */
.custella-dashboard-container {
    height: 100vh;
    margin-left: 200px;
}

/* Fleet Dashboard */
.todays-order-container {
    height: 100%;
    background: #F9F9F9;
    border: 1px solid #dee2e6;
    border-radius: 0.775rem !important;

    #{&}-title {
        background: a.$white;
        color: a.$black2;
        padding: 0.85rem 1.75rem;
        border-top-left-radius: 0.775rem !important;
        border-top-right-radius: 0.775rem !important;
    }

    #{&}-content {
        height: 77.5vh;
        padding: 1.5rem 1.5rem;
        overflow-y: scroll;

        .order-card {
            background: a.$white2;
            color: a.$black;
            box-shadow: a.$form-shadow;
            border: 1px solid #dedede;
            border-radius: 0.5rem;
            overflow: hidden;
            margin-bottom: 0.5rem;
            cursor: pointer;

            .p-avatar img {
                width: 22px !important;
                height: 22px !important;
                border-radius: 25px !important;

            }

            &:hover {
                border: 1px solid #6366F1;
            }

            &:active {
                border: 1px solid #6366F1;
            }

            &:focus {
                border: 1px solid #6366F1;
            }


        }
    }

    #{&}-content-graph {
        height: 300px;
        padding: 1.5rem 1.5rem;
        overflow-y: scroll;
    }

    #{&}-content-short {
        height: 410px;
        padding: 1.5rem 1.5rem;
        overflow-y: scroll;
        .order-card {
            background: a.$white2;
            color: a.$black;
            box-shadow: a.$form-shadow;
            border: 1px solid #dedede;
            border-radius: 0.5rem;
            overflow: hidden;
            margin-bottom: 0.5rem;
            cursor: pointer;

            .p-avatar img {
                width: 22px !important;
                height: 22px !important;
                border-radius: 25px !important;

            }

            &:hover {
                border: 1px solid #6366F1;
            }

            &:active {
                border: 1px solid #6366F1;
            }

            &:focus {
                border: 1px solid #6366F1;
            }


        }
    }
}