@use '../abstracts' as a;

/* Header/Topbar */
.custella-topbar-left {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    #custella-menu-button img{
        width: 24px;
        height: 24px;
        // margin-top: -1px;
    }

    .p-button {
        width: 80px !important;
        position: relative;
        margin-left: 1rem;
        margin-right: 1rem;
        margin-top: 0.1rem;
        font-weight: a.text-weight(medium);
    }

    .custella-topbar-title {
        font-family: a.$form-font  !important;
        font-size: a.$l-fontsize  !important;
        color: a.$white  !important;
        font-weight: a.text-weight(medium);
        padding-top: 5px !important;
    }
}


/* Title only Header */
.custella-title-header {
    position: relative;
    top: 0;
    width: 100%;
    height: 65px;
    background: a.$dark-color2;
    color: a.$white;
    overflow-x: hidden;

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
        height: 60px;

    }
}

.custella-logo-header {
    display: flex;
    position: relative;
    padding: 15px;

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
        padding: 10px 15px;

    }

    img {
        position: relative;
        width: 40px;
        height: 40px;
    }

    label {
        position: relative;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        font-family: a.$form-font  !important;
        font-size: a.$xl-fontsize  !important;
        color: a.$white  !important;
        font-weight: a.text-weight(medium);
    }
}

/* Dropdown Profile */
.profie .p-avatar.display-dp{
    width: 24px !important;
    height: 24px !important;

}
ul.dropdown-menu {
    background: a.$dark-color3;
    color: a.$white;

    li a.dropdown-item {
        color: a.$white  !important;
        
        &:hover{
            background: a.$dark-color;
            color: a.$white !important;
            border-radius: 4px !important;
        }
        &:focus{
            background: a.$dark-color;
            color: a.$white !important;
            border-radius: 4px !important;
        }
        &:active{
            background: a.$dark-color;
        }
        &.active{
            background: a.$dark-color !important;
            color: a.$white !important;
            border-radius: 4px !important;  
        }
    }
    li a.custella-service.dropdown-item {
        color: a.$white  !important;
        
        &:hover{
            background: a.$purple;
            color: a.$white !important;
            border-radius: 4px !important;
        }
        &:focus{
            background: a.$purple;
            color: a.$white !important;
            border-radius: 4px !important;
        }
        &.active{
            background: a.$primary-color;
            color: a.$white !important;
        }
        .disabledMenu{
             opacity:0.6
        }
    }
    // /* Remove active background color only for language dropdown items */
    // li.custella-lang button {
    //     &:active{
    //         background: transparent !important; /* Remove active background */
    //         color: inherit !important; /* Keep text color as is */
    //         outline: none !important;
    //     }
    // }
    li div{
        color: a.$white;
    }
    li small{
        color: a.$white;
    }
}
ul.dropdown-menu.show{
    padding: 5px;
}
.custella-notification.show{
    width: 20vw !important;
    transform: translate(0px, 47px) !important;
    @media (max-device-width: a.$large-screen-up) and (orientation: landscape) {
        width: 28vw !important;

    }
}
@media (min-width: 1920px) {
    .custella-notification-body {
     height: 22vh;
    }
  }
  
  @media (max-width: 1600px) {
    .custella-notification-body {
     height: 42vh;
    }
  }
.custella-notification-body
.custella-service.show{
    transform: translate(-15px, 47px) !important;
    padding: 1.5rem 0 !important;
}

.notifcation-active{
    background: a.$dark-color;
    color: a.$white !important;
    border-radius: 4px !important;
}
/* Online Badge in Profile Settings */
.custella-online{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: a.$green-online;
    border: a.$green-online;
    bottom: 8px;
    right: 0;
    position: absolute;
    &.chat{
        bottom: 5px;
    }
}
.custella-offline{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: a.$red-offline;
    border: a.$red-offline;
    bottom: 8px;
    right: 0;
    position: absolute;
}
.custella-online-mini{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: a.$green-online;
    border: a.$green-online;
    bottom: 4px;
    right: 5px;
    position: absolute;
}
.custella-offline-mini{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: a.$red-offline;
    border: a.$red-offline;
    bottom: 4px;
    right: 5px;
    position: absolute;
}
.custella-go-online{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #6EEAB3;
    border: #6EEAB3;
    margin-right: 7px;
}
.custella-go-offline{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: a.$red-offline;
    border: a.$red-offline;
    margin-right: 7px;
}

/* Dropdown Profile */
ul.dropdown-status {
    background: a.$white;
    color: a.$black;
    width: 100%;

    li a.dropdown-item {
        color: a.$black  !important;
        
        &:hover{
            background: a.$lightPurple2;
            border-radius: 4px !important;
        }
        &:focus{
            background: a.$lightPurple2;
            border-radius: 4px !important;
        }
        &:active{
            background: a.$lightPurple2;
        }
    }
    li a.custella-service.dropdown-item {
       
        
        &:hover{
            background: a.$purple;
            border-radius: 4px !important;
        }
        &:focus{
            background: a.$primary-color;
            border-radius: 4px !important;
        }
        &:active{
            background: a.$primary-color;
            color: a.$white !important;
        }
    }
    li div{
        color: a.$white;
    }
    li small{
        color: a.$white;
    }
}
.dropdown .custella-profile div.profile{
    background: #1a2428;
    color: #fff;
}

ul.dropdown-collaps {
    background: a.$white;
    color: a.$black;
    position: fixed !important;
    &.assets-tag{
        z-index: 3;
        max-height: 160px;
        overflow-y: scroll;
    }

    // &.show {
    //    transform: translate(-21px, 146px) !important;
    //   }
}

/* Dropdown change language */
.custella-lang .accordion{
    --bs-accordion-bg: transparent;
}
.custella-lang-menu .accordion-item button{
    cursor: pointer;
    &:hover{
        background: a.$dark-color;
        color: a.$white !important;
        border-radius: 4px !important;
    }
    &:focus{
        background: a.$dark-color;
            color: a.$white !important;
            border-radius: 4px !important;
    }
}
.custella-lang-menu .accordion-item ul li{
    cursor: pointer;
    &:hover{
        background: a.$dark-color;
        color: a.$white !important;
        border-radius: 4px !important;
    }
    &:focus{
        background: a.$dark-color;
            color: a.$white !important;
            border-radius: 4px !important;
    }
}
.custella-lang-menu .accordion-button::after {
    display: none !important;
}
.custella-lang .accordion-button:not(.collapsed){
    background: transparent;
    color: #E0E0E0;
    box-shadow: none;
}

/* Using Dropdown-bootstrap */
.custella-lang.dropdown button{
    cursor: pointer;
    display: flex !important;
    align-items: center !important;
    background: transparent !important;
    box-shadow: none;
    border: none !important;
    &:hover{
        background:a.$dark-color !important;
        color: a.$white !important;
        border-radius: 4px !important;
    }
    &:focus{
        background: a.$dark-color;
            color: a.$white !important;
            border-radius: 4px !important;
    }
}
.custella-lang.dropdown ul{
    &.sel-lang{
        position: absolute;
        inset: 0px 0px auto auto;
        margin: 0px;
        transform: translate3d(-43px, 36px, 0px) !important;
    }
    li{
        cursor: pointer;
        &:hover{
            background: a.$dark-color;
            color: a.$white !important;
            border-radius: 4px !important;
        }
        &:focus{
            background: a.$dark-color;
                color: a.$white !important;
                border-radius: 4px !important;
        }
    }
}

// disable top hehader service/sales/fleet style
.topbar .disabledMenu a {
    opacity: 0.6;
}

// disable top hehader service/sales/fleet style hover
.topbar .disabledMenu a.dropdown-item:hover {
    background: transparent !important;
}
