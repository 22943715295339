@use '../abstracts' as a;

/* Upload Files design*/
.custella-upload .p-fileupload .p-fileupload-buttonbar{
    display: none !important;
}

.custella-upload .p-fileupload .p-fileupload-content{
    position: relative !important;
    display: block !important;
    justify-content: center !important;
    text-align: center !important;
    padding: 30px 30% !important;
    border: 1px dashed a.$grey !important;
    border-radius: 4px !important;
    width: 100% !important;
    height: 150px !important;
    background: a.$lightGrey !important;
    border-radius: 4px !important;
}

.p-fileupload .p-fileupload-buttonbar{
    height: 52px !important;
    padding: 0.75rem !important;
}

.p-fileupload .p-fileupload-content{
    padding: 1rem !important;
}



.upload{
    .p-fileupload{
        .p-fileupload-content.p-fileupload-highlight{
            background: #f0f0f0 !important;
        }
    }
}

/* p-fileupload component inside modal body - 02/09/24 -ina*/
.modal-upload{
    .p-fileupload.p-fileupload-basic{
        display: flex !important;
        flex-direction: column-reverse !important;
        .p-messages{
            position: relative !important;
            left: 0 !important;
        }
        .p-button{
            width: fit-content !important;
            &.p-fileupload-choose{
                position: relative !important;
            }
        }
    }
}

/* AI Upload Drag Drop */
.upload-box {
    border: 2px dashed #6366F1A3;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;

    position: absolute;
    top: 200px;
    left: 40%;

    display: flex;
    align-items: center;
    gap: 5px;
    &-image{
        padding: 8px;
        img{
            width: 120px;
        }
    }
    &-content{
        display: flex;
        flex-direction: column;
        gap: 4px;
        .text{
            font-size: 14px;
            color: a.$black;
            font-family: a.$form-font-medium;
            margin-bottom: 0 !important;
        }
        .subtext{
            font-size: 12px;
            color: #6366F1A3;
            font-family: a.$form-font;
            margin-bottom: 0 !important;
        }
        .file-input {
            display: none;
        }
    }
    .dragover {
        border-color: #007bff;
    }
    &.dragging{
        background-color: #EBEBF0;
    }
}