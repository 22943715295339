.p-confirm-popup {
    background: $overlayContentBg;
    color: $panelContentTextColor;
    border: $overlayContentBorder;
    border-radius: $borderRadius;
    box-shadow: $overlayContainerShadow;

    .p-confirm-popup-content {
        padding: $panelContentPadding;
    }

    .p-confirm-popup-footer {
        text-align: right;
        padding: $panelFooterPadding;

        button {
            margin: 0 $inlineSpacing 0 0;
            width: auto;

            &:last-child {
                margin: 0;
            }
        }
    }

    &:after {
        border: solid transparent;
        border-color: rgba($overlayContentBg, 0);
        border-bottom-color: $overlayContentBg;
    }

    &:before {
        border: solid transparent;

        @if (nth($overlayContentBorder, 2) == 'none') {
            border-color: rgba($overlayContentBg, 0);
            border-bottom-color: $overlayContentBg;
        }
        @else {
            border-color: rgba(nth($overlayContentBorder, 3), 0);
            border-bottom-color: nth($overlayContentBorder, 3);
        }
    }

    &.p-confirm-popup-flipped {
        &:after {
            border-top-color: $overlayContentBg;
        }

        &:before {
            @if (nth($overlayContentBorder, 2) == 'none') {
                border-top-color: $overlayContentBg;
            }
            @else {
                border-top-color: nth($overlayContentBorder, 3);
            }
        }
    }

    .p-confirm-popup-icon {
        font-size: $primeIconFontSize * 1.5;
    }

    .p-confirm-popup-message {
        margin-left: $inlineSpacing * 2;
    }
}
.overLay-search{
    width: 88vh;
    right: 4rem;
    position: absolute;
    z-index: 103;
    background: #fff;
    left: 0;
    height: 57vh;
    padding: 1rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
