@use '../abstracts' as a;

/* Overview SLA */
.custella-bar .p-progressbar{
    border-radius: 2px !important;
    height: 1.4rem;
    margin-right: 1.5rem !important;
}

.custella-bar .p-progressbar .p-progressbar-value{
    background: a.$bg-progress !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

.custella-bar .p-progressbar-determinate .p-progressbar-label{
    text-align: start !important;
    font-size: a.$normal-fontsize !important;
    font-family: a.$form-font !important;
    font-weight: 500;
    color: a.$white !important;
    padding-top: 0.25rem !important;
    padding-left: 1rem !important;
}
/* Expand SLA */
.custella-long-bar .p-progressbar{
    border-radius: 2px !important;
    margin-right: -5px !important;
}
.custella-long-bar .p-progressbar .p-progressbar-value{
    background: a.$bg-progress !important;
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

.custella-long-bar .p-progressbar-determinate .p-progressbar-label{
    display: none !important;
    text-align: start !important;
    font-size: 10px !important;
    font-family: a.$form-font !important;
    font-weight: 500;
    color: a.$white !important;
    padding-left: 1rem !important;
}

// Round Progress
.progress-circle{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 180px;
    width: 180px;
    text-align: center;
}
.progress-circle div{
    position: absolute;
    height: 180px;
    width: 180px;
    border-radius: 50%;
}
.progress-circle{
    #{&}-background{
        background: transparent;
    }
    #{&}-rotate{
        background: blueviolet;
        clip: rect(0 70px 200px 0);
        transition: transform 1000ms linear 0s;
        transform: rotate(360deg);
    }
    #{&}-right{
        background: a.$bg-progress;
        clip: rect(0 109px 200px 0);
        transform: rotate(270deg);
    }
    #{&}-left{
        background: a.$bg-progress-load;
        clip: rect(0 109px 200px 0);
        transform: rotate(90deg);
    }
    #{&}-title{
        position: absolute;
        height: 150px;
        width: 150px;
        top: 1.15rem;
        left: 13.5px;
        padding-top: 3.5rem;
        text-align: center;
        border-radius: 50%;
        background-color: a.$white;
    }
}

// Dropdown Progress
.custella-sla-progress .dropdown-menu.show{
    padding-top: 0 !important;

}

/* STORAGE PROGESSBAR IN AI-TRAINING */
.storage-indicator{
    .p-progressbar{
        height: 7px !important;
        .p-progressbar-label{
            display: none !important;
        }
    }
}