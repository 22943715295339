@use '../abstracts' as a;

/* Leftbar */
.wrapper .sidebar{
    .sidebar-scroll-content{
        padding-top: 55px;
        .bottom-menu{
            // position: fixed;
            // display: flex;
            // justify-content: center;
            // bottom: 0;
            // width: 172px;
            // padding: 0.85rem 1.75rem;
            // background: a.$white;
            // color: #212121;
            // div{
            //     cursor: pointer;
            //     &:hover{
            //         background-color: #f1f0f1;
            //         border-radius: 4px;
            //     }  
            //     &.active-icon{
            //         background: #f1f0f1;
            //         border-radius: 4px;
            //     }
            // }
            display: flex;
            justify-content: space-evenly;
            height: 5%;
            li{
                display: flex;
                flex: 1;
                justify-content: center;
                cursor: pointer;
                padding-top: 3px;
                span{
                    display: flex;
                    align-items: center;
                }
                &:hover, &.active-icon{
                    background: #f1f0f1;
                }
            }
        }
    }
}
@media (max-height: 540px) {
    .wrapper .sidebar{
        .sidebar-scroll-content{
            .bottom-menu{
                height: 7%;
            }
        }
    }
  }
.wrapper .sidebar ul li > a{
    border-top: none !important;
    border-bottom: none !important;
    font-weight: 300;
    height: 35px;
    &.pm-h{
        height: 45px;
    }
    span{
        margin-left: 0.6rem;
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        &.pm{
            margin-left: 0.8rem;
        }
    }
    .menuSubText{
        position: relative;
        top: 4px;
        margin-left: 1.7rem;
    }
}
.custella-logo{
    display: flex;
    position: relative;
    padding: 15px;
    img{
        position: relative;
        margin-top: 0.75rem;
        width: 20%;
        height: 20%;

    }
    label{
        position: relative;
        margin-top: 1rem;
        margin-left: 0.5rem;
        font-family: a.$form-font !important;
        font-size: a.$xl-fontsize !important;
        color: a.$white !important;
    }
    i{
        position: relative;
        margin-top: 1.25rem;
        margin-left: 5rem;
        color: a.$white;
    }
}
.custella-mini-icon{
    position: relative;
    margin: 0 3px;
    height: 21px;
    width: 21px;
}
.custella-short-icon{
    position: relative;
    margin: 0 3px;
    height: 17px;
    width: 21px;
}

/* Reset Accordion bootstrap for setup leftbar*/
.custella-setup .custella-setup-menu{
    cursor: pointer;
    &:hover{
        background: #27343d;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        // padding-left: 0.75rem;
    }
    &:focus{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        // padding-left: 0.75rem;
    }
    a{
        &:hover{
            color: a.$white !important;
         }
    }
    &.active-menuitem{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        // padding-left: 0.75rem;
    }
}
.custella-setup .accordion{
    --bs-accordion-bg: transparent;
}
.custella-setup .accordion-button:not(.collapsed){
    background: transparent;
    color: a.$white;
    box-shadow: none;
}
.custella-setup .accordion-button:focus{
    border: none;
    box-shadow: none;
}
.custella-accordion-menu .accordion-item ul{
    text-decoration: none !important;
    list-style-type: none !important;
}
.custella-accordion-menu .staffTask .sub-topic .accordion-header .accordion-button{
    cursor: pointer;
    &:hover{
        background: #27343d;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
    }
    &:focus{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
    }
    &:active{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
    }
    &.active-menuitem{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
    }

}
.active-menuitem{
    background: #222e36;
    color: a.$white !important;
    border-radius: 4px !important;
    margin: 0;
    padding-left: 0.75rem;
    width: 100%;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
// .custella-accordion-menu .accordion-item .sub-topic li{
//     cursor: pointer;
//     &:hover{
//         background: #27343d;
//         color: a.$white !important;
//         border-radius: 4px !important;
//         margin: 0;
//     }
//     &:focus{
//         background: #222e36;
//         color: a.$white !important;
//         border-radius: 4px !important;
//         margin: 0;
//     }
// }
.custella-accordion-menu .accordion-item .sub-topic .setup-sub-manu-two{
    cursor: pointer;
    &:hover{
        background: #27343d;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        padding: 0 !important;
    }
    &:active{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        padding: 0 !important;
    }
    &:focus{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        padding: 0 !important;
    }
    &.active-menuitem{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        padding: 0 !important;
    }
}
.custella-accordion-menu .accordion-item .sub-topic .setup-sub-manu{
    cursor: pointer;
    height: 30px;
    &:hover{
        background: #27343d;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        padding: 0 !important;
    }
    &:active{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        padding: 0 !important;
    }
    &:focus{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        padding: 0 !important;
    }
    &.active-menuitem{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
        margin: 0;
        padding: 0 !important;
    }
}

.custella-accordion-menu .accordion-item ul li a{
    &:hover{
       color: a.$white !important;
    }
    .no-expand-topic{
        position: relative;
        top: 5px;
    }
}
.custella-setup .task-left div ul li{
    cursor: pointer;
    &:hover{
        background: #27343d;
        color: a.$white !important;
        border-radius: 4px !important;
    }
    &.active-menuitem{
        background: #222e36;
        color: a.$white !important;
        border-radius: 4px !important;
    }
}
.custella-setup .task-left div ul li a{
    &:hover{
       color: a.$white !important;
    }
}
.custella-accordion-menu .accordion-button::after {
    display: none !important;
}

.sub-topic div button{
    color: #fff;
    font-size: 12px;
    padding-left: 0.5rem !important;
}

.onSelected {
    background-color: #12191d;
    border-radius: 4px;
    padding-left: 0.75rem !important;
  }


/* Modules: Menu bar */
.custella-form-container-content-sidebar{
    .sidebar-scroll-content {
        display: block;
        height: 100%;
        position: relative;
        overflow: scroll;
        & > ul {
            padding-bottom: 40px;
            padding-left: 0px;
            li{
                text-decoration: none;
                color: a.$black2;
                padding: 7px 7px 7px 5px !important;
                text-align: center;
                margin-bottom: 3px;
                &:hover{
                    background: #f0f0f0;
                    border-radius: 4px;
                    cursor: pointer;
                }
                &:active, &:visited, &.active{
                    background: #f0f0f0;
                    border-radius: 4px;
                    // font-family: a.$form-font-semi-bold;
                    cursor: pointer;
                }
            }
        }
    }
}
