.p-sidebar {
    background: $overlayContentBg;
    color: $panelContentTextColor;
    border: $overlayContentBorder;
    box-shadow: $overlayContainerShadow;
    .p-sidebar-header {
        display:none;
    }
    .p-sidebar-header {
        padding: $panelHeaderPadding;

        .p-sidebar-close,
        .p-sidebar-icon {
            @include action-icon();
        }

        & + .p-sidebar-content {
            padding-top: 0;
        }
    }

    .p-sidebar-content {
        padding: $panelContentPadding;
    }
}
.p-sidebar-right {
    right: 10px !important;
    top: 10px !important;
    height: 97% !important;
    border-radius: 8px;
}
