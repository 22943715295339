@use '../abstracts' as a;

/* Tab Menu */
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link,.p-tabmenu  .p-menuitem-link.p-menuitem-active{
    color: a.$primary-color !important;
    background: a.$white !important;
    border-color: a.$primary-color !important;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus{
    outline: none;
    outline-offset: 0 ;
    box-shadow: none !important;
}