@use '../abstracts' as a;

.app-table .table {
    height: calc(100% - 73px) !important;
}

.cust-so .table{
    height: calc(100% - 73px) !important;
}
.cust-jo .table{
    height: calc(100% - 73px) !important;
}
.cust-asset .table{
    height: calc(100% - 73px) !important;
}
.cust-contracts .table{
    height: calc(100% - 73px) !important;
}
.cust-site .table{
    height: calc(100% - 73px) !important;
}
.cust-projects .table{
    height: calc(100% - 73px) !important;
}
.cust-do .table{
    height: calc(100% - 73px) !important;
}
.site-tix .table{
    height: calc(100% - 73px) !important;
}
.asset-mntnc .table{
    height: calc(100% - 73px) !important;
}
.ag-theme-balham .ag-paging-panel{
    height: 38px !important;
}