@use "../abstracts" as a;
@import "bootstrap";

@font-face {
  font-family: 'FontAwesome';
  src: url('../../fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
       url('../../fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
       url('../../fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype');
}
/* Custom Classname */
/* Color */
.bg-purple {
  background: a.$primary-color;
}

.bg-header {
  background: a.$headerblack;
}
.bg-header-two {
  background: a.$light-dark-color !important;
}
.bg-header-rounded{
  background: a.$headerblack;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.box-none{
  box-shadow: none !important;
}
.bg-darkBlue {
  background: a.$dark-color2;
}

.bg-white {
  background: a.$white;
}

.bg-white2 {
  background: a.$white3;
}

.bg-white4 {
  background: a.$white4;
}

.bg-grey {
  background: a.$lightGrey;
}

.bg-grey2 {
  background: a.$lightGrey2;
}

.bg-grey3 {
  background: a.$grey !important;
}

.color-var-500 {
  color: a.$lightGrey5;
}
.color-9e{
  color: #9E9E9E;
}
.color-86{
  color: #868686;
}
.color-56{
  color: #565656;
}
.color-77{
  color: #777 !important;
}

.color-primary {
  color: a.$primary-color;
}

.color-btn-grey {
  color: a.$dark-color4;
}

.color-primary-bg {
  background-color: a.$primary-color;
}

.color-none-bg {
  background-color: unset !important;
}

.font-green {
  color: a.$green;
}

.font-orange2 {
  color: a.$orange5;
}

.font-red {
  color: a.$red;
}

.font-orange {
  color: a.$orange-font;
}

.font-violet {
  color: a.$violet-font;
}

.font-white {
  color: a.$white;
}

.font-lightGrey {
  color: #e6e6e6;
}

.font-grey {
  color: #e0e0e0;
  opacity: 0.7;
}
.font-grey-icon {
  color: a.$grey2;
}

.font-black {
  color: a.$black;
}
.font-grey6{
  color: a.$grey6;
}

.border-rad-rev{
  border-radius: 4px !important;
}

/* Text Size */
.text-32 {
  font-size: 32px;
}

.text-28 {
  font-size: 28px;
}

.text-24 {
  font-size: 24px;
}

.text-20 {
  font-size: 20px;
}

.text-16 {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}

.text-14 {
  font-size: 14px;
}
.text-13 {
  font-size: 13.5px;
}
.text-13-og {
  font-size: 13px;
}
.text-10 {
  font-size: 10px !important;
}

.text-12 {
  font-size: a.$normal-fontsize !important;
}
.text-11 {
  font-size: 11px !important;
}

/* Font Weight */
.font-bold {
  font-family: a.$form-font-bold;
}

.font-semiBold {
  font-family: a.$form-font-semi-bold;
}

.font-medium {
  font-family: a.$form-font-medium;
}

.font-light {
  font-family: a.$form-font-light;
}
.font-italic{
  font-style: italic;
}

/* Font Spacing */
.font-space-1 {
  letter-spacing: 1px;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer;
}
.cursor-edit{
  cursor: text;
}
.cursor-default{
  cursor: default;
}

/* Cursor Not Allowed */
.cursor-not-allowed {
  cursor: not-allowed !important;
}

/* No Border */
.no-border {
  border: none;
}

.border-grey {
  color: a.$grey5;
  border: solid 1px;
}

.border-ph-grey {
  border-right: 1px solid a.$grey5;
}
.border-teams-grey {
  border-bottom: 1px solid a.$grey5;
}
.border-top-right{
  border-top-right-radius: 0.375rem;
}
.border-top-left{
  border-top-left-radius: 0.375rem;
}
.border-bottom-right{
  border-bottom-right-radius: 0.375rem;
}
.border-bottom-left{
  border-bottom-left-radius: 0.375rem;
}
.border-bottom-2px{
  border-bottom: 2px solid #dee2e6 !important
}

/* Display */
.flex-between{
  display:flex !important; 
  align-items:center !important; 
  justify-content: space-between !important;
}
.flex-align-center{
  display: flex !important;
  align-items: center !important;
}
.flex-align-baseline{
  display: flex !important;
  align-items: baseline !important;
}
.flex-align-end{
  display: flex !important;
  align-items: end !important;
}
.flex-end-between{
  display: flex !important;
  align-items: end !important;
  justify-content: space-between !important;
}
.flex-column-100{
    // border: solid red;
    display: flex;
    flex-direction: column;
    height: calc(100% - 12px);
    /* overflow: hidden; */
}
.flex-end{
  display: flex !important;
  justify-content: end !important;
}

.z-1{
  z-index: 1 !important;
}
.z-10{
  z-index: 10 !important;
}
/* Height */
.h-38 {
  height: 38px !important;
}

.h-35 {
  height: 34.7px !important;
}

.h-32 {
  height: 32px !important;
}

.h-30 {
  height: 30px !important;
}
.h-28 {
  height: 28px !important;
}

.h-17 {
  height: 17px !important;
}
.h-25 {
  height: 25px !important;
}
.h-20{
  height: 20px !important;
}
.btn-32{
  height: 32px;
  width: 32px;
}
.btn-28{
  height: 28px;
  width: 28px;
}

/* Height div */
.mh-350px{
  min-height: 350px !important;
}
.mh-44px{
  min-height: 44px !important;
}
.height-addMod{
  max-height: 257px;
}
.height-wp{
  height: 561px;
}
.height-30 {
  max-height: 30vh;
}
.height-30-stat {
  height: 30vh;
}
.height-40{
  max-height: 40vh;
}
.height-42 {
  max-height: 41.3vh;
}
.height-42-stat {
  height: 42vh;
}

.height-45 {
  max-height: 45vh;
}

.height-50 {
  max-height: 50vh;
}

.height-52 {
  height: 52vh;
}
.height-53 {
  max-height: 53vh;
}

.height-57 {
  max-height: 57vh;
}

.height-60 {
  max-height: 60vh;
}

.height-62 {
  max-height: 62vh;
}

.height-65 {
  max-height: 65vh;
}
.height-78 {
  max-height: 78vh;
}
.height-78 {
  max-height: 78vh;
}


.height-92 {
  max-height: 92vh;
}
.height-95 {
  max-height: 95vh;
}

.height-85 {
  max-height: 85vh;
}
.height-85-stat {
  height: 85vh;
}

.height-80 {
  max-height: 80vh;
}
.height-80-stat {
  height: 80vh;
}


@media (min-width: 1920px) {
  .ie-height {
   height: 62vh;
  }
}

@media (max-width: 1600px) {
  .ie-height {
   height: 52vh;
  }
}
@media (min-width: 1024px) {
  .height-do{
    height: 84vh;
  }
  .height-do-right{
    height: 67.7vh
  }
}

@media (max-width: 1023px) {
  .height-do{
    max-height: 83vh;
  }
  .height-do-right{
    height: 65vh
  }
}


/* Width img */
.w-308px {
  width: 19.25rem;
}

.w-172px {
  width: 10.75rem;
}

.w-124px {
  width: 7.75rem;
}

.w-92px {
  width: 5.75rem;
}

.w-72px {
  width: 4.5rem;
}
.w-60px{
  width: 60px;
}
.w-28px {
  width: 1.75rem;
}
.w-24px-size {
  width: 1.7rem;
}

.w-24px {
  width: 1.5rem;
}

.w-19px {
  width: 1.2rem;
}

.w-17px{
  width: 17px;
}
.w-14px {
  width: 0.85rem;
}
.w-12px {
  width: 0.75rem;
}

.mx-500px{
 max-width: 500px !important;
}
.mx-455px{
 max-width: 455px !important;
}
/* Width Div */
.w-3per{
  width: 3%;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-54 {
  width: 54.3% !important;
}
.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}

.w-120 {
  width: 120px;
}

.w-130 {
  width: 130px;
}

.w-140 {
  width: 140px;
}

.w-150 {
  width: 150px;
}

.w-160 {
  width: 160px;
}

.w-170 {
  width: 170px;
}

.w-180 {
  width: 180px;
}

.w-190 {
  width: 190px;
}

.w-200 {
  width: 200px;
}

.w-300 {
  width: 300px;
}

.w-400 {
  width: 400px;
}

.w-500 {
  width: 500px;
}

.w-600 {
  width: 600px;
}

.w-700 {
  width: 700px;
}

.w-800 {
  width: 800px;
}

/* Spacing */
.left-14px{
  position: relative;
  left: 14px;
}
.top-15 {
  top: 15% !important
}

.padding-top-10px {
  padding-top: a.$padding-10px;
}

.padding-top-50px {
  padding-top: a.$padding-top-50px;
}

.padding-bottom-10px {
  padding-bottom: a.$padding-10px;
}

.padding-bottom-20px {
  padding-bottom: a.$padding-bottom-20px;
}

.padding-left-10px {
  padding-left: a.$padding-10px;
}

.padding-right-10px {
  padding-right: a.$padding-10px;
}

.padding-left-24px {
  padding-left: a.$padding-24px;
}

.pt-05-rem {
  padding-top: 0.15rem !important;
}
.pt-05 {
  padding-top: 2.4px !important;
}
.pt-42px {
  padding-top: 42px !important;
}
.pt-56px {
  padding-top: 3.5rem !important;
}
.mb-25px{
  margin-bottom: 25px;
}
.mb-72px {
  margin-bottom: 4.5rem !important;
}
.mt-56px {
  margin-top: 3.5rem !important;
}
.mt-20px {
  margin-top: 1.25rem;
}
.mt-12px {
  margin-top: 0.75rem !important;
}
.mt-9px {
  margin-top: 0.55rem !important;
}
.ms-32px{
  margin-left: 2rem;
}

.ms-15px{
  margin-left: 0.925rem;
}
.ms-10px{
  margin-left: 0.65rem;
}
.mb-14px{
  margin-bottom: 0.85rem !important;
}
.mb-6px{
  margin-bottom: 0.35rem !important;
}

.pb-32px{
  padding-bottom: 2rem;
}
.pb-56px{
  padding-bottom: 3.5rem;
}
.pb-72px{
  padding-bottom: 4.5rem;
}
.pt-04{
  padding-top: 0.45rem;
}
.pe-20px{
  padding-right: 1.25rem;
}
.ps-28px{
  padding-left: 1.75rem !important;
}
.px-04{
  padding: 0 0.4rem;
}
.px-06{
  padding: 0 0.6rem;
}
.py-07{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-08{
  padding-top: 0.85rem !important;
  padding-bottom: 0.85rem !important;
}
.my-05 {
  margin-top: 2px;
  margin-bottom: 2px;
}
.ms-43px {
  margin-left: 2.7rem !important;
}

.me-40px{
  margin-right: 2.5rem !important;
}

.right-10px {
  right: 10px !important;
}

.bottom-10px {
  bottom: 10px !important;
}

.alert {
  margin-bottom: 0px;
}
.alert-left {
  margin-bottom: 0px;
  margin-left: 6.2rem;
}

/* Width */
.w-jo-own{
  width: 135.28px !important;
}
.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}
 
.w-70px{
width: 70px;
}

.width-75vw {
  max-width: 75vw;
}

@media (min-width: 1920px) {
  .ie-height {
   height: 62vh;
  }
}

@media (max-width: 1600px) {
  .ie-height {
   height: 52vh;
  }
}
.ie-height {
  height: 52vh;
}

.w-l-picklist{
  width: 600px;
}

/* Asterisk in Form*/
.mandatory::after {
  content: "*";
  position: absolute;
  right: auto;
  display: inline-block;
  font-size: 12px;
  color: #f44336;
}
/* Vertical Divider */
.divider-end{
  border-left: 1px solid a.$black;
  height: 25px;
  position: relative;
  top: 5px;

}
.divider-end-white{
  border-left: 1px solid a.$white;
  height: 25px;
  position: relative;
  top: 5px;

}

/* Login, Create Password, Activate Account, Forget Password
====================== */
.custella-dw-app{
  height: 100%;
  max-width: 460px;
  margin: 0 auto;
  overflow: hidden;

}

.left-form {
  transform: translate(0, a.$translate-top-8);
  width: 40%;

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
    // transform: translate(0, a.$translate-top-10);
  }
}

.right-form {
  background: red($color: #000000);
  transform: translate(0, a.$translate-top-10);

  @media screen and (min-device-width: a.$min-width-laptop-screen) and (max-device-width: a.$max-width-laptop-screen) {
    // transform: translate(0, a.$translate-top-10);
  }
}

.right-form-two {
  background: var(--surface-300);
  transform: translate(a.$translate-left-10, a.$translate-top-negative-10);

  @media screen and (min-device-width: a.$min-width-laptop-screen) and (max-device-width: a.$max-width-laptop-screen) {
    transform: translate(a.$translate-left-10, 0);
  }
}

.custella-login {
  background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(66,70,170,1) 59%, rgba(91,95,231,1) 100%);
  height: 100vh;
  // position: relative;
  @media (max-width: 1024px) {
    overflow: hidden;
  }
  /* Login language */
  .language{
      position: absolute;
      right: 30px;
      top: 30px;
      width: auto;
      /* Dropdown change language */
    .custella-lang-front{
      .accordion{
        --bs-accordion-bg: #66666617;
      }
      .accordion-button:not(.collapsed){
        background: transparent;
        color: #E0E0E0;
        box-shadow: none;
      }
    } 
    .custella-lang-menu{
      &.accordion-flush > .accordion-item:last-child{
        padding: 6px;
        border-radius: 4px;
      }
      .accordion-item button{
        cursor: pointer;
        color: a.$white;
        background: transparent;
        &:hover{
          background: transparent;
          color: a.$white !important;
          border-radius: 4px !important;
        }
        &:focus{
          // background: a.$white;
          // color: a.$dark-color !important;
          // border-radius: 4px !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
      .accordion-item ul li{
        cursor: pointer;
        color: a.$white;
        &:hover{
          background: a.$white;
          color: a.$dark-color !important;
            border-radius: 4px !important;
        }
        &:focus{
            background: a.$white;
            color: a.$dark-color !important;
            border-radius: 4px !important;
        }
        a{
          color: unset !important;
        }
      }
      .accordion-button::after {
        display: none !important;
      }

    } 
  }
  .container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // @media (max-width: 1024px) {
    //   position: unset;
    //   top: unset;
    //   left: unset;
    //   transform: unset;
    // }
    .row{
      .mx-auto{
        width: 450px;
        // max-width: 450px;
        .card{
          .card-body{
            form{
              .form-group{
                margin-bottom: 16px;
                label{
                  font-size: 14px;
                  font-family: a.$form-font-medium;
                  margin-bottom: 4px;
                }
                .p-input-icon-left, .p-input-icon-right{
                  display: block;
                  span input{
                    border-radius: 8px;
                    width: 100%;
                  } 
                }
                .pwd-valid{
                  border: 1px solid a.$white4;
                  background: a.$white4;
                  border-radius: 8px;
                  padding: 0.85rem;
                  margin-top: 16px;
                  margin-bottom: 16px;

                  
                  position: absolute;
                  z-index: 100;
                  top: 11px;
                  right: -97px;
                  &-body{
                    span{
                      font-size: 10px;
                      font-family: a.$form-font;
                    }
                    &:before{
                      content: "";
                      position: absolute;
                      bottom: -11px;
                      left: 13px;
                      margin-left: -5px;
                      border-width: 5px;
                      border-style: solid;
                      border-color: a.$white4 transparent transparent transparent;
                    }
                  }
                }
              }
            }
            .remember{
              display: flex;
              align-items: center;
              .rmb-label{
                font-size: 12px;
                font-family: a.$form-font-medium;
                margin-left: 8px;
              }
            } 
            .forgot-me{
              display: flex;
              align-items: center;
              margin-top: 12px;
              label{
                font-size: 12px;
                font-family: a.$form-font-medium;
              }
              .click-forgot{
                font-size: 12px;
                font-family: a.$form-font-medium;
                color: a.$primary-color;
                cursor: pointer;
                &:hover{
                  text-decoration: underline;
                }
              }
            } 
          }
        }
      }
    }
  }
}

.custella-login-form {
  margin-top: 2.5rem;

  #{&}-text {
    margin-bottom: 0.5rem;
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(medium);
  }
}

.custella-login-form-pwd {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0.25rem 0 2rem 0;

  #{&}-text {
    display: block;
    margin-bottom: 1rem;
    font-size: a.$s-fontsize;
    font-family: a.text-weight(regular);
  }
}

.custella-login-form-new-pwd {
  right: 0;
  top: 0;

  #{&}-text-2 {
    margin-left: 21rem;
  }
}

.custella-login-form-signup {
  #{&}-text {
    cursor: pointer;
  }
}


.custella-quote {
  display: block;
  position: relative;
  padding: 3rem;

  #{&}-text {
    font-size: 18px;
    font-family: a.text-weight(medium);
  }

  #{&}-text-bold {
    font-size: 18px;
    font-family: a.text-weight(bold);
  }

  #{&}-text-small {
    font-size: 12px;
    color: #7d7d7d;
    font-family: a.text-weight(medium);
  }

  #{&}-img {
    display: block;
    position: relative;
    margin-top: 1rem;

    img {
      position: relative;
      width: 100%;
    }
  }

  #{&}-img-two {
    display: block;
    position: relative;

    img {
      position: relative;
      width: 80%;
    }
  }
}

.integration-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  img{
    animation: fadeInAnimation ease-in-out 3.5s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
  }
}
@keyframes fadeInAnimation {
  0% {
      opacity: 0.5;
  }
  100% {
      opacity: 1;
   }
}

/* Login from phone
============================================*/
.custella-phone {
  max-height: 100vh;
  display: block;
  text-align: center;
  margin: 1rem;
  overflow-x: hidden;

  #{&}-driver {
    width: 100%;
  }

  #{&}-text {
    position: relative;
    display: block;
    margin: 10px;
    text-align: center;

    &-title {
      font-size: 32px;
      font-family: a.text-weight(bold);
    }

    &-sub-one {
      display: block;
      margin: 10px 5px;
      font-size: a.$normal-fontsize;
      font-family: a.text-weight(medium);
      color: a.$dark-font;
    }

    &-sub-two {
      font-size: a.$s-fontsize;
      font-family: a.text-weight(medium);
    }
  }

  #{&}-googleplay {
    width: 70%;
  }
}

/* Form Layout 
============================================*/
.custella-form {
  display: block;
  position: relative;
  height: 100vh;
  z-index: 1000;
}

.custella-dashboard-top {
  position: relative;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  #{&}-title {
    position: relative;
    font-size: a.$h4-fontsize;
    font-family: a.text-weight(bold);
  }

  #{&}-right {
    display: flex;
    align-items: center;
  }
}

.custella-dashboard-title {
  position: relative;
  top: 50px;
  font-size: a.$l-fontsize;
  font-family: a.text-weight(medium);
}

.custella-dashboard-title-tab {
  position: relative;
  top: 50px;
  font-size: a.$l-fontsize;
  font-family: a.text-weight(medium);
}

.custella-dashboard-empty {
  position: absolute;
  left: 45%;
  transform: translate(0, a.$translate-top-150px);

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
    transform: translate(a.$translate-left-35px, a.$translate-top-80px);
  }

  img {
    position: absolute;

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
      margin-top: 5rem;
    }
  }

  span {
    position: absolute;
    margin-top: 17rem;
    margin-left: 5rem;
    width: max-content;

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
      margin-top: 22rem;
    }
  }
}

.cont{
  position: relative;
  display: flex;
  /* justify-content: space-between; */
  /* width: 100%; */
}

.custella-dashboard-internet {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;

  img {
    position: absolute;

    @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
      // margin-top: 5rem;
    }
  }

  #{&}-text {
    position: absolute;
    display: block;
    margin-top: 22rem;
    padding-left: 3rem;
    text-align: center;

    .custella-dashboard-internet-text-one {
      display: block;
      width: max-content;
      text-align: center;
      font-size: a.$xxl-fontsize;
      font-family: a.text-weight(medium);
      color: a.$dark-font2;

      @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
        // margin-top: 22rem;
      }
    }

    .custella-dashboard-internet-text-two {
      margin-top: 10px;
      width: max-content;
      text-align: center;
      font-size: a.$l-fontsize;
      font-family: a.text-weight(medium);
      color: a.$dark-font2;
    }
  }
}

.custella-form-container {
  box-shadow: 0px 4px 15px -12px rgba(117, 115, 115, 0.8);
  background: a.$background-color;
  margin: 0 1.5rem;
  border-radius: 0.375rem;

  #{&}-title {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.65rem 1.75rem;
    border-radius: 0.375rem 0.375rem 0 0;
    font-family: a.text-weight(medium);
    @include a.font-size-for-all-screens();

    .custella-form-container-title-subtitle {
      font-family: a.$form-font;
      font-size: 16px;
      font-family: a.text-weight(medium);
    }

    .custella-form-container-title-subtitle-thin {
      font-family: a.$form-font;
      font-family: a.text-weight(thin);
    }
  }

  #{&}-title-two {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.65rem 1.75rem;
    font-family: a.text-weight(medium);
    @include a.font-size-for-all-screens();
    /* For Dynamic Labels */
    &.stretch{
      padding: 0.65rem 1.15rem;
    }
  }

  #{&}-title-two-grey {
    background: a.$grey;
    color: a.$headerblack;
    padding: 0.65rem 1.75rem;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    /* For Dynamic Labels */
    &.stretch{
      padding: 0.65rem 1.15rem;
    }
  }
  #{&}-title-two-grey-flex {
    background: a.$grey;
    color: a.$headerblack;
    padding: 0.65rem 1.75rem;
    display: flex;
  }

  #{&}-title-two-grey-two {
    background: a.$grey3;
    color: a.$headerblack;
    padding: 0.5rem 1.95rem;
  }
  
  #{&}-title-two-empty {
    background: a.$white;
    color: a.$headerblack;
    padding: 0.5rem 1.95rem;
  }

  #{&}-title-two-grey-right {
    background: a.$grey3;
    color: a.$black;
    padding: 0.65rem 1.75rem;
    border-left: 1px solid #c7c7c7;
  }

  #{&}-content {
    padding: 0rem 1.5rem;
    margin: 1.5rem 0;
  }

  #{&}-content-sidebar {
    padding: 0rem 0.75rem;
    margin: 1rem 0;
  }

  #{&}-content-last-row {
    padding: 0rem 2rem 0.5rem 2rem;
    font-family: a.$form-font;
    margin-bottom: 25px;
    border-radius: 0 0 0.375rem 0.375rem;
  }
  .rmv-margin  #{&}-content-last-row{
    padding: 0rem 2rem 0.5rem 2rem;
    font-family: a.$form-font;
    border-radius: 0 0 0.375rem 0.375rem;
    margin-bottom: unset ;
  }

  #{&}-table {
    padding: 0rem 1.5rem;
    margin: 0.5rem 0rem;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
  }

  #{&}-content-border-bottom {
    border-bottom: 1px solid a.$grey;
    min-height: 50px;
    padding: 0rem 2rem;
    /* Custom Stylings */
    /* Form/Subform Setup */
    .lv-desc{
      margin-top: 1rem;
    }
    .form-setup{
      display: grid;
      grid-template-columns: repeat(3, minmax(217px, 485px));
      // grid-auto-rows: 130px;
      padding: 0 0 1rem 0;
      .custella-form-container{
        .body{
          padding: 1.35rem  1.75rem 2rem 1.75rem;
          position: relative;
          height: 110px;
          .footer-setup{
            position: absolute;
            right: 1.75rem;
            bottom: 9px;
            text-align: right;
            span{              
              display: block;
            }
          }
        }
      }
    }
  }
  #{&}-content-border-bottom-h-0 {
    border-bottom: 1px solid a.$grey;
    padding: 0rem 2rem;
    &.stretch{
      padding: 0rem 1.15rem;
    }
  }

  #{&}-content-files {
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    border-bottom: 1px solid a.$grey;
    padding: 0rem 2rem;
  }

  #{&}-footer {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 25px;
  }

  #{&}-footer-two {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .hidePopup {
    position: absolute;
    background: transparent;
    border: 1px solid transparent;
  }
  &.right-panel{
    margin: 0;
  }
  /* Overview */
  #{&}-image{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border-bottom: 1px solid #dbdbdb;
  }
  .ov-title{
    font-size: 14px;
    font-family: a.text-weight(medium);
    padding: 16px 1.75rem 0.15rem 1.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background: #ebebeb;
    // border-top: 1px solid #dbdbdb;  
    // border-bottom: 1px solid #dbdbdb;
    label{
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
  .ov-title-summ{
    font-size: 14px;
    font-family: a.text-weight(medium);
    padding: 6px 1.75rem 0px 1.75rem;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // background: #ebebeb;
    // border-top: 1px solid #dbdbdb;  
    // border-bottom: 1px solid #dbdbdb;
    label{
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
  .ov-link{
    font-size: 12px;
    font-family: a.text-weight(regular);
    color: a.$primary-color !important;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
    &-mini{
      font-family: a.text-weight(regular);
      color: a.$primary-color !important;
      font-size: 12px;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .ov-content{
      border-bottom: 1px solid #dbdbdb;
      // border-top: 1px solid #dbdbdb;
      padding-top: 0;
      padding-bottom: 12px;
      padding-left: 1.75rem;
      padding-right: 1.75rem;
      .ov-row{
        display: flex;
        align-items: center;
        margin: 4px 0;
        .label{
          font-size: 12px;
          font-family: a.text-weight(regular);
          color: a.$lightGrey5;
          &-i{
            font-size: a.$normal-fontsize;
            font-family: a.text-weight(semiBold);
            font-style: italic;
            color: a.$black;
          }
        }        
        .value{
          font-size: 12px;
          font-family: a.text-weight(regular);
          color: #495057;
          white-space: nowrap;
          &-link{
            font-size: 12px;
            font-family: a.text-weight(regular);
            color: a.$primary-color !important;
            cursor: pointer;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        .label-j{
          @include a.font-size-for-all-screens(12px, 12px, 12px, 12px);
          font-family: a.text-weight(regular);
          // color: a.$lightGrey3;
          // display: block;
          &-od{
            @include a.font-size-for-all-screens(12px, 12px, 12px, 12px);
            font-family: a.text-weight(regular);
            color: a.$red;
            // display: block;
          }           
        }
        .value-j{
          @include a.font-size-for-all-screens(12px, 12px, 12px, 12px);
          font-family: a.text-weight(regular);
          &-od{
            @include a.font-size-for-all-screens(12px, 12px, 12px, 12px);
            font-family: a.text-weight(regular);
            color: a.$red;
          }
        }
        .ov-jo{
          text-align: center;
          .label-j{
            @include a.font-size-for-all-screens(12px, 12px, 12px, 12px);
            font-family: a.text-weight(regular);
            // color: a.$lightGrey3;
            display: block;
            &-od{
              @include a.font-size-for-all-screens(12px, 12px, 12px, 12px);
              font-family: a.text-weight(regular);
              color: a.$red;
              display: block;
            }           
          }
          .value-j{
            @include a.font-size-for-all-screens(12px, 14px, 16px, 20px);
            font-family: a.text-weight(semiBold);
            &-od{
              @include a.font-size-for-all-screens(12px, 14px, 16px, 20px);
              font-family: a.text-weight(semiBold);
              color: a.$red;
            }
          }
        }
        &-no{
          // align-items: center;
          padding-top: 6px;
          .label{
            font-size: 12px;
            font-family: a.text-weight(regular);
            // color: a.$lightGrey5;
            &-i{
              font-size: a.$normal-fontsize;
              font-family: a.text-weight(semiBold);
              font-style: italic;
              color: a.$lightGrey5;
            }
          }
        }
      }
      &-stats{
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        .ov-row{
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          .label{
            font-size: 12px;
            font-family: a.text-weight(regular);
            color: a.$lightGrey5;
            &-i{
              font-size: a.$normal-fontsize;
              font-family: a.text-weight(regular);
              font-style: italic;
              color: a.$black;
            }
          }        
          .value{
            font-size: 12px;
            font-family: a.text-weight(regular);
            color: #495057;
            white-space: nowrap;
          }
          .ov-jo{
            text-align: center;
            .label-j{
              @include a.font-size-for-all-screens(12px, 12px, 12px, 12px);
              font-family: a.text-weight(regular);
              color: a.$lightGrey3;
              display: block;
              &-od{
                @include a.font-size-for-all-screens(12px, 12px, 12px, 12px);
                font-family: a.text-weight(regular);
                color: a.$red;
                display: block;
              }           
            }
            .value-j{
              @include a.font-size-for-all-screens(12px, 14px, 16px, 20px);
              font-family: a.text-weight(regular);
              &-od{
                @include a.font-size-for-all-screens(12px, 14px, 16px, 20px);
                font-family: a.text-weight(regular);
                color: a.$red;
              }
            }
          }
          &-no{
            min-height: 50px;
            align-items: center;
            padding-top: 0.5rem;
            .label{
              font-size: 12px;
              font-family: a.text-weight(medium);
              color: a.$lightGrey5;
              &-i{
                font-size: a.$normal-fontsize;
                font-family: a.text-weight(semiBold);
                font-style: italic;
                color: a.$black;
              }
            }
          }
        }
      }
      .charts{
        background: #f2f2f2;
        border-radius: 12px;
        padding: 1.25rem 0 0 0;
        border-style: solid;
        border-width: 1px;
        border-color: #d8d8d8;
      }
    }
  .ov-content-solo{
    padding: 0.85rem 1.75rem 0.35rem 1.75rem;
    border-bottom: 1px solid #dbdbdb;
    .ov-row{
      display: flex;
      align-items: center;
      .label{
        font-size: 12px;
        font-family: a.text-weight(regular);
        color: a.$lightGrey5;
        &-c{
          font-size: a.$l-fontsize;
          font-family: a.text-weight(medium);
          color: #495057;
          padding-bottom: 17px;
          &-0{
            font-size: a.$l-fontsize;
            font-family: a.text-weight(medium);
            color: #495057;
            padding-bottom: unset;
          }
        }
      }
      .value{
        font-size: 12px;
        font-family: a.text-weight(medium);
        color: #495057;
        &-desc{
          font-size: 12px;
          font-family: a.text-weight(medium);
          color: #495057;
          padding-top: 5px;
        }
      }
      .icon {
        width: 38px;
        min-width: 38px;
        height: 38px;
        color: a.$primary-color;
        align-items: center;
        display: flex;
        justify-content: center;
        border-right: thin;
        background: a.$white4;
        border-radius: 10px;
      }
    }
    .ov-row-0{
      padding-bottom: 0.35rem;
      .label{
        font-size: 12px;
        font-family: a.text-weight(regular);
        color: a.$lightGrey5;
        &-c{
          font-size: a.$l-fontsize;
          font-family: a.text-weight(regular);
          color: #495057;
        }
      }
      .value{
        font-size: 12px;
        font-family: a.text-weight(regular);
        color: #495057;
        word-break: break-word;
      }
    }
    // .p-tag{
    //   height: 17px;
    // }
  }
  .instruction{
    padding: 1.5rem;
    .intro{
      font-family: a.text-weight(medium);
      font-size: 12px;
      display: block;
      margin-bottom: 12px;
    }
    .steps{
      font-family: a.text-weight(medium);
      font-size: 12px;
      margin-bottom: 4px;
      display: block;
    }
  }
}
// @media (min-width: 1920px) {
//   .custella-form-container{
//     &.right-panel{
//       height: 750px;
//       overflow-y: scroll;
//     }
//   }
// }
// @media (max-width: 1600px) {
//   .custella-form-container{
//     &.right-panel{
//       height: 580px;
//       overflow-y: scroll;
//     }
//   }
// }
/* Timeline */
.tl-row{
  display: flex;
  margin: 0;
  // padding-right: 12px;
  justify-content: space-between;
  .label{
    font-size: a.$normal-fontsize;
    font-style: italic;
    font-family: a.text-weight(semiBold);
  }
  &-no{
    align-items: center;
    padding: 0.85rem 0;
    .label{
      font-size: a.$normal-fontsize;
      font-family: a.text-weight(regular);
      font-style: italic;
      padding: 30px 0;
      display: flex;
      justify-content: center;
    }
  }
}
// task timeline
.dot-wrapper {
  position: relative;
  left: 1rem;
  width: 100%;
  margin-bottom: 20px;
}
.dot-wrapper-tl {
  position: relative;
  left: 0.45rem;
  width: 100%;
  margin-bottom: 20px;
  // max-height: 753px;
  // overflow: scroll;
}

.dot-item {
  position: relative;
  display: flex;
  align-content: baseline;
  flex: 1;
  margin-top: 15px;
  &::before {
    position: absolute;
    content: "";
    border-left: 1px dotted #c9d4d8;
    z-index: 2;
    margin-top: 40px;
    margin-left: 8px;
    height: 100%;
  }
  .dot-counter {
    position: relative;
    z-index: 5;
    display: flex;
    width: 17.78px;
    height: 17px;
    border-radius: 50%;
    background: #435861;
    margin-bottom: 6px;
    top: 25px;
  }
  .dot-counter-past {
    position: relative;
    z-index: 5;
    display: flex;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #c9d4d8;
    margin-bottom: 6px;
    top: 25px;
  }
}

.text-for-step {
  border: 1px solid #bfbdbd;
  border-radius: 4px 4px 0 0;
  padding: 10px 15px;
  display: block;
  margin: 0 17px;

  .dot-name {
    font-family: a.text-weight(medium);
    font-size: a.$normal-fontsize;
    display: block;
    // margin: 5px 0 0 0;
  }

  .dot-description {
    // margin: 5px 0 0 0;
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(regular);
    font-style: italic;
    color: a.$lightGrey5;
  }

  .tl-icon{
    width: 38px;
    min-width: 38px;
    height: 38px;
    color: a.$primary-color;
    align-items: center;
    display: flex;
    justify-content: center;
    border-right: thin;
    background: a.$white3;
    border-radius: 10px;
    border: 1px solid #dbdbdb;
  }
}
.text-update{
  background-color: a.$white3;
  margin: 0 17px;
  padding: 4px 15px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #bfbdbd;
  border-top: transparent;
  .tl-desc-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tl-desc-start{
      display: flex;
      align-items: center;
      .desc-label{
        font-size: 10px;
        // font-style: italic;
        font-family: a.text-weight(regular);
        color: a.$lightGrey5;
      }
    }
    .tl-desc-end{
      font-size: 10px;
      font-family: a.text-weight(regular);
      color: a.$lightGrey5;
    }
  }
}
/* no line for dot item */
.no_line:before {
  border-left: unset;
}
 
.cs-ov-info{
  // background: a.$background-color;
  // box-shadow: 0px 4px 15px -12px rgba(117, 115, 115, 0.8);
  // border: 1px solid;
  margin-right: 0.75rem;
  .p-tabview{
    .p-tabview-nav{
      .p-tabview-nav-link {
        padding: 0.9rem !important;
      }
    }
    .p-tabview-panels{
      padding-top: 0 !important;
      width: calc(100% - -321px) !important;
      height: unset !important;
    }
  }
  .rs{
    width: 420px;
    max-width: 420px;
  }
}
.ast-ov-info{
  // background: a.$background-color;
  // box-shadow: 0px 4px 15px -12px rgba(117, 115, 115, 0.8);
  // border: 1px solid;
  margin-right: 0.75rem;
  .p-tabview{
    .p-tabview-nav{
      .p-tabview-nav-link {
        padding: 0.9rem !important;
      }
    }
    .p-tabview-panels{
      padding-top: 0 !important;
      width: calc(100% - -242px) !important;
      height: unset !important;
    }
  }
  .rs{
    width: 420px;
    max-width: 420px;
  }
}

.hz-control .custella-form-container-content-last-row{
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.custella-form-container-two {
  box-shadow: 0px 4px 15px -4px rgba(117, 115, 115, 0.8);
  background: a.$background-color;
  margin: 0 0.5rem;

  #{&}-title {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.85rem 1.75rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .custella-form-container-title-subtitle {
      font-family: a.$form-font;
      font-size: 16px;
      font-family: a.text-weight(medium);
    }

    .custella-form-container-title-subtitle-thin {
      font-family: a.$form-font;
      font-family: a.text-weight(thin);
    }
  }

  #{&}-title-two {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.65rem 1.75rem;
  }

  #{&}-title-two-grey {
    background: a.$grey;
    color: a.$headerblack;
    padding: 0.65rem 1.75rem;
  }

  #{&}-title-two-grey-right {
    background: a.$grey;
    color: a.$black;
    padding: 0.65rem 1.75rem;
    border-left: 1px solid #c7c7c7;
  }

  #{&}-content {
    padding: 0rem 1.5rem;
    margin: 1.5rem 0;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    // min-height: 6rem;
  }

  #{&}-content-last-row {
    padding: 0rem 2rem 0.5rem 2rem;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    margin-bottom: 25px;
  }

  #{&}-table {
    padding: 0rem 1.5rem;
    margin: 0.5rem 0rem;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
  }

  #{&}-content-border-bottom {
    border-bottom: 1px solid a.$grey;
    padding: 0rem 2rem;
    min-height: 50px;
  }

  #{&}-content-files {
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    border-bottom: 1px solid a.$grey;
    padding: 0rem 2rem;
  }

  #{&}-footer {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 25px;
  }

  #{&}-footer-two {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.custella-form-container-three {
  box-shadow: 0px 4px 15px -4px rgba(117, 115, 115, 0.8);
  background: a.$background-color;
  margin: 0 1.5rem 0 0;

  #{&}-title {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.85rem 1.75rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .custella-form-container-title-subtitle {
      font-family: a.$form-font;
      font-size: 16px;
      font-family: a.text-weight(medium);
    }

    .custella-form-container-title-subtitle-thin {
      font-family: a.$form-font;
      font-family: a.text-weight(thin);
    }
  }

  #{&}-title-two {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.65rem 1.75rem;
  }

  #{&}-title-two-grey {
    background: a.$grey;
    color: a.$headerblack;
    padding: 0.65rem 1.75rem;
  }

  #{&}-title-two-grey-right {
    background: a.$grey;
    color: a.$black;
    padding: 0.65rem 1.75rem;
    border-left: 1px solid #c7c7c7;
  }

  #{&}-content {
    padding: 0rem 1.5rem;
    margin: 1.5rem 0;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    // min-height: 6rem;
  }

  #{&}-content-last-row {
    padding: 0rem 2rem 0.5rem 2rem;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    margin-bottom: 25px;
  }

  #{&}-table {
    padding: 0rem 1.5rem;
    margin: 0.5rem 0rem;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
  }

  #{&}-content-border-bottom {
    border-bottom: 1px solid a.$grey;
    min-height: 50px;
    padding: 0rem 2rem;
  }

  #{&}-content-files {
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    border-bottom: 1px solid a.$grey;
    padding: 0rem 2rem;
  }

  #{&}-footer {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 25px;
  }

  #{&}-footer-two {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.custella-form-container-four {
  box-shadow: 0px 4px 15px -4px rgba(117, 115, 115, 0.8);
  background: a.$background-color;

  #{&}-title {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.85rem 1.75rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .custella-form-container-title-subtitle {
      font-family: a.$form-font;
      font-size: 16px;
      font-family: a.text-weight(medium);
    }

    .custella-form-container-title-subtitle-thin {
      font-family: a.$form-font;
      font-family: a.text-weight(thin);
    }
  }

  #{&}-title-two {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.65rem 1.75rem;
  }

  #{&}-title-two-grey {
    background: a.$grey;
    color: a.$headerblack;
    padding: 0.65rem 1.75rem;
  }

  #{&}-title-two-grey-right {
    background: a.$grey;
    color: a.$black;
    padding: 0.65rem 1.75rem;
    border-left: 1px solid #c7c7c7;
  }

  #{&}-content {
    padding: 0rem 1.5rem;
    margin: 1.5rem 0;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    // min-height: 6rem;
  }

  #{&}-content-last-row {
    padding: 0rem 2rem 0.5rem 2rem;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    margin-bottom: 25px;
  }

  #{&}-table {
    padding: 0rem 1.5rem;
    margin: 0.5rem 0rem;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
  }

  #{&}-content-border-bottom {
    border-bottom: 1px solid a.$grey;
    min-height: 50px;
    padding: 0rem 2rem;
  }

  #{&}-content-files {
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    border-bottom: 1px solid a.$grey;
    padding: 0rem 2rem;
  }

  #{&}-footer {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 25px;
  }

  #{&}-footer-two {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.custella-form-container-five {
  box-shadow: 0px 4px 15px -4px rgba(117, 115, 115, 0.8);
  background: a.$background-color;
  margin: 0 1.5rem;

  #{&}-title {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.85rem 1.75rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .custella-form-container-title-subtitle {
      font-family: a.$form-font;
      font-size: 16px;
      font-family: a.text-weight(medium);
    }

    .custella-form-container-title-subtitle-thin {
      font-family: a.$form-font;
      font-family: a.text-weight(thin);
    }
  }

  #{&}-title-two {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.65rem 1.75rem;
  }

  #{&}-title-two-grey {
    background: a.$grey;
    color: a.$headerblack;
    padding: 0.65rem 1.75rem;
  }

  #{&}-title-two-grey-two {
    background: a.$grey3;
    color: a.$headerblack;
    padding: 0.5rem 1.95rem;
  }

  #{&}-title-two-grey-right {
    background: a.$grey3;
    color: a.$black;
    padding: 0.65rem 1.75rem;
    border-left: 1px solid #c7c7c7;
  }

  #{&}-content {
    padding: 0rem 1.5rem;
    margin: 1.5rem 0;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    // min-height: 6rem;
  }

  #{&}-content-last-row {
    padding: 0rem 2rem 0.5rem 2rem;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    margin-bottom: 25px;
  }

  #{&}-table {
    padding: 0rem 1.5rem;
    margin: 0.5rem 0rem;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
  }

  #{&}-content-border-bottom {
    border-bottom: 1px solid a.$grey;
    padding: 0rem 1.5rem;

    &.do-summary-sign {
      min-height: 110px;
    }
  }

  #{&}-content-files {
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    border-bottom: 1px solid a.$grey;
    padding: 0rem 2rem;
  }

  #{&}-footer {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 25px;
  }

  #{&}-footer-two {
    border-top: 1px solid a.$border-top-grey;
    padding: 0.95rem 1.5rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.custella-form-container-footer-float {
  box-shadow: 0px 4px 15px -4px rgba(117, 115, 115, 0.8);
  background: a.$background-color;
  padding: 0.95rem 1.5rem;
  border-radius: 4px;
}

.opt-plan .custella-form-container-footer-float {
  box-shadow: 0px 8px 15px -8px rgba(117, 115, 115, 0.8);
  background: a.$background-color;
  padding: 0.95rem 1.5rem;
  border-radius: 0px;
}
.custella-content-row {
  display: flex;
  margin: 10px 0;
  padding-right: 12px;
  justify-content: space-between;
  // align-items: center;

  #{&}-input {
    display: block;
    position: relative;
    margin: 0 1rem 0 0;
  }

  #{&}-time {
    display: flex;
    position: relative;
    margin: 0.25rem 1rem 0.25rem 0px;
    padding-left: 0.5rem;
  }
  .address .custella-content-row{
    margin: 0 !important;
  }
}

.custella-content-row-two {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;

  #{&}-input {
    display: block;
    position: relative;
    margin: 0 1rem 0 0;
  }
}

.custella-content-row-two-non {
  display: flex;
  margin: 10px 0;

  #{&}-input {
    display: block;
    position: relative;
    margin: 0 1rem 0 0;
  }
}

.custella-content-row-three {
  display: flex;
  padding-right: 12px;
  justify-content: space-between;
  padding-right: 0px;

  #{&}-input {
    display: block;
    position: relative;
    margin: 0 1rem 0 0;
    padding-top: 5px;
  }

  #{&}-attachment {
    display: block;
    position: relative;
    margin: 0;
    .title{
      border-bottom: 1px solid #dee2e6;
      padding-bottom: 5px;
      span{
        font-size: 12px;
        font-family: a.$form-font-medium;
        color: a.$lightGrey5;
      }
    }
  }
}

.link-style {
  color: #6366F1 !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  cursor: pointer;
}

.link-style:hover {
  text-decoration: underline !important;
  color: #0044cc !important;
}


.custella-content-row-four {
  display: flex;
  padding-right: 12px;
  padding-right: 0px;

  #{&}-input {
    display: block;
    position: relative;
    margin: 0 1rem 0 0;
    padding-top: 5px;
  }

  #{&}-attachment {
    display: block;
    position: relative;
    margin: 0;
    padding-top: 5px;
  }
}
.custella-content-row-start {
  display: flex;
  margin: 10px 0;
  // padding-right: 1.25rem;
  // padding-left: 1.95rem;
  justify-content: space-between;
  // align-items: center;

  #{&}-input {
    display: block;
    position: relative;
    margin: 0 1rem 0 0;
  }

  #{&}-time {
    display: flex;
    position: relative;
    margin: 0.25rem 1rem 0.25rem 0px;
    padding-left: 0.5rem;
  }
  .address .custella-content-row{
    margin: 0 !important;
  }
}

.custella-time-content {
  background-color: #eeeeee;
  padding: 0.25rem 1rem;
  border-radius: 4px;
}

.container-no-title {
  margin-top: 50px;
}

.custella-content-row-input {
  #{&}-title {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(regular);
    display: block;
    margin-bottom: 4px;
  }

  #{&}-title-two {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(regular);
    color: a.$lightGrey5;
    display: block;
    margin-bottom: 3px;
  }

  #{&}-title-files {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(medium);
    color: a.$grey6;
    display: block;
    padding-top: 1rem;
    // padding-bottom: 1rem;
    // border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  #{&}-title-two-border-bottom {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(medium);
    color: a.$grey6;
    display: block;
    border-bottom: 1px solid a.$grey;
    padding: 10px 0;
  }

  #{&}-title-three {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(regular);
    color: a.$black;
    display: block;
    word-break: break-word;
    a{
      color: a.$primary-color;
      &:hover{
        text-decoration: underline;
      }
    }
  }

  #{&}-title-three-link {
    // margin: 5px 0;
    font-family: a.text-weight(regular);
    font-size: a.$normal-fontsize;
    color: a.$primary-color !important;
    display: block;
    cursor: pointer;
    &:hover {
      // margin: 5px 0;
      font-family: a.text-weight(regular);
      color: a.$primary-color;
      text-decoration: underline;
    }
  }

  #{&}-sub {
    font-size: a.$s-fontsize;
  }

  #{&}-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid a.$grey;
    margin-top: 10px;
    padding-bottom: 10px;

    .custella-content-row-input-list-title-sub {
      font-family: a.$form-font;
      font-size: a.$normal-fontsize;
      font-family: text-weight(thin);
      padding: 0 10px;
    }
  }
}
.custella-content-row-input-title-two {
  font-family: "Inter-Regular";
  font-size: 12px;
  font-family: 500;
  color: a.$lightGrey5;
  display: block;
  margin-bottom: 3px;
}
.custella-content-row-start-input {
  #{&}-title {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(regular);
    display: block;
    margin-bottom: 4px;
  }

  #{&}-title-two {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(regular);
    color: a.$lightGrey5;
    display: block;
    margin-bottom: 3px;
  }

  #{&}-title-files {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(medium);
    color: a.$grey6;
    display: block;
    padding-top: 1rem;
    // padding-bottom: 1rem;
    // border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  #{&}-title-two-border-bottom {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(medium);
    color: a.$grey6;
    display: block;
    border-bottom: 1px solid a.$grey;
    padding: 10px 0;
  }

  #{&}-title-three {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(regular);
    color: a.$black;
    display: block;
    word-break: break-word;
  }

  #{&}-title-three-link {
    // margin: 5px 0;
    font-family: a.text-weight(regular);
    font-size: a.$normal-fontsize;
    color: a.$primary-color !important;
    display: block;
    cursor: pointer;
    &:hover {
      // margin: 5px 0;
      font-family: a.text-weight(regular);
      color: a.$primary-color;
      text-decoration: underline;
    }
  }

  #{&}-sub {
    font-size: a.$s-fontsize;
  }

  #{&}-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid a.$grey;
    margin-top: 10px;
    padding-bottom: 10px;

    .custella-content-row-input-list-title-sub {
      font-family: a.$form-font;
      font-size: a.$normal-fontsize;
      font-family: text-weight(thin);
      padding: 0 10px;
    }
  }
}
.custella-time-row {
  padding-left: 0.5rem;
}

.custella-content-row-time {
  #{&}-title {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(medium);
  }

  #{&}-title-two {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(medium);
    color: a.$grey6;
    width: 150px;
  }

  #{&}-title-three {
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(regular);
    color: a.$black;
  }
}

.custella-content-row-checkbox {
  display: flex;
  position: relative;

  #{&}-text {
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    font-family: a.$form-font;
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(regular);
  }
}

/* Form Layout; upload files box */
.custella-file-dropbox {
  position: relative;
  display: block;
  justify-content: center;
  text-align: center;
  padding: 30px 30%;
  border: 1px dashed a.$grey;
  border-radius: 4px;
  width: 100%;
  height: 150px;
  background: a.$lightGrey;
  border-radius: 4px;
}

.custella-file-notification {
  background: a.$lightGrey;
  border: 1px solid a.$grey;
  margin-bottom: 8px;
  padding: 3px 15px;
  height: 40px;

  #{&}-remove {
    right: 0;
  }
}

/* Form Layout: Dependancy Field */
.custella-input-box {
  display: block;
  border: 1px solid a.$lightGrey;
  border-radius: 4px;
  background: a.$lightGrey;
  width: 100%;
  height: 120px;
  padding: 0.95rem 1.75rem;

  #{&}-title {
    display: block;
    margin: 5px 0;
    font-family: a.$form-font;
    font-size: a.$normal-fontsize;
    font-family: a.text-weight(medium);
  }
}

/* Sticky Bread*/
.custella-stickey-breadcrumb {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 99;
  margin-left: 0.95rem;
}

/* Sticky Bread*/
.custella-setup-stickey-breadcrumb {
  width: 102%;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 99;
  margin-left: 0.95rem;
  top: 52px;
}

.custella-margin-top {
  margin-top: 3.9rem;
}

.custella-margin-top-2 {
  margin-top: 3.1rem;
}

.custella-right-body-position {
  padding-left: 0.5rem;
}

.custella-left-body-position {
  margin-left: 0.15rem;
}

.left-right-space {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

/* Setup View frame */
.left-setup-task, .left-setup-tix {
  width: 195px;
  background: a.$light-dark-color;
  // transform: translate(-30px, 0);
}
.left-setup {
  width: 220px;
  background: a.$light-dark-color;
  // transform: translate(-30px, 0);
}

.left-team-view {
  width: 220px;
  background: #f0f0f0;
  border-right: gainsboro 1px solid;
  border-left: gainsboro 1px solid;
}
.teams-setup{
  height: calc(100% - 78px) !important;
  margin-top: 42px;
}

.right-setup-task {
  width: calc(100vw - 26.2rem);
  // overflow-y: scroll;
  // overflow-x: hidden;
  background: #F3F3F3;
}

.right-setup {
  width: calc(100vw - 28rem);
  // overflow-y: scroll;
  overflow-x: hidden;
  background: #F3F3F3;
}
@media (min-width: 1025px) {
  .wrapper.layout-sidebar-active .main .right-setup {
    width: calc(100vw - 20rem);
    overflow-y: scroll;
    overflow-x: hidden;
    background: #F3F3F3;
  }
  .wrapper.layout-sidebar-active .main .right-setup-task {
    width: calc(100vw - 18.3rem);
    overflow-y: scroll;
    overflow-x: hidden;
    background: #F3F3F3;
  }
}
@media (max-width: 1024px) {
  .wrapper.layout-sidebar-active .main .right-setup {
    width: calc(100vw - 20rem);
    overflow-y: scroll;
    overflow-x: hidden;
    background: #F3F3F3;
  }
  .wrapper.layout-sidebar-active .main .right-setup-task {
    width: calc(100vw - 18.3rem);
    overflow-y: scroll;
    overflow-x: hidden;
    background: #F3F3F3;
  }
}

.custella-general-setup {
  width: 80vw;
  height: 85vh;

  @media (max-device-width: 1280px) and (orientation: landscape) {
    width: 67vw;
    height: 85vh;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    width: 72vw;
    height: 85vh;
  }

  #{&}-width {
    max-width: 105rem;
  }
}

.custella-setup-body{
  overflow: scroll;
  position: relative;
  height: calc(100% - 4.3vh);
  padding-top: 3.9rem;
  padding-bottom: 1rem;
  .non-scroll{
    /* overflow: hidden; */
    height: unset;
    position: fixed;
    width: -webkit-fill-available;
    width: -moz-available; 
    .start-scroll-vh{
      height: calc(100vh - 150px);
      overflow: overlay;
    }
  }
}
.custella-setup-body-not{
  // overflow: scroll;
  // position: relative;
  // height: calc(100% - 4.3vh);
  // padding-top: 3.9rem;
  padding-bottom: 3.5rem;
  display: block;
  &.modules{
    padding-bottom: 4.5rem;
  }
}

.subContractor-vt .vt{
  height: calc(100% - 10px) !important;
}

/* Filter pane */
.custella-form-container-content-pane {
  background: a.$background-color;
  box-shadow: a.$form-shadow;

  #{&}-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #e0e0e0;
    color: #212121;
    padding: 0.5rem 1rem;
    .fa-regular:hover{
      color: a.$primary-color
    }
  }
  .custella-content{
    font-size: a.$normal-fontsize;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    &-input{
      margin-bottom: 0.75rem;
    }
  }
  .clear{
    font-size: a.$s-fontsize;
    cursor: pointer;
    text-decoration: underline;
    &:hover{
      color: a.$primary-color;
    }
  }
}

/* Attachment Screen */
.custella-attachment-files{
  min-height: 57vh;
  li div {
    &:hover {
      background: a.$white3;
    }
  
    &.selected {
      background: a.$white3;
    }
  }
  .content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    flex-wrap: wrap;
    .flex-align-center{
      img{
        width: 30px;
      }
      div{
        .filename{
          font-size: 12px;
          font-family: a.$form-font-medium;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 350px;
        }
        .filetime{
          font-size: 10px;
          font-family: a.$form-font-medium;
          color: a.$lightGrey5;
        }
      } 
    } 
  }
}


/* SLA */
.custella-sla-progress {
  background: a.$background-color;
  color: a.$black;
}

.sla-time {
  position: absolute;
  top: 4.3rem;
  font-size: 10px;
  color: #fff;
  left: 30px;
}

.sla-time-box {
  width: 150px;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.sla-popup {
  min-width: 350px;
}

.elips {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.border-right {
  border-right: 1px solid #eaeaea;
}

/* Fault and Resolution */
.custella-fr-opt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0.75rem 0.95rem ;
  cursor: pointer;
  &:hover {
    border: 1px solid a.$primary-color;
  }
  &:active {
    background: a.$lightViolet3;
    color: a.$violet3;
    border: 2px solid a.$violet4;
  }
  .selected-fr {
    background: a.$lightViolet3;
    color: a.$violet3;
    border: 2px solid a.$violet4;
  }
}
.custella-fr-box {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 0.75rem 0.95rem ;
  cursor: pointer;
  &:hover {
    border: 1px solid a.$primary-color;
  }
  &:active {
    background: a.$lightViolet3;
    color: a.$violet3;
    border: 2px solid a.$violet4;
  }
 &.selected-fr {
    background: a.$lightViolet3;
    color: a.$violet3;
    border: 2px solid a.$violet4;
  }
}

.right-pane-fr{
  position: fixed;
  top: 52px;
  right: 0;
  background: white;
  height: 100%;
  width: 330px;
  z-index: 100;
  box-shadow: a.$form-shadow;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}
.right-pane-fr-task{
  position: fixed;
  top: 0px;
  right: 0;
  background: white;
  height: 100%;
  width: 330px;
  z-index: 100;
  box-shadow: a.$form-shadow;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
}
.right-pane-fr-summ{
  position: fixed;
  top: 94px;
  right: 0;
  background: white;
  height: 100%;
  width: 370px;
  z-index: 100;
  box-shadow: a.$form-shadow;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  .custella-form-container{
    height: 100%;
    .scroll{
      overflow: scroll;
      height: 82%;
      padding-bottom: 25px;
    }
  }
}

.right-pane-fr-cus-summ{
  position: fixed;
  top: 94px;
  right: 0;
  background: white;
  height: 100%;
  width: 370px;
  z-index: 100;
  box-shadow: a.$form-shadow;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  .custella-form-container{
    height: 100%;
    .scroll{
      overflow: scroll;
      height: 80%;
      padding-bottom: 25px;
    }
  }
}
.right-pane-fr-cus-summ-task{
  position: fixed;
  top: 0;
  right: 0;
  background: white;
  height: 100%;
  width: 370px;
  z-index: 100;
  box-shadow: a.$form-shadow;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  .scroll{
    overflow: scroll;
    height: 650px;
    padding-bottom: 25px;
  }
}

.slide-left-two {
  right: -331px;
  animation: slide-left 0.5s forwards;
  -webkit-animation: slide-left 0.5s forwards;
}
.slide-left-two-summ {
  right: -371px;
  animation: slide-left 0.5s forwards;
  -webkit-animation: slide-left 0.5s forwards;
}

.slide-left-two-cus-summ {
  right: -371px;
  animation: slide-left 0.5s forwards;
  -webkit-animation: slide-left 0.5s forwards;
}

.slide-right-two {
  right: 0px;
  animation: slide-right 0.5s forwards;
  -webkit-animation: slide-right 0.5s forwards;
}


/* Skills; Roles and Levels box */
.custella-skills-box {
  align-items: center;
  border: 1px solid #eaeaea;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  #{&}-link {
    font-family: a.$form-font;
    font-size: a.$normal-fontsize;
    color: a.$primary-color;
    display: block;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

/* Helpdesk Email Conversation */
.custella-conversation {
  #{&}-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: a.$white;
    }
  }

  #{&}-list {
    color: a.$white !important;

    &:hover {
      background: a.$dark-color;
      color: a.$white !important;
      border-radius: 4px !important;
    }

    &:focus {
      background: a.$dark-color;
      color: a.$white !important;
      border-radius: 4px !important;
    }

    &:active {
      background: a.$dark-color;
    }
  }
}

/* Check Availability Screen */
.left-bar {
  width: 220px;
  background: white;
  box-shadow: a.$form-shadow;

  #{&}-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: a.$headerblack;
    color: a.$white;
  }

  #{&}-title-grey {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: a.$grey;
    color: a.$headerblack;
  }
}

.left-bar .p-tabview .p-tabview-nav-container {
  position: relative !important;
  width: 100% !important;
  border-top: none;
  margin: 0;
}

.left-bar .p-tabview .p-tabview-panels {
  padding: 0 !important;
  background: white !important;
}

.left-bar .p-tabview .p-tabview-nav li {
  width: 50%;
}

.left-bar .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.left-bar .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

// In screen tab
.in-screen-tabs {
  width: 100%;
  background: a.$background-color;
  box-shadow: a.$form-shadow;
  border-radius: 4px;

  #{&}-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: a.$headerblack;
    color: a.$white;
  }
}

.in-screen-tabs .p-tabview .p-tabview-nav-container {
  position: relative !important;
  width: 100% !important;
  border-top: none;
  margin: 0;
}

.in-screen-tabs .p-tabview .p-tabview-panels {
  background: white !important;
  padding: 0 !important;
}

.button-tabs-50 {
  background: #f1f0f1;
  box-shadow: a.$form-shadow;
  border-radius: 4px;
  padding: 1rem;
}

.px-3-5 {
  padding-right: 1.75rem !important;
  padding-left: 1.75rem !important;
}

// for certain google map autocomplete show up front when modal - adjust higher small steps if needed
.pac-container {
  z-index: 1155 !important;
}

/* Task Screen */
.skills {
  background-color: a.$primary-color;
  color: #fff;
  border-radius: 10px;
  padding: 4px;
}

// priority indicator
.circle {
  margin-top: 3px;
  margin-right: 10px;
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: a.$grey4;
  margin-bottom: 1px;
}

.low {
  background: a.$low-priority;
}

.medium {
  background: a.$medium-priority;
}

.high {
  background: a.$high-priority;
}

/* for highlight text */
.highlighted-text {
  background: #ffefc4;
}

/********* Diff height****/
.h-91 {
  height: 91% !important;
}
.h-92 {
  height: calc(100% - 80px) !important;
}

.h-95 {
  height: 95% !important;
}

.h-97 {
  height: 97% !important;
}

.h-100-10 {
  height: calc(100% - 10px) !important;
}
.h-100-28 {
  height: calc(100% - 28px) !important;
}

.h-100-56 {
  height: calc(100% - 56px) !important;
}

.h-100-70 {
  height: calc(100% - 70px);
}
.h-100-95px{
  height: calc(100% - 95px) !important;
}
.h-100-100px{
  height: calc(100% - 100px) !important;
}
.h-100-122px{
  height: calc(100% - 122px) !important;
}
.h-100-128px{
  height: calc(100% - 128px) !important;
}
// .custella-setup-body .tab-noscroll .h-100-70{
//   height: 0 !important;
// }

/* 26/9/2024; adding height that automatically follows the browser's resolution */
.h-full{
  height: -webkit-fill-available;
  height: -moz-available;
  flex-grow: 1;
}
.h-95-20{
  height: calc(100% - 42px);
}
.h-34 {
  height: 34px !important;
}
.h-100-37{
  height: calc(100% - 37px) !important;
}
.h-100-205{
  height: calc(100% - 205px) !important;
}


// helpdesk ticket adjust back tab view
.ticketViewContainer .p-tabview .p-tabview-nav-container {
  position: absolute;
  margin-left: 0.8rem !important;
  margin-right: 1.5rem !important;
  // padding-right: 175px;
  width: 100% !important;
}
@media (min-width: 1025px) {
  .wrapper.layout-sidebar-active .main .ticketViewContainer .p-tabview .p-tabview-nav-container {
    width: 100% !important;
  }
}
@media (max-width: 1024px) {
  .wrapper.layout-sidebar-active .main .ticketViewContainer .p-tabview .p-tabview-nav-container {
    width: 100% !important;
  }
}

// FOR CONTRACTS/SCHEDULE/MAGNUM DASHBOARD MAINTENANCE COLORS
.s-new {
  background-color: #1e78ba;
}

.s-open {
  background-color: #B4FFEE;
}

.s-onRent {
  background-color: #d0a156;
}

.s-expired {
  background-color: #c9c9c9;
}

.s-terminated {
  background-color: #dd4e4e;
}

.s-pending {
  background-color: #FF7655;
}

.s-overDue {
  background-color: #EF4B4B;
}

.s-completed {
  background-color: #3CD55F;
}

.s-incomplete {
  background-color: #959595;
}

.s-Inprogress {
  background-color: #FECD55;
}

.s-active {
  background-color: #1aac20;
}

.s-draft {
  background-color: #CDCCFF;
}

.s-cancel {
  background-color: #D77D7D;
}

.s-maintenance {
  background-color: #ce4343;
}

.s-enroute {
  background-color: #edc054;
}

.s-overDue {
  background-color: #ef4b4b;
}

.s-void {
  background-color: #db5383;
}

// FOR CANCEL CONTRACT, CREATE/EDIT CONTRACT
#cancel_contract_container,
#addEditContract {

  // override global
  .p-tabview-nav-container {
    margin-left: 0 !important;
  }

  .p-tabview .p-tabview-panels {
    background: none;

    padding: 0 !important;
  }

  .p-tabview .p-tabview-nav-container {
    position: unset;
    width: unset !important;
  }
}

// FOR TABLE ASSET AND ACCESSORIES
.headerTable {
  background-color: a.$lightGrey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  span {
    word-break: unset;
    font-size: 12px;
    font-weight: 700;
  }
}
.contentTable{
 @extend .d-flex;
 @extend .flex-row;
 @extend .justify-content-between;
 @extend .text-center;
 @extend .align-items-center;
}

// Setup: Import/Export path
.custella-import-checkpoint {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: a.$primary-color;
}

.custella-import-uncheckpoint {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: a.$grey9;
}

.custella-import-path {
  border-style: solid;
  border-width: 0.5px;
  width: 25%;
  border-color: a.$primary-color;
  background: a.$primary-color;
}

.custella-import-path-export {
  border-style: solid;
  border-width: 0.5px;
  width: 80%;
  border-color: a.$primary-color;
  background: a.$primary-color;
}

.custella-import-path-dash {
  border-style: dashed;
  border-width: 0.5px;
  width: 25%;
  border-color: a.$grey9;
}

.custella-import-path-dash-export {
  border-style: dashed;
  border-width: 0.5px;
  width: 80%;
  border-color: a.$grey9;
}

.custella-import-path-none {
  border-style: solid;
  border-width: 0.5px;
  width: 25%;
  border-color: transparent;
}

.custella-import-path-none-export {
  border-style: solid;
  border-width: 0.5px;
  width: 80%;
  border-color: transparent;
}

.custella-do-path {
  border-style: solid;
  border-width: 0.5px;
  width: 25%;
  border-color: #6fbc60;
  background: #6fbc60;
}

.f-blue {
  color: #2294e3 !important;
}

p-gmap {
  width: 100%;
}

// grey padding on certain uis
.grey-padding {
  border-radius: 6px;
  padding: 8px;
  background-color: #f5f5f5;
}

// criteria box
.custella-grey-box {
  border: 1px solid a.$white3;
  min-height: 120px;
  background-color: a.$white3;
  // margin-top: 4px;
  // margin-bottom: 16px;
  border-radius: 5px;
  padding: 8px;
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  .operators{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
}
.custella-grey-box-cond {
  border: 1px solid a.$white3;
  background-color: a.$white3;
  // margin-top: 4px;
  // margin-bottom: 16px;
  border-radius: 5px;
  padding: 8px;
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  .operators{
    display: flex;
    align-items: baseline;
  }
}

// checklist answers box
.answers-box{
  display: flex;
  align-items: baseline;
}
@media only screen and (max-width: 992px) {
  .answers-box {
      flex-direction: column;
  }
}
.custella-gb-box {
  box-shadow: a.$form-shadow;
  // min-height: 150px;
  background-color: a.$white;
  margin: 0.8rem 0;
  // border-radius: 5px;
  padding: 0;
  width: -webkit-fill-available;
}
.custella-gb-title {
  background: a.$grey;
  color: a.$headerblack;
  padding: 0.85rem 1.95rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custella-gb-content {
  background: a.$white;
  padding: 0.85rem 1.95rem;
  .pill{
    // max-width: 300px;
    margin-bottom: 1rem;
    .pill-text{
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
  }
}
.title-link {
  // margin: 5px 0;
  font-family: a.$form-font;
  font-size: a.$normal-fontsize;
  // font-family: 600;
  color: a.$primary-color !important;
  display: block;
  cursor: pointer;
}

.title-link:hover {
  // margin: 5px 0;
  font-family: a.$form-font;
  font-size: a.$normal-fontsize;
  // font-family: 600;
  color: a.$primary-color;
  display: block;
  text-decoration: underline;
}
// criteria box
.custella-grey-box-user {
  border: 1px solid a.$white3;
  min-height: 100px;
  background-color: a.$white3;
  margin-top: 4px;
  border-radius: 5px;
  padding: 8px;
  width: -webkit-fill-available;
  width: -moz-available;
}
.grey-box-integra {
  background: a.$grey12;
  border-radius: 5px;
}

/* Vehicle Creation Box */
.custella-vehicle-box {
  border: 1px solid a.$grey5;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 1.5rem;

  .text-10 {
    color: a.$lightGrey5;
  }

  .pi-sitemap {
    color: a.$lightGrey5;
    font-size: 24px;
  }

  :hover {
    color: a.$white;
    background: a.$primary-color;
    padding: 0;
    border-radius: 4px;

    .text-10 {
      color: a.$white;
    }

    .pi-sitemap {
      color: a.$primary-color;
      background: a.$white3;

    }

    .bg-white2 {
      background: a.$white3;
    }
  }

  :active {
    color: a.$white;
    background: a.$primary-color;
    padding: 0;
    border-radius: 4px;

    .text-10 {
      color: a.$white;
    }

    .pi-sitemap {
      color: a.$primary-color;
      background: a.$white3;
    }

    .bg-white2 {
      background: a.$white3;
    }
  }

  :focus {
    color: a.$white;
    background: a.$primary-color;
    padding: 0;
    border-radius: 4px;

    .text-10 {
      color: a.$white;
    }

    .pi-sitemap {
      color: a.$primary-color;
      background: a.$white3;
    }

    .bg-white2 {
      background: a.$white3;
    }
  }
}

// Delivery Orders: View
.custella-do-checkpoint {
  display: flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
  background-color: #6fbc60;
}

.custella-do-uncheckpoint {
  border-radius: 50%;
  height: 14px;
  width: 24px;
  background-color: a.$grey9;
}

.custella-do-text {
  font-size: 12px;
  font: a.$form-font-medium;
  white-space: nowrap;

  ::after {
    color: a.$grey9;
  }
}

  /* Messages */
  .messages-list{
    @media screen and (max-device-width: a.$max-width-laptop-screen) {
      width: 35% !important;
      min-width: 350px !important;
    }
  }
  .text-message {
    flex-wrap: nowrap;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 160px;
    // margin-left: 2.5rem;
}
  .w-25-300
  {
    width: 25% !important;
    min-width: 350px !important;
  }

  .custella-messages{
    cursor: pointer;
    padding: 0;
    :hover{
      background: a.$grey10;
    }
    :focus{
      background: a.$grey10;
    }
    :active{
      background: a.$grey10;
    }
  }
  .custella-messages.active-chat{
    background: a.$grey10;
  }

  .custella-messages.active {
    background: a.$grey10;
  }

  .custella-chatbox-delivered{
    background: #eeeeee;
    border-radius: 0.75rem 0.75rem 0.75rem 0;
    min-height: 50px;
    white-space: normal;
    padding: 10px 12px;
    margin-left: 2rem;
    max-width: 60%;
    word-wrap: break-word;
    float: left;
  }

  .delivered-font
  {
    color: #869aa5;
  }
  .recipient-font
  {
    color: #ffffff;
    opacity: 0.7;
  }

  .custella-chatbox-recipient{
    background: #dcddfa;
    border-radius: 0.75rem 0.75rem 0 0.75rem;
    min-height: 50px;
    white-space: normal;
    padding: 10px 12px;
    margin-left: 3rem;
    word-wrap: break-word;
    max-width: 60%;
    float: right;
  }

// for hierarchy assign
#assignRole .p-picklist .p-picklist-list, #assignGroup .p-picklist .p-picklist-list {
  height: 25rem !important;

}

/* Planning Hub */
.ph-peek{
  max-width: 159px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
.ph-peek-cust{
  max-width: 100%; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
.ph-peek-icon-detail{
  max-width: 100%; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
.ph-peek-short{
  max-width: 110px; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
}
@media (min-width: 1025px) {
  .ph-peek-short {
    max-width: 126px;
  }
}

@media (max-width: 1024px) {
  .ph-peek-short {
    max-width: 110px;
  }
}

// Orders Selected Divs
.custella-cards-list {
  background: #f8f8f8;
  // box-shadow: 0px 4px 15px -4px rgba(117, 115, 115, 0.8);
  padding: 10px;
  display: block;
  margin: 0;
  border-radius: 0.5rem;
  border: 1px solid a.$grey5;
  cursor: move;
}
.custella-cards-list-desc {
  background: #fff;
  // box-shadow: 0px 4px 15px -4px rgba(117, 115, 115, 0.8);
  padding: 10px;
  display: block;
  margin: 0;
  border-radius: 0.5rem 0 0 0.5rem;
  border: 1px solid a.$grey5;
  cursor: default;
}
.custella-cards-list-del {
  background: #d15555;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  width: 27px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custella-ph-header {
  background: a.$black;
  height: 60px;
  width: 100%;
  padding: 12px 10px;

  #{&}-btn {
    border-radius: 0.25rem;
    background: a.$white3;
    // align-items: center;
    // justify-content: center;
    // display: flex;
    width: 32px;
    height: 32px;
    padding: 6px;
    cursor: pointer;
   
  }
  #{&}-btn-refresh {
    border-radius: 0.25rem;
    background: transparent;
    width: 32px;
    height: 32px;
    padding: 6px;
    cursor: pointer;
   
  }

  #{&}-btn-action {
    border-radius: 0.25rem;
    background: a.$primary-color;
    color: a.$white;
    // align-items: center;
    // justify-content: center;
    // display: flex;
    width: 32px;
    height: 32px;
    padding: 5px;
    cursor: pointer;
    // .dropdown div img{
    //   margin-left: 0.65rem;
    // }
  }
}

.ph-center {
  height: 87%;
  width: 96%;
  position: fixed;

  &.table {
    height: calc(100% - 129px);
  }
}

.ph-table-fit {
  height: 65vh;
  // @media only screen and (max-width: 1025px)  {
  //   margin-right: 2rem;
  // }
}
.order-detail-ph{
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  height: 20px;
  .title{
    margin-top: 5px;
  }
}
.opt-orders-selected{
  overflow: scroll;
  // max-height: 400px;
}
@media (min-width: 1920px) {
  .opt-orders-selected {
    max-height: 51.9vh;
  }
}

@media (max-width: 1600px) {
  .opt-orders-selected {
    max-height: 44vh;
  }
}

@media (min-width: 1920px) {
  .opt-screen {
    height: 54.5vh;
  }

}

@media (max-width: 1600px) {
  .opt-screen {
    height: 43vh;
  }
}

@media (min-width: 1920px) {
  .ph-table-view {
    width: 100% ;
  }
 
}

// @media (max-width: 1600px) {
//   .ph-table-view {
//     width: calc(100% - 171px);
//   }
  
// }
.ph-table-view {
  width: 100%;
}

// Task
.acceptBtn {
    background-color: #6366f1;
    color: #fff;
    border: 1px solid #6366f1 !important;
    padding: 2px 4px 2px 4px !important;
  }

  .declineBtn {
    background-color: #f5f5f5;  
    margin-right: 2px;
    padding: 2px 2px 2px 6px !important;
  }

//Footer 
.custella-ph-footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 170px);
  background: a.$white3;
  height:250px;
  //overflow:scroll;
  // margin: 0 1.5rem 0.25rem 1.5rem;

  #{&}-title {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.85rem 1.75rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  #{&}-container {
    // box-shadow: 0px 4px 15px -4px rgba(117, 115, 115, 0.8);
    background: a.$background-color;
    // height: 349px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 200px;
    overflow: scroll;
  }
}

.expandWidth {
  width: calc(100% - 60px) !important;

}

.slideUpMore {
  transition: all 0.4s ease-out;
  height: 80%;
  cursor: pointer;

  .custella-ph-footer-container{
    height: 93%;
  }
}

.slideUp {
  transition: all 0.4s ease-out;
  height: 250px;
  cursor: pointer;
  .custella-ph-footer-container{
    height: 200px;
    
  }
}

.slideDown {
  transition: all 0.4s ease-out;
  height: 50px;
  cursor: pointer;
}

//Left Bar
.left-bar-ph {
  position: fixed;
  top: 52px;
  width: 340px;
  background: white;
  box-shadow: a.$form-shadow;
  z-index: 18;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);

  #{&}-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: a.$headerblack;
    color: a.$white;
  }

  #{&}-title-grey {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: a.$grey;
    color: a.$headerblack;
  }

  #{&}-card {
    background: a.$white2;
    color: a.$black;
    box-shadow: a.$form-shadow;
    border: 1px solid #dedede;
    border-radius: 0.5rem;
    overflow: hidden;
    margin-bottom: 0.5rem;
    cursor: pointer;

    .p-avatar img {
      width: 22px !important;
      height: 22px !important;
      border-radius: 25px !important;

    }
  &:hover{
    border: 1px solid #6366F1;
  }
  &:active{
    border: 1px solid #6366F1;
  }
  &:focus{
    border: 1px solid #6366F1;
  }
  
  
  }
}


.left-bar-ph .p-tabview .p-tabview-nav-container {
  position: relative !important;
  width: 100% !important;
  border-top: none;
  margin: 0;
}

.left-bar-ph .p-tabview .p-tabview-panels {
  padding: 0 !important;
  background: white !important;
}

.left-bar-ph .p-tabview .p-tabview-nav li {
  width: 50%;
}

.left-bar-ph .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.left-bar-ph .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.5rem 1.5rem !important;
  // padding-left: 1.5rem !important;
}

.slide-in {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.slide-out {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
  }
}

// Right Bar
.right-bar-ph {
  position: fixed;
  right: 0;
  background: white;
  min-height: 280px;
  width: 550px;
  z-index: 19;
  box-shadow: a.$form-shadow;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);

  #{&}-info {
    background: #f9f9f9;
    margin: 1rem 1.5rem;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    min-height: 190px;

    &-row {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      min-height: 20px;
    }
  }
}

.slide-left {
  right: -551px;
  animation: slide-left 0.5s forwards;
  -webkit-animation: slide-left 0.5s forwards;
}

.slide-right {
  right: 0px;
  animation: slide-right 0.5s forwards;
  -webkit-animation: slide-right 0.5s forwards;
}

@keyframes slide-left {
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
  }
}

//Modify Plan
.custella-modify-left {
  // background: #f0f0f0;
  padding: 1rem;
  border-radius: 0.25rem;
  border-right: 1px solid #dee2e6;
  // border: 1px solid #d0d0d0;
  // box-shadow: a.$form-shadow;
  min-height: 420px;

  #{&}-card {
    background: a.$white;
    color: a.$black;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid a.$grey9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      background: a.$primary-color;
      color: a.$white;
    }
    &.selected-card{
      background: a.$primary-color;
      color: a.$white;
    }
  }
}

.custella-modify-right {
  // background: a.$white2;
  padding: 1rem;
  border-radius: 0.25rem;
  // border: 1px solid #d0d0d0;
  // box-shadow: a.$form-shadow;
  min-height: 430px;

  #{&}-card {
    background: a.$white;
    color: a.$black;
    padding: 0.75rem 1rem;
    margin-bottom: 0.75rem;
    border-radius: 0.5rem;
    border: 1px solid a.$grey9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      background: a.$primary-color;
      color: a.$white;
    }
  }
}

.bg-grey3 .p-avatar img{
  height: 5rem;
  width: 5rem;
}

.custella-action{
  background: a.$white2;
  color: a.$black !important;
  box-shadow: a.$form-shadow;
  width: 27.5rem;
  #{&}-dropdown{
    background: a.$white;
    color: a.$black !important;
    padding: 0px;
    border-bottom: 1px solid a.$grey9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      background: a.$primary-color;
      color: a.$white !important;
    }

    &:focus {
      background: a.$primary-color;
      color: a.$white !important;
    }

    &:active {
      background: a.$primary-color;
      color: a.$white !important;
    }
  }
  &.show{
    transform: translate(-126px, 102px) !important
  }
}
.weightUnit{
  font-size: 9px;
  position: absolute;
  margin-top: -22px;
  z-index: 8;
  right: 153px;
  text-transform: lowercase
}
.clone{
  position: absolute;
  right: 28px;
  margin-top: -34px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.remove-item{
  position: absolute;
  right: 5px;
  margin-top: -34px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.modal,.modal-backdrop{
  z-index: 1002 !important
}
.p-component-overlay{
  z-index: 1000 !important
}
.p-sidebar-right{
  z-index: 1001 !important
}

// for override view do planning hub tabs styling
#viewDOTabs .p-tabview-panels {
  padding: 0 !important;
}

/************************ PLaning Hub Status ****************************/
.lowCapacity { color: #24d13c; }
/* <50 */
.mediumCapacity { color: #FDE250; }
/* 50-80 */
.highCapacity { color: #FD9696; }
  /* >80 */
.fade-opacity-04
{
    filter: opacity(0.4);
}
.fade-opacity-05
{
     opacity:0.5;
}
.magnum-progress {
  height: 1.3rem !important;
  border-radius: 0 !important;
}

.bg-color1 {
  background-color: #fecc7b !important;
}
.bg-color2 {
  background-color: #78919f !important;
}

/* Dashboard */
/* Hills */
.custella-dash-chart{
  border-radius: 0.5rem;
  padding: 10px;
  margin: 0 10px;
  box-shadow: a.$form-shadow;
  min-height: 420px;
}
.custella-dash-chart-no-min{
  border-radius: 0.5rem;
  padding: 10px;
  margin: 0 10px;
  box-shadow: a.$form-shadow;
}
.custella-dash-chart-two{
  border-radius: 0.5rem;
  padding: 10px;
  margin: 0 10px;
  box-shadow: a.$form-shadow;
  #{&}-title{
    background: a.$grey;
    color: a.$headerblack;
    padding: 0.85rem 1.95rem;
  }
}

/* Chubb */
.all-team-box {
  display: grid;
  grid-template-columns: repeat(4, minmax(217px, auto));
  grid-auto-rows: 130px;
  padding: 0.5rem 0;
}
.all-team-box-chart {
  display: grid;
  grid-template-columns: repeat(4, minmax(220px, auto));
  padding: 0.5rem 0;
}
.data-label-chart {
  display: grid;
  grid-template-columns: repeat(2,1fr);
  letter-spacing: 0;
  color: #7c828a;
  gap: 0 10px;
}
.team-box{
  text-align: center;
  background: #354954;
  margin: 0.3rem;
  border-radius: 12px;
}
.color-bar {
  height: 11px;
  border-radius: 12px 12px 0px 0px;
}
.team-name {
  background: #27373F;
  color: #FFFFFF;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
.team-task-data {
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
}
.masking-svg{
  background-color: #495057;
  width: 20px;
  height: 20px;
  mask-size: cover !important;
}
.masking-svg-opt{
  background-color: #49505750 !important;
}
.task-lock{
    -webkit-mask: url(../../svg/lock-key.svg) no-repeat center;
    mask: url(../../svg/lock-key.svg) no-repeat center;
  
}
.task-lock-key-open{
    -webkit-mask: url(../../svg/lock-key-open.svg) no-repeat center;
    mask: url(../../svg/lock-key-open.svg) no-repeat center;
 }
 .masking-svg-white{
  background-color: #fff !important;
  }
.action-info{
    -webkit-mask: url(../../svg/table/info-circle.svg) no-repeat center;
    mask: url(../../svg/table/info-circle.svg) no-repeat center;
}
.action-ph{
  -webkit-mask: url(../../svg/header/pencil.svg) no-repeat center;
  mask: url(../../svg/header/pencil.svg) no-repeat center;
}
.modifiy-ph{
  -webkit-mask: url(../../svg/header/plus-circle.svg) no-repeat center;
  mask: url(../../svg/header/plus-circle.svg) no-repeat center;
}

.table-ph{
  -webkit-mask: url(../../svg/table/table-icc.svg) no-repeat center;
  mask: url(../../svg/header/plus-circle.svg) no-repeat center;
}
.map-ph{
  -webkit-mask: url(../../svg/sidebar/map.svg) no-repeat center;
  mask: url(../../svg/sidebar/map.svg) no-repeat center;
}
.refresh-ph{
  -webkit-mask: url(../../svg/refresh.svg) no-repeat center;
  mask: url(../../svg/refresh.svg) no-repeat center;
}
.carTypeColorCode-PH{
  background: #afafaf !important;
}

.team-task-ms-user{
  background: #f0f0f0;
  border-radius: 10px 10px 10px 0px;
  width: 75%;
  float: left;
  margin-top: 0.5rem !important;
  word-wrap: break-word;
}
.team-task-ms-owner{
  background: #EDEDFF;
  border-radius: 10px 10px 0px 10px;
  width: 75%;
  float: right;
  margin-top: 0.5rem !important;
  word-wrap: break-word;
}
.team-task-msg-body{
    border: solid 1px #dee2e6;
    border-radius: 9px;
    background: #fff;
    padding: 4px;

    .textarea{
    width: 100%;
    border: none;
    resize: inherit;
    padding: 4px;
    }
    textarea:focus{
      outline: none;
  }
}

.slideshow-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.slideshow-container {
  background: #000000;
  position: relative;
  top: 0;
  z-index: 1000;
  left: 0;
  width: 100%;
  padding: 52px 2rem 2rem 2rem;
  height: 100%;
}

.slideshow-header {
  display: flex;
  align-items: center;
  letter-spacing: 0px;
  color: #FFFFFF;
  padding-top: 3rem;
}
@media (min-width: 1025px) {
  .slideshow-header {
    padding-left: 11rem;
  }
}

@media (max-width: 1024px) {
  .slideshow-header {
    padding-left: 5rem;
  }
}

.slideshow-footer {
  flex: 1;
  align-items: end;
}
@media (min-width: 1025px) {
  .slideshow-footer {
    padding-left: 11rem;
  }
}

@media (max-width: 1024px) {
  .slideshow-footer {
    padding-left: 5rem;
  }
}

.back-arrow {
  width: 16px;
  height: 16px;
  position: relative;
  bottom: 5px
}

.the-show {
  margin-top: 2rem;
  height: 70%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-chart-data-label{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 1rem 15px;
  letter-spacing: 0px;
  gap: 0px 10px;
}


/* planing-hub */
.custella-planing-hub-filter{
  position: absolute;
  display: block;
  transform: translate(-115%, 49%);
  z-index: 100;
  width: 22%;
}
@media (min-width: 1920px) {
  .custella-planing-hub-filter{
    transform: translate(-126%, 49%);
  }
  .custella-planing-hub-filter-vehicle{
    transform: translate(-217%, 49%) !important;
  }
}

.custella-planing-hub-filter-vehicle{
  position: absolute;
  display: block;
  transform: translate(-207%, 49%);
  z-index: 100;
  width: 22%;
}
.preview{
  position: relative;
  margin-bottom: 10px;
  #{&}-attachments{
    position: relative;
    background: a.$white;
    padding: 0.5rem;
    border-radius: 1.25rem;
    border: 1px solid a.$grey4;
    width: 5rem;
    height: 5rem;
  }
  #{&}-close{
    border-radius: 50%;
    border: 1px solid #878787;
    background: #FAFAFA;
    padding: 4px;
    height: 20px;
    position: relative;
    top: -4px;
    right: 15px;
  }
  .pi-times{
    font-size: 10px;
  }
}

.multiSelect-width{
  height: 35px; 
  display: flex; 
  align-items: end; 
  width: 255px;
}

.custella-taskOpt-header{
  background: a.$black;
  height: 43px;
  width: 100%;
  padding: 12px 10px;
  display: flex;
  align-items: center;
}

.custella-taskOpt-footer{
  bottom: -15rem;
  position: absolute;
  z-index: 10;
  #{&}-string{
    background-color: a.$black;
    cursor: pointer;
    border-radius: 0.375rem 0.375rem 0 0;
    border: none;
    height: 35px;
  }
  #{&}-body{
    background: transparent;
    .header{
      background: a.$black;
      color: a.$white;
      opacity: 0.9;
      padding: 12px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .body{
      height: 200px;
      // opacity: 0.7;
      background: #12191D90;
      overflow-y: scroll;
    
    }
    .body-card{
      display: flex;
      border-radius: 0.375rem;
      background: a.$white2;
      cursor: pointer;
    }
  }

}
.row-grid{
  display: grid;
    grid-column-end: five;
    grid-row-start: row1-start;
    grid-template-columns: 24% 24% 24% 24%;
    grid-template-rows: auto 80px;
    column-gap: 17px;
    row-gap: 16px;
}
.slideUpTask{
  bottom: -15rem;
  -webkit-animation: slideUpTask 1s forwards;
  -webkit-animation-delay: 0s;
  animation: slideUpTask 1s forwards;
  animation-delay: 0s;
}

@-webkit-keyframes slideUpTask {
  100% { 
      bottom: 3rem;
      display: block;
  
  }
}

@keyframes slideUpTask {
  100% { bottom: 3rem; display: block; }
}


.slideBottomTask{
  bottom: 3rem;
  -webkit-animation: slideBottomTask 1s forwards;
  -webkit-animation-delay: 0s;
  animation: slideBottomTask 1s forwards;
  animation-delay: 0s;
}

@-webkit-keyframes slideBottomTask {
  10% { display: none; }
  100% { bottom: -15rem; }
}

@keyframes slideBottomTask {
  10% { display: none; }
  100% { bottom: -15rem; }
}


@media (min-width: 1920px) {
  .task-notes {
   height: 710px;
  }
}

@media (max-width: 1600px) {
  .task-notes {
   height: 710px;
  }
}

/* Skills / Roles Start */
.role-dropdown {
  background: white;
  position: absolute;
  z-index: 10;
  width: 100%;
  border: 1px solid grey;
  padding: 10px;
  border-radius: 4px;
}

.role-dropdown .role-drop-item {
  padding: 10px;
  border: grey 1px solid;
  background: white;
  border-radius: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  position: relative;
}

.role-dropdown .role-drop-item:hover {
  background: #212121;
  color: white;
}

.user-assign-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 5px;
  margin: 5px;
}

.user-search-assign {
  background: white;
    border: 1px solid #6366F1;
    max-height: calc(100% - -25em);
    position: absolute;
    width: 100%;
    z-index: 11;
    overflow: auto;
    border-radius: 4px;
}

/* Skills / Roles End */

.planinghub-button input[type="radio"] {
  border: 2px solid #ced4da;
    background: #ffffff;
    width: 16px;
  height: 16px;
    color: #495057;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;

}

.planinghub-button input[type="radio"]:checked {
  border: 5px solid #6366F1;
  background: #ffffff;
  width: 16px;
  height: 16px;
  color: #495057;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}


/* Hide the browser's default checkbox */
.planinghub-button input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.c-checkbox-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: #FFF;
  border-radius: 4px;
  border: 2px solid #ced4da;
  background-color: #ffffff;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.c-checkbox-icon-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: #FFF;
  border-radius: 4px;
  border: 2px solid #ced4da;
  background-color: #ffffff;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

/* On mouse-over, add a grey background color */
.planinghub-button:hover input ~ .c-checkbox-icon {
  background-color: #fff;
}
.planinghub-button:hover input ~ .c-checkbox-icon-two {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.planinghub-button input:checked ~ .c-checkbox-icon {
  background-color: #6366F1;
  border: 2px solid #6366F1;
}
.planinghub-button input:checked ~ .c-checkbox-icon-two {
  background-color: #6366F1;
  border: 2px solid #6366F1;
}

/* Create the c-checkbox-icon/indicator (hidden when not checked) */
.c-checkbox-icon:after {
  content: "";
  position: absolute;
  display: none;
}
.c-checkbox-icon-two:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the c-checkbox-icon when checked */
.planinghub-button input:checked ~ .c-checkbox-icon:after {
  display: block;
}
.planinghub-button input:checked ~ .c-checkbox-icon-two:after {
  display: block;
}

/* Style the c-checkbox-icon/indicator */
.planinghub-button .c-checkbox-icon:after {
  left: 2px;
  top: 0px;
  border-radius: 0px;
  font-family: 'primeicons';
  content: "\e909";
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 12px;
}
.planinghub-button .c-checkbox-icon-two:after {
  left: 2px;
  top: 3px;
  border-radius: 0px;
  font-family: 'primeicons';
  content: "\e909";
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 12px;
}
.planinghub-button .indeterminate{
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  color: #FFF;
  border-radius: 4px;
  background-color: #6366F1;
  border: 2px solid #6366F1;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.planinghub-button .indeterminate:before {
  margin-left: 2px;
  margin-top: -1px;
  border-radius: 0px;
  font-family: 'primeicons';
  content: "\e90f";
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 13px;
  position: absolute;
}

// Shifts Calendar
.theCalendar .fc.fc-theme-standard .fc-toolbar .fc-button {
  text-transform: capitalize !important;
  height: 35px;
}

.theCalendar .fc .fc-timegrid-axis-frame
{
  text-transform: capitalize;
}
.b-grid-header-text{
  padding-left: 10px;
}


// shifts custom tab
.custom-tab-header {
  display: flex;
  height: 14px;
  align-items: center;
}

/* Pekat Forms */
.table-form-left {
  color: white;
  font-family: 400;
  background-color: #2a3a43;
  font-size: 13px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
  width: 220px;
}

.table-form-right {
  font-size: 13px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}

.table-form-top {
  color: white;
  font-family: 400;
  background-color: #2a3a43 !important;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.bin-in-zone .display-table{
  height: calc(100% - 2px) !important;
}
.bin-in-zone .bins{
 margin-top: 0 !important;
}
.bin-in-zone .bins .noData{
 overflow-y: hidden !important;
}
.table-form-bottom {
  font-size: 13px;
}
.group-in-tab{
  height: calc(100% - 120px);
  position: fixed;
  width: -moz-available;     
  width: -webkit-fill-available; 
  width: stretch;
}

.table-form-2 {
  border-right: 1px solid #dee2e6!important;
}

.b-sch-event {
  justify-content: center;
}

// fit content for status renderer jo - do
.ag-rich-select-list {
  height: 100% !important; 
}  
.b-sch-horizontal .b-sch-style-line > .b-sch-event{
  top: 50% !important;
}
.demo{
  min-height: 200px;
  overflow-y: scroll;
  display: flex;
 flex-wrap: wrap;
}
.demo .b-list-item{
  min-width: 48px !important;
}
.gmnoprint{
  display: none
}

.gm-style-iw-d{
  overflow: hidden !important
}
.gm-style .gm-style-iw-c{
  padding: 0 !important
}
.gm-ui-hover-effect>span{
  background-color: #fff !important;
}
.custella-form-container-marker {
  box-shadow: 0px 4px 15px -4px rgba(117, 115, 115, 0.8);
  background: a.$background-color;
  margin: 0 1.5rem;
  #{&}-title {
    background: a.$headerblack;
    color: a.$white;
    padding: 0.85rem 1.75rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  #{&}-content {
    padding: 0rem 1.5rem;
    margin: 1.5rem 0;
    font-family: a.$form-font;
    font-size: 12px;
    font-family: a.text-weight(medium);
    // min-height: 6rem;
  }
  #{&}-content-border-bottom {
    border-bottom: 1px solid a.$grey;
    min-height: 50px;
    padding: 0rem 2rem;
  }
  .ag-tooltip{
    background-color: #0f1014;
    color: #999eab;
    border-radius: 2px;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #545454;
 }
}
@media (min-width: 1025px) {
  .pa-box{
    width: 40%;
  }
  .pa-box2{
    width: 38.5%;
  }
}
@media (max-width: 1024px) {
  .pa-box{
    min-width: 36.5%;
  }
}
.custella-task-body{
  overflow: hidden;
  height: 100%;
  width: 100%;
  // position: fixed;
  padding-top: 3rem;
  padding-bottom: 3rem;
  left:0 ;
}

// for centering table column headers
.center-header-column * {
  display: flex;
  justify-content: center;
}

.width-90 { width: 90px; }

.rounded-top-right { border-top-right-radius: 4px; }
.rounded-top-left { border-top-left-radius: 4px; }
.rounded-bottom-left { border-bottom-left-radius: 4px; }
.rounded-bottom-right { border-bottom-right-radius: 4px; }



// :host ::ng-deep .custom-spinner-fleet .p-progress-spinner-circle {
//   animation: p-progress-spinner-color 2s linear infinite !important;
// }

// :host ::ng-deep .custom-spinner-fleet .p-progress-spinner-svg {
//   animation: p-progress-spinner-color 2s linear infinite !important;
// }

// @keyframes p-progress-spinner-color {
//   100%,
//   0% {
//     stroke: #6366F1 !important;
//   }
//   40% {
//     stroke: #6366F1 !important;
//   }
//   66% {
//     stroke: #6366F1 !important;
//   }
//   80%,
//   90% {
//     stroke: #6366F1 !important;
//   }
// }


.spinner-fleet .p-progress-spinner-circle {
  stroke: #6366F1 !important;;
  animation: fleetSpin 8s ease-in-out infinite !important;
}

@keyframes fleetSpin {
100%,
0% {
  stroke: #6366F1 !important;

}
40% {
  stroke: #6366F1 !important;
}
66% {
  stroke: #6366F1 !important;
}
80%,
90% {
    stroke: #6366F1 !important;
}
}

.userOnline{ border: a.$green-online solid 2px; }
.userOffline{ border: a.$red-offline solid 2px; }

// helpdesk table overides
.single-table .ag-center-cols-viewport {
  overflow-x: hidden;
}
.card-product{
  background: a.$white;
  margin: 10px 10px 10px 0;
  padding: 10px 23px;
  border-radius: 9px;
  min-width: 200px;
  max-width: 300px;
  box-shadow: a.$form-shadow;

}
/* Calendly Integration */
.grey-box-calendly {
  background: a.$white4;
  border-radius: 10px;
  margin: 10px 15px;
  padding: 7px;
  // min-height: 150px;

  #{&}-content {
    padding: 0 0.5rem;

    .grey-box-calendly-content-row{
      background: #FFF;
      padding: 10px;
      margin-bottom: 1rem;
      border-radius: 10px;
    }
  }
}
.cal-logo{
  margin-top: 0.25rem;
  img{
    height: 42px;
  }
}
.cal-logo-two{
  margin-top: 0.25rem;
  background: #ECECEC;
  border: 5px solid #ECECEC;
  border-radius: 5px !important;
  img{
    height: 42px;
  }
}
.cal-logo-two-mini{
  background: #ECECEC;
  border: 3px solid #ECECEC;
  border-radius: 5px !important;
  img{
    height: 25px;
  }
}
.preview-calendly-mini{
  background: #ECECEC;
  border: 5px solid #ECECEC;
  border-radius: 5px !important;
}
.preview-calendly{
  background: #ECECEC;
  border: 7px solid #D9D9D9;
  height: 235px;
  border-radius: 14px;
  padding: 0.5rem 1rem;
  // img{
  //   border: 1px solid #E5E5E5;
  // }
}
.preview-calendly-ext{
  background: #ECECEC;
  overflow: auto;
  .dp-plugin{
    margin: 25px;
    text-align: center;
  }
  .prev-cal-logo{
    display: flex;
    align-items: center;
    transform: translate(25px, 10px);
    .p-avatar.p-avatar-lg{
      width: unset !important;
    }
  }
}
@media only screen and (min-width: 480px) {
  .preview-calendly-ext{
    background: #ECECEC;
    padding: 5rem;
    overflow: auto;
    .dp-plugin{
      margin: 25px;
      text-align: center;
    }
  }
}


.display-hide{
  display: none
}
.display-block{
  display: block
}
.card-p{
  background: #32454e;
  color: #fff;
  margin: 10px;
  padding: 13px 23px;
  border-radius: 9px;
  min-width: 200px;
  max-width: 300px;
}

.per-page {
  float: left;
  width: 108px;
  display: flex;
  height: 32px;
  margin-top: -36px;
  z-index: 99;
  font-size: 12px;
  font-family: normal;
   position: absolute !important;
}

.survey-peek{
  max-width: 289px;
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis
}
.cs-response{
  position: absolute;
  text-align: center;
  padding: 20px;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  .sub-response{
    display: block;
    margin-top: 16px;
  }
}
.colorEdit{
  color: a.$white;
  position: absolute;
  transform: translate(-24px, 9px);
}
.colorSelect{
  color: a.$white;
  position: absolute;
  transform: translate(-29px, 14px);
}
.calendlyFrame{
  height: 700px;
  width: 100%;
}
@media only screen and (max-width: 1024px){
  .calendlyFrame{
    height: 950px;
    width: 100%;
  }
}

.lmtWIHO_gkbTeeyuvoJC.mOUYF5ZmuNL6I7t0mSFg{
  background-color: #1aac20;
}
.licenseBilling{
  margin-top: 0.5rem;
  margin-bottom: 3rem;
  overflow-y: auto;
  height: 100%;
}
.lv-desc{
  background: a.$white;
  border-radius: 8px;
  width: 100%;
  padding: 0.45rem 0.75rem;
  margin-bottom: 0.55rem;
  border: 1px solid #dee1e3;
  .text{
    font-size: 12px;
    font-family: a.$form-font-medium;
    .link{
      color: a.$primary-color;
      cursor: pointer;
      font-size: 12px;
    }
  }
}

.access-title{
  z-index: 100;
  position: fixed;
  // width: calc(100% - 392px) !important;
  width: 100%;
  background-color: #F3F3F3;
  .title{
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 0.5rem;
    word-break: keep-all; //This is used for Chinese, Japanese and Korean text words are not broken
  }
  .edit-btn{
    display: flex;
    align-items: center;
    position: fixed;
    right: 9px;
  }
}


/* Email Template */
.example-template{
  margin: 0.75rem;
  max-height: calc(100vh - 180px);
  height: auto;
  overflow: overlay;
  border: 1px solid a.$grey5;
  border-radius: 4px;
  // @media (min-width: 1025px) {
  //   height: calc(100vh - 270px);
  // }
  .header{
    // border-right: 1px solid a.$grey5;
    // border-top: 1px solid a.$grey5;
    // border-left: 1px solid a.$grey5;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 1rem;
    .image{
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        color: a.$primary-color;
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
      }
      img{
        max-height: 175px;
        max-width: 150px;
      }
    }
  }
  .image-container {
    position: relative;
    display: flex;
    width: 100%;
}

.indicator {
    position: absolute;
    top: 0;
    left: 0;
    background: #211f1f8a;
    padding: 5px;
    display: flex;
    gap: 5px;
    display: flex;
    gap: 5px;
    z-index: 10;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    i {
      cursor: pointer;
      font-size: 14px;
      color: #ffffff; 
  }
}

.indicator-prev{
    position: absolute;
    top: 0;
    left: 0;
    background: #211f1f8a;
    padding: 5px;
    display: flex;
    gap: 5px;
    display: flex;
    gap: 5px;
    z-index: 10;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    i {
      cursor: pointer;
      font-size: 14px;
      color: #ffffff; 
  }
} 

// .indicator i:hover {
//     color: #ffffff; /* Change color on hover */
// }


  .content-email{
    padding: 1.5rem;
    // border-right: 1px solid a.$grey5;
    // border-left: 1px solid a.$grey5;
    // border-top: 1px solid a.$grey5;
    // border-bottom: 1px solid a.$grey5;
    span{
      display: block;
    }
    p-button{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .content-table{
    padding: 1.5rem;
    // border-right: 1px solid a.$grey5;
    // border-left: 1px solid a.$grey5;
    // border-bottom: 1px solid a.$grey5;
    span{
      display: block;
    }
    p-button{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .bottom{
    // border-right: 1px solid a.$grey5;
    // border-bottom: 1px solid a.$grey5;
    // border-left: 1px solid a.$grey5;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1rem;
    .image{
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        color: a.$primary-color;
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
      }
      img{
        max-height: 75px;
        max-width: 150px;
      }
    }
  }

}
/*****/
.filter-section-content .content-container {
  /* margin-top:10px; */
  margin-bottom: 10px;
  padding-right: 10px
}
.filter-group .group-content {
  margin: 17px 0 10px 0;
  padding-left: 24px;
}

  .content-container>.filter-rule,.content-container>.filter-group {
  position: relative;
}
 .content-container>.filter-rule::before, .content-container>.filter-group::before {
  top: -7px;
  border-width: 0 0 2px 2px;
}

.content-container>.filter-rule::after{
  top: 50%;
  border-width: 0 0 0 2px;
}

.filter-section-content .content-container:last-child>.filter-rule::after, .content-container:last-child>.filter-group::after {
  display: none
}
 .content-container>.filter-rule::before,
  .content-container>.filter-rule::after,
  .content-container>.filter-group::before,
 .content-container>.filter-group::after
  {
  content: "";
  position: absolute;
  left: -14px;
  width: 14px;
  height: calc(50% + 8px);
  border-color: #3f5764;
  border-style: solid
}
.content-container:first-child>.filter-rule::before,  .content-container:first-child>.filter-group::before {
  top: -19px;
  height: calc(50% + 21px);
}
.filter-section-content .content-container:last-child>.filter-rule::before,.filter-section-content .content-container:last-child>.filter-group::before {
  border-radius: 0 0 0 4px
}
 .content-container>.filter-group::before, .content-container>.filter-group::after {
  left: -13px;
  width: 15px
}
 .content-container>.filter-rule::after, .content-container>.filter-group::after {
  top: 50%;
  border-width: 0 0 0 2px
}
.htmlContent div {
  display: -webkit-inline-box;
  width: 100%;
}

.sizing{
  position: fixed;
  height: calc(100% - 140px);
  width: 100%;
  display: flex;
  overflow: overlay;
  padding-bottom: 10px;
}
/* Adding Banner to Setup */
.banner{
  background: a.$white;
  border-radius: 10px;
  border: 1px solid a.$grey3;
  box-shadow: a.$form-shadow;
  padding: 15px 58px;
  margin: 0 0.75rem;

  display: flex;
  align-items: baseline;
  .left{
    width: 100%;
    // background: green;
    padding: 0 0.5rem;
    h1{
      font-family: a.$form-font-bold;
      font-size: 48px;
      margin-bottom: 0;
      // text-align: center;
    }
    p{
      font-family: a.$form-font-medium;
      font-size: 14px;
     
    }
    &-view{
      width: 100%;
      .p-steps{
        .p-steps-item{
          &.p-highlight{
            .p-steps-number{
              color: transparent;
    
              &::before{
                content: "\f00c";
                font-family: "Font Awesome 5 Pro";
                color: a.$dark-color;
                margin-left: 0.5rem;
              }
            }
          } 
          .p-steps-title{
            font-family: a.$form-font-medium;
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
    }
    &-create{
      width: 100%;
      .p-steps{
        .p-steps-item{
          .p-steps-title{
            font-family: a.$form-font-medium;
            font-weight: 500;
            font-size: 12px;
          }
          .p-menuitem-link:not(.p-disabled):focus{
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
    &-instruction{
      width: 50%;
      h4{
        font-family: a.$form-font-semi-bold;
        font-size: 20px;
        text-decoration: underline;
        margin-bottom: 0.75rem;
      }
      .content{
        display: block;
        margin-bottom: 24px;
        // width: ;
        h6{
          font-family: a.$form-font-semi-bold;
          font-size: 14px;
        }
        .steps{
          span{
            display: block;
            line-height: 1.5rem;
            font-size: 13px;
            font-family: a.$form-font-medium;
          }
          &-info{
            padding: 0.85rem;
            border: 1px solid a.$black;
            border-radius: 4px;
            width: 95%;
            span{
              display: block;
              line-height: 1.5rem;
              font-size: 12px;
              font-family: a.$form-font-medium;
            }
            .fa-circle-info{
                position: absolute;
                left: 39px;
                top: 180px;
                /* background: white; */
            }
          }
        }
      }
    }
  }
  .right{
    width: 100%;
    padding: 0.85rem 0 0 50px;
    .p-progressbar{
      height: 1.45rem !important;
      border-radius: 14px !important;
      border: 1px solid #808080 !important;
      .p-progressbar-value{
        background: a.$primary-color !important;
      }
      .p-progressbar-label{
        display: none !important;
      }
    }
    .content{
      padding: 15px 0;
      width: 100%;
      div{
        display: flex;
        align-items: center;
        text-align: justify;
        line-height: 1.5rem;
        justify-content: space-between;
        margin-bottom: 2px;
        .fa-check{
          color: a.$green;
        }
        .fa-xmark{
          color: a.$red;
        }
        span{
          margin-left: 0.75rem;
          font-size: 13px;
          font-family: a.$form-font-medium;
          &.p-button-label{
            font-size: 12px;
            font-family: a.$form-font;
          }
        }
        .p-button{
          justify-content: end;
          span{
            margin-left: 0;
          }
        }
      }
    }

    &-instruction{
      border-left: 1px solid a.$grey;
      padding-left: 48px;
      width: 50%;
      h5{
        font-family: a.$form-font-semi-bold;
        font-size: 16px;
        // font-style: italic;
        margin-bottom: 0.75rem;
      }
      .content{
        display: block;
        margin-bottom: 24px;
        h6{
          font-family: a.$form-font-semi-bold;
          font-size: 14px;
        }
        .steps{
          span{
            display: block;
            line-height: 1.5rem;
            font-size: 13px;
            font-family: a.$form-font-medium;
            .see-example{
              color: a.$primary-color;
              text-decoration: underline;
              font-size: 12px;
              cursor: pointer;

              position: relative;          // Ensures the hidden-div is positioned relative to the parent
              display: inline-block;       // Keeps the container as an inline-block element

              .hidden-div {
                display: none;             // Initially hidden
                position: absolute;        // Positioned relative to the parent
                top: -95px;                 // Place below the parent
                left: 75px;
                background-color: a.$white; // Background for the hidden div
                color: a.$black;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid a.$lightGrey;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
                width: 330px;
                h6{
                  font-family: a.$form-font-semi-bold;
                  font-size: 12px;
                }
                .steps{
                  span{
                    display: block;
                    line-height: 1.5rem;
                    font-size: 10px;
                    font-family: a.$form-font-medium;
                  }
                }
              }
            
              &:hover .hidden-div {
                display: block;            // Show when hovered
              }
            }
          }
          .sbs{
            display: flex;
            align-items: start;
            grid-gap: 12px;
            .explain{
              display: block;
              line-height: 1.5;
            }
          }
          .sbs-or{
            display: flex;
            align-items: start;
            grid-gap: 23px;
            .explain{
              display: block;
              line-height: 1.5;
            }
          }
          .hover-container {
            position: relative;          // Ensures the hidden-div is positioned relative to the parent
            display: inline-block;       // Keeps the container as an inline-block element
            padding: 10px;
            background-color: #3498db;
            color: white;
            border-radius: 5px;
          
            
          }
        }
      }
    }
  }
  button.close{
    background: none;
    border: none;
    position: absolute;
    right: 30px;
  }

}

.box {
	height: 80px;
	width: 50px;
	border: 1px solid #12191D;
	top: 0;
	left: 20px;
	position: relative;
	margin-bottom: 12px;
	border-radius: 4px;
}

.container-box{
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .box2{
      height: 80px;
      width: 50px;
      border: 1px solid #12191D;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  .box3{
      height: 80px;
      width: 50px;
      border: 1px solid #12191D;
      border-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
}
.highlight {
  background-color: #6366F1; /* Light blue background */
  span{
    color:#fff
  }
}
