@use '../abstracts' as a;

/* reset */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: a.$form-font;
}
body{
    position: relative;
    background-color: a.$background-color;
    font-family: a.$form-font;
    height: 100vh;
    overflow-x: hidden;
    margin: 0;
}
header{
    position: sticky;
}
a{
    text-decoration: none;
    color: a.$white;
    cursor: pointer;
    &:hover{
        color: a.$primary-color;
    }
    &:focus{
        border: none;
    }
}
i{
    cursor: pointer;
}
ul, li{
    text-decoration: none !important;
    border: none;
    list-style: none
}
input[type=radio]{
    appearance: none;
}
input[type=checkbox]{
    appearance: none;
    margin: 0;
}
input::placeholder{
    color: a.$dark-font;
}
::-webkit-scrollbar{
    width: 0;
}
/* Reset Main Content */
.wrapper{
    overflow: hidden !important;
}
.wrapper .main{
    // margin-left: 172px;
    padding: 52px 0 0 0 !important;
    background: #f3f3f3;
    /* adding position and width */
    position: fixed;
    overflow: overlay;
    width: calc(100% - 172px);
    width: -webkit-fill-available; /* WebKit-based browsers */
    width: -moz-available; /* Firefox (older versions) */
    .scroll-child{
        position: absolute;
        width: -webkit-fill-available; /* WebKit-based browsers */
        width: -moz-available; /* Firefox (older versions) */
        height: -webkit-fill-available; /* WebKit-based browsers */
        height: -moz-available; /* Firefox (older versions) */
        height: calc(100% - 52px);  
        width: 100%;
        overflow: hidden;
    }
}
.wrapper .sidebar .logo-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 7px; 
    padding-bottom: 12px; 
    position: fixed; 
    background-color: #1a2428; 
    z-index: 100;
    img{
        cursor: pointer;
    }
}
.wrapper.layout-sidebar-active .logo{
    width: 60px;
}

.wrapper.layout-sidebar-active .custella-mini-icon ,.wrapper.layout-sidebar-active .custella-short-icon  , .wrapper.sidebar-active-m .custella-mini-icon{
    margin: 0 auto;
}

.wrapper.collaps-sidebar-active .logo{
    width: 172px !important;
}
.wrapper.collaps-sidebar-active .custella-mini-icon , .wrapper.collaps-sidebar-active .custella-short-icon{
    margin: 0 3px !important;
}

/* Reset Leftbar to default custella design */
.wrapper .sidebar{
    background: a.$white !important;
    width: 172px;
    border: none !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.wrapper .sidebar ul li a{
    text-decoration: none;
    color: #212121;
    padding: 4px 8px 4px 5px !important;
    &:hover{
        background: #f6f3f6;
        color: #212121 !important;
        border-radius: 4px !important;
    }
    &:focus{
        background: #f1f0f1;
        color: #212121 !important;
        border-radius: 4px !important;
    }
    &:active{
        background: #f1f0f1;
        color: #212121 !important;
        font-weight: 700;
    }
    &.active-menuitem{
        background: #f1f0f1;
        color: #212121 !important;
        border-radius: 4px !important;
        font-weight: 700;
    }
}
/* Reset Topbar to default custella design */
.wrapper .topbar{
    height: 52px;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    background: #26363e !important;
    color: a.$white !important;
    border: none !important;
}
/* Reset Focus color */
.p-focus{
    outline: 3px solid a.$input-glow !important;
    border: 1px solid a.$input-active !important;
    border-radius: 3px;
    box-shadow: 0 0 5px a.$input-glow !important;
}
/* Overlay background for popup */
.p-component-overlay{
    background-color: rgba(0, 0, 0, 0.6) !important;
    transition-duration: 0.2s;
}
/* TabView Panel */
@media (min-width: 1025px){
    .p-tabview{
        .p-tabview-nav-btn.p-link {
            display: none;
        }
    }
}
@media (max-width: 1024px){
    .p-tabview{
        .p-tabview-nav-btn.p-link {
            display: block;
        }
    }
}
.p-tabview .p-tabview-panel{
    width: 100%;
    height: 100%;
    overflow: overlay;
}
/* Setup TabView Panel */
.setup-tabView .p-tabview .p-tabview-panel{
    height: 100%;
}
.p-tabview .p-tabview-panels{
    color: a.$dark-font3 !important;
}

/*  */
.p-accordion-header-link{
    width: 100% !important;
}
/* Viewpage: profile */
.custella-vp-picture .p-avatar img{
    width: 100% !important;
    height: 100% !important;
}

/* Border Bottom in Notification menu */
.custella-profile .border-secondary{
    color:rgba(238, 238, 238, 1) !important;
    --bs-border-opacity: 0.3 !important;
}
.custella-notification .border-secondary{
    color:rgba(238, 238, 238, 1) !important;
    --bs-border-opacity: 0.3 !important;
}
.custella-setup .border-secondary{
    color:#E0E0E0 !important;
}
/* Reset Autocomplete width */
p-autocomplete,
.p-autocomplete {
  width: 100%;
}

/* Reset Dropdown height  */
.p-dropdown-items-wrapper{
    max-height: 257px !important;
    // max-width: 632px !important;
}
.p-multiselect-items-wrapper{
    max-height: 257px !important;
}

.custella-picklist-tab .p-tabview .p-tabview-nav-container{
    width: 67vw !important;
}
// .p-dropdown-panel, .p-multiselect-panel{
//     z-index: 20 !important;
// }
 /* Add width due to dropdown panel increasing with long text, unable to have ellipsis due to primeng plugin 28/May/2024*/

.p-dropdown-width-0 .p-overlay{
        z-index: 1001 !important;
        width: auto !important;
        left: 33.5px !important;
    }
    .p-overlay{
        z-index: 110 !important;
        width: 100%;
    }     

.custella-form-container{
    .p-dropdown-panel, .p-multiselect-panel{
        z-index: 98 !important;
         width: 100%; //comment out this line to remove width of dropdown panel coz it's causing the dropdown panel  width issue in create task 
         /* Add width due to dropdown panel increasing with long text, unable to have ellipsis due to primeng plugin*/
    }
    //Added for priority pdropdown
    #picklist_TaskPriorityField{
        .p-dropdown-panel, .p-multiselect-panel{
            z-index: 98 !important;
            position: fixed; 
            width: unset !important;
        }
    }
}
// body.modal-open{
//     .p-dropdown-panel, .p-multiselect-panel{
//         z-index: 98 !important;
//     }
// }
.p-dropdown-panel{
    .p-dropdown-items {
        .p-dropdown-item{
            font-size: 12px !important;    
            overflow: hidden;
            text-overflow: ellipsis;
        }
       
    } 
} 

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message{
    font-size: 12px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    font-size: 12px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    font-size: 12px !important;
}
.p-listbox-list-wrapper .p-listbox-list .p-listbox-item span{
    font-size: 12px !important;
}
.ag-rich-select-list {
    max-height: 220px !important;
    overflow: scroll;
}
.p-dropdown.h-25 .p-dropdown-trigger{
    padding-top: 2px;
}
/* Width and height of color picker */
.p-button.p-button-icon-only{
    width: 32px !important;
    height: 32px !important;
}
.move{
    .p-button.p-button-icon-only:disabled{
        color: a.$white !important;
    }
}
.multiple {
    .p-button.p-button-icon-only{
        width: 32px !important;
        height: 33.6px !important;
    }
}
.custella-color-picker-2 .p-colorpicker-preview{
    width: 3rem !important;
    height: 3rem !important;
}
.custella-color-picker .p-colorpicker-preview{
    width: 32px !important;
    height: 32px !important;
}
.custella-color-picker .p-colorpicker-overlay-panel{
    position: absolute;
    z-index: 99;
    left: -145px !important;
    top: 36px;
}
.color-scheme .p-colorpicker-preview.p-inputtext{
    border-radius: 50% !important;
    border: 3px solid #CACACA !important;
}

/* Set color table in general setup */
.custella-general-setup .p-datatable .p-datatable-thead > tr > th{
    background: #26363e !important;
    color: a.$white;
}
.custella-form-container-content .p-datatable .p-datatable-thead > tr > th{
    background: #f8f9fa !important;
    color: #495057;
}

/* Tab view */
// .p-tabview .p-tabview-nav-container{
//     margin-left: 1.5rem;
// }
.p-tabview .p-tabview-nav{
    padding-left: 0.75rem;
}
////////////// ======= Preeti chnage 23/sep/2024 =========////////////////
// .bin .p-tabview {
//     .p-tabview-nav-container{
//         /* remove padding-right to remove gap | 11/9/24 ina */
//         // padding-right: 1rem;
//         /* adding height to prevent scrollable when there is p-tabview | 11/9/24 ina*/
//         // height: calc( 100% - 95px);
//     }
//     .p-tabview-panels{
//         position: fixed;
//         height: calc(100% - 102px);
//         width: -webkit-fill-available;
//         width: -moz-available;
//         .details .p-tabview-panel{
//             overflow: overlay;
//         }
//     }
// }
///////////// ======= Preeti chnage 23/sep/2024 =========////////////////
.chubbs .p-tabview .p-tabview-nav-container{
    margin-top: -4px;
}

/* Picklist Header */
.p-picklist .p-picklist-header{
    background: a.$headerblack !important;
    color: a.$white !important;
    padding: 0.5rem 1rem !important;
}
.p-picklist .p-picklist-buttons:nth-child(1){
    display: none !important;
}
// .p-picklist .p-picklist-buttons:nth-child(5){
//     display: none !important;
// }

/* Checkbox select all */
.select-all .p-checkbox .p-checkbox-box{
    background: transparent !important;
}

/* Reset Breadcrumb scroll */
.p-breadcrumb{
    overflow-y: hidden !important;
}
.p-breadcrumb.title{
    height: 43.5px !important;
}
.p-breadcrumb.title ul li .p-menuitem-link .p-menuitem-text{
    font-size: 22px !important;
    font-family: a.$form-font-semi-bold !important;
}

.p-avatar.p-avatar-xl.teams{
    width: 13.5rem !important;
    height: 9rem !important;
}

/* Reset width for Tabs in Setup */
// .setupBody .p-tabview-nav-content{
//     width: calc(100% - 223px);
// }

/* Shifts Reset Color */
.fc-direction-ltr .fc-timegrid-col-events{
    margin: 0 2px !important;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event{
    background: transparent !important;
    border: transparent !important;
    &:hover{
        background: a.$primary-color !important;
        border: transparent !important;
    }
}

/* Map */
.gmnoprint{
    display: none !important;
}

// .p-button-label{
//     color: a.$white !important;
// }
.custella-calendar .p-datepicker-buttonbar{
    .p-button{
        background-color: transparent !important;
        color: a.$primary-color !important;
        border: none !important;
    }
    .p-button-label {
        font-family: a.$form-font-bold;
    }
}

/* Rows Selected in ag-grid */
.ag-theme-balham .ag-row-selected::before{
    background-color: transparent !important;
}

.charts ngx-charts-line-chart .ngx-charts-outer{
    height: 230px !important;
}

.ph .ag-center-cols-viewport {
    width: 100%;
    overflow-x: hidden;
    /* display: none; */
}

/* Dropdown Bootstrap */
.dropdown .dropdown-menu.packages.show{
    position: fixed !important;
}
.table > :not(caption) > * > *{
    padding: 0rem !important;
}
.ag-record-row-summary-panel.padds{
    padding-top: 2.5px !important;
    padding-right: 20px !important;
}
/* calendar for filter */
.custella-form-container-content-pane .p-calendar .p-datepicker{
    top: -50px !important;
}
/* PrimeNg Picklist */
.p-picklist{
    .p-picklist-list{
        height: 350px !important;
    }
}
.jo-type.p-picklist .p-picklist-buttons.p-picklist-transfer-buttons .p-button.p-button-icon-only:nth-child(2), .jo-type.p-picklist .p-picklist-buttons.p-picklist-transfer-buttons .p-button.p-button-icon-only:nth-child(4){
    display: none !important;
}
.p-colorpicker-overlay-panel{
    z-index: 1002 !important;
}
/* QR/BARCODE */
.barcode-ov{
    width: 250px !important;
}
.text-muted {
    color: #6c757d !important;
}
/* p-message; sentence break wrap */
.p-message{
    .p-message-summary {
        word-break: break-word !important;
        white-space: break-spaces !important;
    }
}
/* ticket conversation: a tag */
.gmail_quote{
    .gmail_attr{
        a{
        text-decoration: none;
        color: a.$primary-color !important;
        cursor: pointer;
            &:hover{
                color: a.$primary-color;
                text-decoration: underline;
            }
        }
    }
}
.conv-desc-box{
    div:dir(ltr){
      a{
        text-decoration: none;
        color: a.$primary-color !important;
        cursor: pointer;
        &:hover{
            color: a.$primary-color;
            text-decoration: underline;
        }
    }
    }
}
.angular-editor-textarea{
    a{
        text-decoration: none;
        color: a.$primary-color !important;
        cursor: pointer;
        &:hover{
            color: a.$primary-color;
            text-decoration: underline;
        }
    }
}