@use '../abstracts' as a;

.dropData {

        display: flex;
        margin: 10px 0;
        justify-content: space-between;
        height: 29px;

}

.list-item.disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
}


/* Custom Form: Form Arrangement */
.cdk-drop-list {
        .page {
                padding: 14px !important;
                margin-bottom: 12px !important;
                // border: 1px solid #CDCDCD !important;
                // border-radius: 8px !important;
        }
}