* {
	box-sizing: border-box;
}

.p-component {
    font-family: $fontFamily;
    font-size: $fontSize;
    font-weight: $fontWeight;
}

.p-component-overlay {
    background-color: $maskBg;
    transition-duration: $transitionDuration;
}

.p-disabled, .p-component:disabled {
    // opacity: $disabledOpacity;
    //background: #F5F5F5 !important; =>> Preeti comment out this line coz it is over writing colorpicker color 
    background: #F5F5F5;
    border: 1px solid #e7e7e7 !important;
}
.p-disabled {
    // opacity: $disabledOpacity;
   background: #F5F5F5 !important;
}
.dynamic{
    .p-disabled {
        // opacity: $disabledOpacity;
       background:rgba(219, 217, 225, 0.4784313725) !important;
    }
}

.disabled, .disabled:enabled:hover, .disabled:enabled:focus{
    background-color: #f1f0f1 !important;
    outline: none !important;
    border: 1px solid #ced4da !important;
}

.p-error {
    color: $errorColor;
}

.p-text-secondary {
    color: $textSecondaryColor;
}

.pi {
    font-size: $primeIconFontSize;
}

.pi-info-circle {
    margin-left: 4px;
    font-size: 11px;
}

.p-link {
    font-size: $fontSize;
    font-family: $fontFamily;
    border-radius: $borderRadius;

    &:focus {
        @include focused();
    }
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
}

.p-component-overlay {
    @keyframes p-component-overlay-enter-animation {
        from {
            background-color: transparent;
        }
        to {
            background-color: var(--maskbg);
        }
    }

    @keyframes p-component-overlay-leave-animation {
        from {
            background-color: var(--maskbg);
        }
        to {
            background-color: transparent;
        }
    }
}

.table-space
{
    box-shadow: 0px 4px 15px -12px rgba(117, 115, 115, 0.8)
}

.main-list-header
{
    color: #212121;
    font-family: "Inter-SemiBold";
    font-weight: 600 !important;
    font-size: 2rem;
    margin-bottom: 0px;
    position: relative;
    line-height: 1;
    padding: 4px 0 4px 4px;
}

.button-box-top-right , .button-box-top-right-delete
{
    position: fixed;
    z-index: 99;
    top: 58px;
    right: 12px;
}
.button-box-top-right-delete-two
{
    position: absolute;
    z-index: 99;
    top: 8px;
    right: 21px;
}
.button-box-top-right-two
{
    position: absolute;
    z-index: 99;
    top: 8px;
    right: 14px;
}

.dialog-close-icon{
    position: absolute;
    z-index: 105;
    right: 9px;
    top: 14px;
    color: #fff;
}

.unlock-Spinner .ngx-spinner-overlay{
    top: 52px !important;
    left: 171px !important;
}
.lock-Spinner .ngx-spinner-overlay{
    top: 52px !important;
    left: 61px !important;
}
// Path: src/assets/sass/theme/designer/_common.scss make spinner center 27/may/2024
.unlock-Spinner .ngx-spinner-overlay > div:not(.loading-text) {  
    left: 40% !important;
}