@use '../abstracts' as a;

.layout-config {
    width: 36rem;
    height: 99%;
    position: fixed;
    right: 0;
    top: 4px;
    /* padding: 1rem; */
    overflow: unset;
    background: #ffffff;
    z-index: 999;
    border-left: 0 none;
    transform: translateX(100%);
    transition: transform .4s cubic-bezier(.05, .74, .2, .99), width 0.3s ease;
    border-radius: 16px;

    &.layout-config-active {
        transform: translateX(-4px);
        // box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);

        .layout-config-content .layout-config-button i {
            transform: rotate(360deg);
        }
    }

    /* Expanded layout-config width */
    &.expanded {
        width: 48rem;
        /* Expanded width, adjust as desired */
        transition: transform .4s cubic-bezier(.05, .74, .2, .99), width 0.3s ease;
    }

    .layout-config-content-wrapper {
        position: relative;
        height: 100%;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24);
        color: var(--text-color);
        background-color: var(--surface-f);
        border-radius: 16px;

        .layout-config-button {
            display: block;
            position: absolute;
            width: 52px;
            height: 52px;
            line-height: 52px;
            background-color: #1A2428;
            text-align: center;
            color: var(--primary-color-text);
            top: 40%;
            left: -51px;
            z-index: -1;
            overflow: hidden;
            cursor: pointer;
            outline: 0 none;
            border-radius: 3px;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);

            i {
                font-size: 26px;
                line-height: inherit;
                cursor: pointer;
                transform: rotate(360deg);
                transition: transform 1s;
            }
        }
    }

    .layout-config-header {
        display: flex;
        align-items: center; /* for Aligning all items to the top */
        justify-content: space-between;
        padding: 0.5rem 1rem;
        background-color: #ffffff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    
        /* Dolphin Icon Container */
        > div:first-child {
            display: flex;
            align-items: flex-start; /* Align dolphin to the top */
            justify-content: center;
            position: relative; /* to allow for fine-tuned adjustments */
            img{
                height: 50px;
            }
        }
    
        /* Dolphin Icon Styling */
        > div:first-child i {
            font-size: 60px; 
            transform: translateY(-2px); /* for fine-tune vertical alignment */
            line-height: 1; 
        }
    
        /* Header Icons Container */
        .header-icons {
            display: flex;
            align-items: center; 
            gap: 0.5rem;
    
            a {
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                cursor: pointer;
                transition: background-color 0.2s, box-shadow 0.2s, transform 0.2s;
    
                i {
                    color: #333;
                    font-size: 14px;
                }
    
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    a {
        color: var(--link-color);
        font-weight: 600;

        &:hover {
            text-decoration: none;
        }
    }

    .layout-config-content {
        overflow: scroll;
        // height: calc(100% - 162px);
        height: calc(100% - 42px);
        padding: 0 1.5rem 0 1.5rem;

        .chatbox {
            // height: 275px;
            // overflow-y: scroll;
            // padding: 0px 0 50px 0px;

            table {
                width: 100%;
                border-collapse: collapse;
                margin: 10px 0;

                thead,
                tbody,
                tfoot,
                tr,
                td,
                th {
                    border-color: inherit;
                    border-style: solid;
                    border-width: 0;
                }

                tbody>tr>td {
                    text-align: left;
                    border: 1px solid #e9ecef;
                    border-width: 0 0 1px 0;
                    padding: 0.5rem 1rem;
                }

                tbody tr {
                    background: #ffffff;
                    color: #495057;
                    transition: box-shadow 0.2s;
                    outline-color: #c1c2f9;
                }

                th {
                    text-align: left;
                    padding: 0.5rem 1rem;
                    border: 1px solid #e9ecef;
                    border-width: 0 0 1px 0;
                    font-weight: 600;
                    color: #495057;
                    background: #f8f9fa;
                    transition: box-shadow 0.2s;
                    font-size: 12px;
                }
            }

            .chat {
                display: flex;

                .chat-message {
                    font-family: "Inter-Regular";
                    background: #6366f1;
                    color: #fff;
                    font-size: 12px;
                    border-radius: 10px 10px 0 10px;
                    padding: 14px;
                    // max-width: 78%; right side space
                    max-width: 100%;

                    p {
                        font-family: "Inter-Regular";
                        color: #212121;
                    }

                    b,
                    strong {
                        font-weight: bold !important;
                        color: #212121;
                        font-family: "Inter-SemiBold";
                        font-weight: 600 !important;
                    }
                }

                &.incoming {
                    margin-bottom: 5px;

                    .chat-message {
                        background: a.$white;
                        border: 1px solid #DADADA;
                        color: #12191d;
                        border-radius: 10px 10px 10px 0;
                    }

                    i {
                        height: 25px;
                        width: 25px;
                        color: #39a8c9;
                        background: #12191d;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 29px;
                        margin: 0px 10px 7px 0px;
                        align-self: flex-end;
                    }
                }

                &.outgoing {
                    justify-content: flex-end;
                    margin: 15px 0;

                    .chat-message {
                        background: #f2f2f2;
                        color: a.$black;
                        border: 1px solid #e6e6e6;
                        border-radius: 10px 10px 0 10px;
                    }

                    i {
                        height: 25px;
                        width: 25px;
                        color: #39a8c9;
                        background: #12191d;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 29px;
                        margin: 0px 10px 7px 0px;
                        align-self: flex-end;
                    }
                }
            }
        }

        .chip-container {
            position: fixed;
            bottom: 65px; // Adjust to place above .chat-input
            // width: calc(100% - 43px); // Match the width of .chat-input
            display: flex;
            gap: 10px;
            padding: 5px 0;
            background-color: a.$white; // Optional: match background to avoid overlapping content

            .clickable-chip {
                .p-chip {
                    background-color: transparent;
                    border: 1px solid #000;
                    border-radius: 20px;
                    padding: 0px 10px;
                    cursor: pointer;
                    font-family: "Inter-Regular";
                    font-size: 10px;
                    color: #000;

                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }
        }

        
        .chat-input {
            position: fixed;
            bottom: 10px;
            border: 1px solid #ccc;
            border-radius: 12px;
            width: calc(100% - 38px);
            background: a.$white;
            padding: 6px;
        
            display: flex;
            gap: 8px;
        
            textarea {
                border: none;
                outline: none;
                font-size: 12px;
                resize: none;
                height: 40px;
                padding: 6px 12px;
                border-radius: 6px;
        
                &.chat-textarea {
                    min-height: unset;
                    height: 50px;
                }
            }
        
            .p-inputtextarea {
                border: none !important;
                outline: none !important;
            }
        
            span {
                align-self: center;
                height: auto;
                line-height: normal;
                cursor: pointer;
                color: a.$grey6;
                font-size: 14px;
                padding: 6px 12px;
                border-radius: 6px;
        
                &:hover {
                    color: a.$primary-color;
                    background-color: rgba(0, 0, 0, 0.05);
                }
            }
        }
    }

    

    .config-scale {
        display: flex;
        align-items: center;
        margin: 1rem 0 2rem 0;

        .p-button {
            margin-right: .5rem;
        }

        i {
            margin-right: .5rem;
            font-size: .75rem;
            color: var(--text-color-secondary);

            &.scale-active {
                font-size: 1.25rem;
                color: var(--link-color);
            }
        }
    }

    .p-grid>div {
        padding: 1rem;
        text-align: center;

        span {
            display: block;
        }

        button {
            position: relative;
            display: inline-flex;
            justify-content: center;
        }
    }

    .free-themes {
        img {
            width: 50px;
            border-radius: 4px;
            transition: transform .2s;

            &:hover {
                transform: scale(1.1);
            }
        }

        span {
            font-size: .875rem;
            margin-top: .25rem;
        }
    }

    .premium-themes {
        img {
            width: 100%;
            transition: transform .2s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.completion-summary-banner {
    background-color: #f8f9fa; // Light background
    border-radius: 16px;
    padding: 16px 30px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    margin: 12px;
    border: 1px solid #CFCFCF;
    opacity: 1; // Default opacity
    transform: translateY(0); // Default position
    transition: opacity 1s ease-out, transform 4s ease-out; // Transition effect
    &.hidden {
        opacity: 0; // Fade out
        transform: translateY(-10px); // Move up slightly
        pointer-events: none; // Prevent interactions when hidden
      }

    .banner-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            font-size: 1.2rem;
            font-family: a.$form-font-semi-bold;
            margin: 0;
            color: #333;
        }

        .suggested-by {
            font-size: 12px;
            color: a.$black;
            font-style: italic;
            display: flex;
            align-items: center;
            gap: 4px;

            i {
                font-size: 0.9rem;
                color: #333; // Icon color
            }
        }

        .close-icon {
            font-size: 1.2rem;
            color: #6c757d;
            cursor: pointer;

            &:hover {
                color: #333;
            }
        }
    }

    .content-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 20px; // Space between summary-list and completed-by
        margin-top: 10px;
    }

    .summary-list {
        margin: 10px 0;
        padding: 0;
        list-style: inside;

        li {
            font-size: 0.95rem;
            color: #333;
            margin-bottom: 3px;

            strong {
                font-weight: bold;
            }
        }
    }

    .completed-by {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #333;
        gap: 8px;

        span {
            color: #333;
            font-size: 0.95rem;
            font-weight: 500;

            strong {
                font-weight: bold;
            }
        }

        .profile-images {
            display: flex;
            gap: -10px; // Overlapping effect

            img {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 2px solid a.$primary-color;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                margin-left: -10px; // Overlapping effect

                &:first-child {
                    margin-left: 0; // No margin for the first image
                }
            }
        }
    }
}


/* Dropdown time estimation by AI */
.custom-pane {
    position: absolute; // Positioned relative to the dropdown
    top: 100%; // Adjust as needed to align with the bottom of the dropdown
    left: 0; // Align to the left of the dropdown
    z-index: 1000; // Ensure it's above other elements
    background-color: #f3f4fe; // Light lavender-like background
    border: 1px solid #d1d5db; // Soft border color
    border-radius: 0 0 8px 8px; // Rounded corners
    padding: 6px 10px; // Add space inside the box
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow for a floating effect
    width: max-content; // Adjust to fit the content, or set a fixed width if needed
  
    // Text style
    p {
      font-size: 10px; // Smaller text
      font-family: a.$form-font;
      font-style: italic; 
      color: #6b7280; // Gray text color
      margin-bottom: 10px; // Space below the paragraph
    }
  
    // Button style
    .set-time-button {
        background-color: transparent;
        border: 1px solid #000;
        border-radius: 20px;
        padding: 0px 10px;
        cursor: pointer;
        font-family: "Inter-Regular";
        font-size: 10px;
        color: #000;
        transition: background-color 0.3s;
  
      &:hover {
        background-color: #c7d2fe; // Darker blue on hover
      }
    }
  
    // Close icon style
    .close-icon {
        position: absolute;
        bottom: 4px; // Adjust as needed
        right: 12px; // Adjust as needed
        width: 20px; // Set width to create a circular shape
        height: 20px; // Match height to width for perfect circle
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px; // Icon size
        color: #6b7280; // Gray color for the icon
        background-color: #fff; // White background for contrast
        border: 1px solid #d1d5db; // Light gray border
        border-radius: 50%; // Make the icon circular
        cursor: pointer;
        transition: all 0.3s ease; // Smooth transitions for hover effects

        &:hover {
            color: #374151; // Darker gray on hover
            background-color: #f3f4f6; // Light gray background on hover
            border-color: #374151; // Darker border color on hover
        }
    }
  }

//   loading bubble for chatbot

.chatbot-container {
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: flex-start;
    height: 98%;
    width: 100%;
    padding: 10px;
    position: relative;
    overflow-y: auto; 
}

.loading-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
    width: 100%; 
    position: absolute; 
    top: 0;
    left: 0;
}

/* Chatbox */
.chatbox {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%; 
    overflow-y: auto; 
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 

    /* Chat Messages */
    li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
        width: 100%;
    }
    .chat-message {
        background-color: #f5f5f5;
        color: #333;
        padding: 5px;
        border-radius: 10px;
        // word-wrap: break-word; 
        width: auto;
        max-width: 80%; 
    } 
    .chat.incoming{
        .chat-message {
            background-color: #e0f7fa;
        }
    }
}
/* Loading Animation */
.bubble-dot {
    width: 8px;
    height: 8px;
    margin: 0 3px;
    background-color: #c5d0dc;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
}

.bubble-dot:nth-child(2) {
    animation-delay: 0.3s;
}

.bubble-dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}


// error for checklist
// .custella-content-row {
//     padding-bottom: 4px; 
// }

// .custella-content-row-input {
//     margin-bottom: 16px;
// }

// .custella-content-row-input-title {
//     font-weight: bold;
//     margin-bottom: 8px;
// }

// .chip-container {
//     margin-top: 8px;
//     display: flex;
//     gap: 8px; 
// }

// ai job order details summary
.completion-summary-banner {
    position: relative;
    max-height: 1000px;
    opacity: 1;
    transition: max-height 0.4s ease-out, opacity 0.4s ease-out;
    overflow: hidden;
  }
  
  .completion-summary-banner.hidden {
    max-height: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
  }
  
  @keyframes flicker {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  
  .banner-spinner-flicker {
    animation: flicker 1.5s infinite;
  }
  
  .completion-summary-banner.flickering {
    animation: flicker 1.5s infinite;
  }
  
//   ai checklist error
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

textarea.is-invalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

// invalid input
.error-message {
    color: #dc3545; 
    font-size: 0.875rem; 
    margin-top: 0.25rem; 
    animation: fadeIn 0.3s ease-in-out; 
}

/* Fade-In Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

// uploaded file
.uploaded-file-container {
    .btn-link {
      text-decoration: none;
      
      &:hover {
        opacity: 0.8;
      }
    }
    
    img {
      max-height: 200px;
      object-fit: contain;
    }
  }
  
/* PLACEHOLDER FOR AI SUGGESTIONS */
.auto-suggest{
    background: #6366f145;
    height: 104px;
    border-radius: 4px;
    border: 1px dashed #6366F1A3;
   
    top: 24px;
    width: 100%;
    overflow: overlay;
    .content{
        padding: 0.54rem 0.5rem;
        .list{
            .label{
                font-size: a.$normal-fontsize;
                font-family: a.$form-font;
                color: #818181;
            }
            ol{
                padding: 0;
            }
        }
    }
}

/* Smart Suggest in JO-Spare Parts */
.smart-suggest{
    display: grid;
    row-gap: 4px;
    background-color: #6366f11a;
    border-radius: 4px;
    border: 1px dashed #6366F1A3;
    transition: background-color 0.7s ease-in;
    padding: 0.5rem 0.75rem;
    .table-suggestion{
        .p-datatable-table{
            opacity: 0.8;
        }
    }
    &:hover{
        background-color: #eff0fe;
        .table-suggestion{
            .p-datatable-table{
                opacity: 1;
            }
        }       
    }
}

.smart-suggest-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    padding: 0.65rem 0.95rem ;
    width: 100%;
    cursor: pointer;
    &.added{
        background:#f2f2f2;
    }
}