@use '../abstracts' as a;

/* Calendar
=============================================================*/
.p-calendar{
    width: 100% !important;
}
.p-calendar .p-datepicker{
    min-width: unset !important;
}
/* 7/10/2024; INA ADD BECAUSE OF THE P-DATEPICKER GO BEHIND CLONE TASK MODAL */
.modal-open{
    .p-datepicker:not(.p-datepicker-inline){
        z-index: 1002 !important;
        min-width: unset !important;
    }
}
#timeonly{
    width: 100% !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select option{
    &:hover{
        @include a.temp-hover;
    }
}

.fc .fc-timegrid-slot{
    height: 2rem !important;
}
.loc-calendar .p-datepicker table thead tr{
    position: relative;
    left: 50px;
}