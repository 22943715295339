@use '../abstracts' as a;

/* Input Text Template
=========================================================*/
.custella-container {
    display: block;
    position: relative;
    margin: 3rem 2rem 0 2rem;
    padding: 1rem;
    background: a.$background-color;
    font-family: a.$form-font;
    font-size: a.$xl-fontsize;
    width: 345px;
}

/* Input Text Template; Text Inputs Normal*/
/* PrimeNg Custom */
.lookup input.p-inputtext {
    margin: 0 !important;
    font-family: a.$form-font !important;
    font-weight: text-weight(regular) !important;
    font-size: a.$normal-fontsize;
    width: 100%;
    &:hover {
        @include a.temp-hover;
    }

    &:focus {
        @include a.temp-focus;
    }

    &:disabled {
        pointer-events: none;
        background-color: a.$lightGrey !important;
    }
}
input.p-inputtext {
    margin: 0 !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    width: 100%;
    height: 32px !important;
    &:hover:not([disabled],[readonly]) {
        @include a.temp-hover;
    }

    &:focus {
        @include a.temp-focus;
    }

    &:disabled::placeholder{
        pointer-events: none;
        // background-color: a.$lightGrey !important; =>> Preeti comment out this line coz it is over writing colorpicker color 
        background-color: a.$lightGrey;
        color: transparent !important;
    }
}

/* Display Text in Disabled Placeholder */
.jo-type{
    .p-disabled, .p-disabled{
        color: a.$black !important; //For Dynamic Columns
    }
}
/* Hide Text in Disabled Placeholder */
// .p-inputtext {
//     .p-disabled, .p-disabled{
//         color: transparent !important; //For general forms
//     }
// }
.plc-remove{
    &.p-disabled *{
        color: transparent !important;
    } 
    input:disabled{
        color: transparent !important;
    }
    
}

.p-inputtext {
    &.ng-invalid.ng-touched {
        border-color: a.$border-color-error !important;
        background: a.$border-color-error 0.5;
        color: a.$border-color-error !important;
    }
}
p-autocomplete.ng-invalid.ng-touched > span.p-autocomplete >.p-inputtext{
    border-color: a.$border-color-error !important;
    background: a.$border-color-error 0.5;
    color: a.$border-color-error !important;
  }
p-calendar.ng-invalid.ng-touched > span.p-calendar >.p-inputtext{
    border-color: a.$border-color-error !important;
    background: a.$border-color-error 0.5;
    color: a.$border-color-error !important;
  }


/* TextArea */
.p-inputtextarea{
    width: 100%;
    height: 104px;
    min-height: 104px;
    &:hover {
        @include a.temp-hover;
    }

    &:focus {
        @include a.temp-focus;
    }

    &:disabled {
        pointer-events: none;
    }
}
/* Multipicklist Text Area */
.p-chips .p-chips-multiple-container:not(.p-disabled):hover{
    @include a.temp-hover;
}
.custella-google-text{
    position: absolute !important;
    z-index: 9;
    margin-top: 1rem;
    left: 3rem;
}

/* Input Texts in p-calendar */
p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    border-color: #ced4da !important;
  }

/* Input Text Autocomplete */
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input{
    font-size: a.$normal-fontsize !important;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item span{
    font-size: a.$normal-fontsize !important;
}

/* InputGroup */
// LIST SEARCH
.p-inputgroup {
    width: unset !important;
}
.p-inputgroup.search{
    .p-inputtext{
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}
.p-inputgroup.sorted{
    .dropdown{
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
    }
}
.p-inputgroup-addon {
    padding: 0.2rem 0.5rem !important;
}

/* Listbox */
.p-listbox .p-listbox-list .p-listbox-item{
    font-size: 12px !important;
    font-family: a.$form-font !important;
}

