@use '../abstracts' as a;

/* Option Dropdown */
.options .dropdown-menu.show {
    position: absolute !important;
}
.options2 .p-slidemenu.p-slidemenu-overlay{
    top: 86px !important;
}
.dynamicEdit{
    .options2{
        .p-slidemenu.p-slidemenu-overlay{
            top: 31px !important;
            right: 0 !important;
        }
    }
}
.p-slidemenu {
   padding: 0.1rem 0 !important;
   width: 138px !important;
}

.del-drop.dropdown-item{
    &:hover,
    &:focus {
        background-color: #D32F2F;
        color: a.$white;
        border-radius: 4px !important;
    }
    &.active,
    &:active {
        background-color: #D32F2F;
        color: a.$white;
        border-radius: 4px !important;
    }
}
.dropdown-item{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &:hover,
    &:focus {
        border-radius: 4px !important;
    }
    &.active,
    &:active {
        border-radius: 4px !important;
        background-color: a.$primary-color !important;
    }
}

/* p-slidemenu overflow */
.p-slidemenu{
    .p-menuitem-link:not(.p-disabled):hover{
        width: 138px;
    }
} 
/* Dropdown */
.p-dropdown {
    width: 100%;
    margin: 0rem 0 0 0 !important;
    height: 32px;
    align-items: center;
}


  p-dropdown.ng-invalid.ng-touched > div {
    border-color: a.$border-color-error !important;
    background: a.$border-color-error 0.5;
    color: a.$border-color-error !important;
  }

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    @include a.temp-active;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
}

/* Dropdown Clearables */
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
}

/* Dropdown Multipicklist */
.p-multiselect {
    width: 100%;
    height: 32px;
    // margin: 0.15rem 0 0 0 !important;
    align-items: center;

    &:hover {
        @include a.temp-hover;
    }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background: a.$lightPurple !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none !important;
}

.p-multiselect .p-multiselect-label {
    font-size: a.$normal-fontsize !important;
}

.p-multiselect .p-multiselect-label.p-placeholder {
    font-size: a.$normal-fontsize !important;
}

/* custom right click context menu */
.context-menu {
    display: block;
    position: absolute;
    z-index: 10000000000;
    list-style: none;
    margin: 0;
    cursor: pointer;
    background: #fff;
    padding: 1px;
    -webkit-box-shadow: 0px 1px 0px 1px #eeeeee;
    box-shadow: 0px 1px 0px 1px #eeeeee;
    border-radius: 4px;
}

/* list of menu item */
li.context-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: a.$normal-fontsize;
    font-family: a.$form-font-medium;
    color: a.$grey2;
    padding: 5px 10px;
    background: #fff;
    margin: 5px;

    /* on hover state context menu */
    &:hover {
        border: 1px solid a.$primary-color;
        border-radius: 4px;
        padding: 4px 9px;
        color: a.$black;

        i {
            color: a.$primary-color;
        }
    }
}

/* icon of menu item */
li.context-menu-item i {
    margin: 2px 5px 0px -5px;

    &:hover {
        color: a.$primary-color;
    }
}

/* Dropdown Filter; Planning Hub */
.filter-drop {
    background: a.$white !important;
    color: a.$black !important;
    border-radius: 0.25rem;
    width: 20rem !important;
}

/* p-calendar dashboard */
.cal-dash {
    .p-calendar {
        .p-datepicker{
            left: 0 !important;
            top: 32px !important;
        }
    }

}

/* Accordion */
.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link{
    p-header span{
        white-space: nowrap !important;
    }
}
/* CUSTOM LISTBOX */
.custom-listbox{
    background: #ffffff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 4px;
    .wrapper-listbox{
        overflow: auto;
        // max-height: 170px;
        .list{
            padding: 0.5rem 0;
            margin-bottom: 0;
            .list-item {
                cursor: pointer;
                position: relative;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: space-between;
                -webkit-user-select: none;
                user-select: none;
                margin: 0;
                padding: 0.5rem 1rem;
                border: 0 none;
                color: #495057;
                transition: box-shadow 0.2s;
                border-radius: 0;
                font-size: 12px !important;
                font-family: "Inter-Regular" !important;
                &:hover{
                    color: #495057;
                    background: #e9ecef;
                }
                &.highlight{
                    background: a.$primary-color;
                    color: a.$white;
                }
            }
        }
    }
}
.p-dropdown.p-component.p-disabled{
    .p-dropdown-trigger{
        display: none;
    }
}
.p-multiselect.p-component.p-disabled{
    .p-multiselect-trigger{
        display: none;

    }
}
/* Tiered Menu in Split Button */
.split{
    .p-tieredmenu {
        &.p-tieredmenu-overlay {
            left: -68px !important;
        }
        .p-menuitem {
            .p-menuitem-text {
                font-size: 12px !important;
                font-family: a.$form-font !important;
            }
        }
    }
}