@use '../abstracts' as a;
/* Badge reset to Custella Design */
.header .p-badge{
    background: a.$primary-color !important;
    min-width: 0.75rem !important;
    height: 0.75rem !important;
    line-height: 0.75rem !important;
    font-size: 8px !important;
}
.message-index .p-badge{
    background: a.$red6 !important;
    min-width: 20px !important;
    height: 20px !important;
    // line-height: 0 !important;
    font-size: 10px !important;
}
.p-overlay-badge .p-badge{
    top: 3px !important;
    right: 13px !important;
}

/* DRAG AND DROP PLANNING HUB */
:host {

    display: block;
    box-sizing: border-box;
  }
  
  pre {
  
    text-align: center;
    padding: 8px;
  }
  
  .mat-list-item {
  
    margin: 2px 0;
    border: 1px solid gray;
  }
  
  .dndList {
  
    transition: all 300ms ease;
  
    padding: 8px;
  
    overflow-y: auto;
  
    &.dndDragover {
      // background: blue;
      // height: 62px;
      // border-radius: 0.325rem;
    }
  }
  
  
  
  .dndDraggingSource {
  
    display: none;
  }
  .dndPlaceholder{
    background: #dbdbdb;
    border: 1px solid #c2c2c2;
    height: 62px;
    border-radius: 0.325rem;
    margin-top: 0.25rem;
  }
  
  .dndPlaceholderLeft {
    background-color: transparent;
  }
  .dndPlaceholderRight {
    min-height: 36px;
    border: 1px dashed green;
    background-color: a.$grey3;
    margin-bottom: 8px;
  }
  
  pre {
  
    white-space: pre-wrap;
    word-wrap: break-word;
  }

.custella-setup-body {
  .chip-container {
    display: flex;
    gap: 6px;
    .display-chip {
      .p-chip {
        background-color: a.$primary-color;
        border: 1px solid a.$primary-color;
        border-radius: 20px;
        padding: 0px 10px;
        cursor: pointer;
        font-family: "Inter-Regular";
        font-size: 12px;
        color: a.$white;
      }
    }
    .disabled-chip {
      .p-chip {
        background-color: transparent;
        border: 1px solid #000;
        border-radius: 20px;
        padding: 0px 10px;
        cursor: pointer;
        font-family: "Inter-Regular";
        font-size: 12px;
        color: #000;
      }
    }
  }
}

  .chip-container {
    display: flex;
    gap: 4px;
    .chip-smart{
      &-add{
        .p-chip {
          background: a.$white;
          font-family: a.$form-font;
          font-size: 11px;
          color: a.$primary-color;
          height: 19px;
          cursor: pointer;
          border: 1px solid a.$primary-color;
          &:hover {
            background: #6366f117 !important;
            color: a.$primary-color !important;
          }
        }
      }
      &-discard{
        .p-chip {
          background: a.$white;
          font-family: a.$form-font;
          font-size: 11px;
          color: a.$black;
          height: 19px;
          cursor: pointer;
          border: 1px solid a.$black;
          &:hover {
            background: #f0f0f0 !important;
            color: a.$black !important;
          }
        }
      }
      &-dark {
        .p-chip {
          background: a.$dark-color;
          font-family: a.$form-font;
          font-size: 11px;
          color: a.$white;
          height: 19px;
          cursor: pointer;
          &:hover {
            background: #182124 !important;
            color: #fff !important;
          }
        }
      }

    }
}
