
/* Bootstrap */
@use './assets/scss/vendors/bootstrap';

/* Base */
@use './assets/scss/base';

/* Components */
@use './assets/scss/components';

/* Layout */
@use './assets/scss/layout';
/* Layout */
@use './assets/theme/theme-blue.scss';
@import "~@bryntum/scheduler/scheduler.stockholm.css";
/**
 * Styling of app component
 */
 @import "@bryntum/scheduler/scheduler.material.css";

 /* Flag Icons */
//  @import "/node_modules/flag-icons/css/flag-icons.min.css";
