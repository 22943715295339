.tab-end {
    .p-tabview {
        .p-tabview-nav-container {
            position: relative !important;
            z-index: 3 !important;
            width: calc(100% - 1px) !important;
        }
    }
}
.ast-tab-end {
    .p-tabview {
        .p-tabview-nav-container {
            position: relative !important;
            z-index: 3 !important;
            width: calc(100% - 1px) !important;
        }
    }
}
.wrapper .main .tab-start, .wrapper.layout-sidebar-active .main .tab-start {
    .p-tabview {
        .p-tabview-nav-container {
            position: relative !important;
            z-index: 3 !important;
            width: 100% !important;
        }
          .p-tabview-panels {
            background: #f3f3f3;
            padding: 0 !important; //ina fix tabs
            border: $tabviewContentBorder;
            color: $tabviewContentTextColor;
            border-bottom-right-radius: $borderRadius;
            border-bottom-left-radius: $borderRadius;
        }
    }
}
.in-modal{
    .p-tabview {
        .p-tabview-nav-container {
            position: absolute !important;
            width: 96% !important;
        }
    }
}
.p-tabview {
    height:100%;
    // border: solid red 2px;
    .p-tabview-nav {
        background: $tabviewNavBg;
        border: $tabviewNavBorder;
        border-width: $tabviewNavBorderWidth;

        li {
            margin-right: $tabviewHeaderSpacing;

            .p-tabview-nav-link {
                border: $tabviewHeaderBorder;
                border-width: $tabviewHeaderBorderWidth;
                border-color: $tabviewHeaderBorderColor;
                background: $tabviewHeaderBg;
                color: $tabviewHeaderTextColor;
                padding: $tabviewHeaderPadding;
                font-weight: $tabviewHeaderFontWeight;
                border-top-right-radius: $borderRadius;
                border-top-left-radius: $borderRadius;
                transition: $listItemTransition;
                margin: $tabviewHeaderMargin;

                &:not(.p-disabled):focus {
                    // @include focused-inset();
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                .p-tabview-nav-link {
                    background: $tabviewHeaderHoverBg;
                    border-color: $tabviewHeaderHoverBorderColor;
                    color: $tabviewHeaderTextHoverColor;
                }
            }

            &.p-highlight {
                .p-tabview-nav-link {
                    background: $tabviewHeaderActiveBg;
                    border-color: $tabviewHeaderActiveBorderColor;
                    color: $tabviewHeaderTextActiveColor;
                    font-weight:600;
                }
            }
        }

    }

    .p-tabview-nav-container
    {
        width: calc(100% - 62px) !important; //Nathan - Need to check once sidebar collapse is working
        position: fixed;
        z-index: 9;
        // border-top: 4px solid #f3f3f3;
    }

    .p-tabview-left-icon {
        margin-right: $inlineSpacing;
    }

    .p-tabview-right-icon {
        margin-left: $inlineSpacing;
    }

    .p-tabview-close {
        margin-left: $inlineSpacing;
    }

    .p-tabview-nav-btn.p-link {
        background: $tabviewHeaderActiveBg; //6/2/24 button transparent
        // background: transparent; //6/2/24 button transparent
        color: $tabviewHeaderTextActiveColor;
        width: $buttonIconOnlyWidth;
        box-shadow: none;
        border-radius: 0;

        &:focus {
            // @include focused-inset();
        }
    }

    .p-tabview-panels {
        background: #f3f3f3;
        padding: 3.35rem 0 0 0 !important; //ina fix tabs
        border: $tabviewContentBorder;
        color: $tabviewContentTextColor;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }

}
.product-trans {

    .p-tabview {
        height: unset;
    }
}

.tabs-low-list { 
  .p-tabview{
    .p-tabview-nav-container{
        position: unset !important;
        width: 100% !important;
      }
      .p-tabview-panels{
        padding: 5px 0 0 0 !important;
      }
  }
}
.tabs-low { 
    .p-tabview.p-component{
    margin-top: 48px !important;
  }
}
.tabs-low-user { 
    .p-tabview.p-component{
    margin-top: 42px !important;
  }
}
.tabs-low-group { 
.p-tabview.p-component{
    padding-top: 42px !important;
  }
}
