@use './variables' as a;

/* Mixin 
================================================================*/
@mixin font-normal{
    font-family: a.$form-font;
    font-size: a.$normal-fontsize;
}
/* Fields */
@mixin temp-hover{
    outline:none !important;
    border: 1px solid a.$input-active !important;
    border-radius: 3px;
}
@mixin temp-focus{
    outline: 1px solid a.$input-glow !important;
    border: 1px solid a.$input-active !important;
    border-radius: 3px;
    box-shadow: 0 0 5px a.$input-glow !important;
}

@mixin temp-active{
    background-color: a.$primary-color !important;
    color: a.$white !important;
}
/* Buttons */
@mixin btn-hover{
  background: #4447ee !important;
  border: 1px solid #4447ee!important;
  color: #fff !important;
}
@mixin btn-hover-long{
  background: #3235e5 !important;
  border: 1px solid #3235e5 !important;
}
@mixin btn-dark-hover{
  background: #182124 !important;
  border: 1px solid #182124!important;
  color: #fff !important;
}
@mixin btn-grey-hover{
  background: #ffffff57 !important;
  color: a.$primary-color !important;
}
@mixin btn-view-hover{
  background: #e6e6e6 !important;
  color: a.$primary-color !important;
  transition: 0.5s ease-in-out;
}

@mixin btn-view-hover-form{
  background: #e6e6e6 !important;
  color: #b0b0b0 !important;
  transition: 0.5s ease-in-out;
}

/* Buttons */
@mixin btn-hover{
  background: #4447ee !important;
  border: 1px solid #4447ee!important;
  color: #fff !important;
}
@mixin btn-grey-hover{
  background: #ffffff57 !important;
  color: a.$primary-color !important;
}
@mixin btn-view-hover{
  background: #e6e6e6 !important;
  color: a.$primary-color !important;
  transition: 0.5s ease-in-out;
}
@mixin btn-view-hover-form{
  background: #e6e6e6 !important;
  color: #b0b0b0 !important;
  transition: 0.5s ease-in-out;
}
@mixin btn-rmv-hover{
  background: #c02929 !important;
  color: #ffffff !important;
  border-color: #ced4da !important;
}
/* Functions
============================================================ */

@function text-weight($weight-name){
    @return map-get(a.$font-weights, $weight-name)
};

/* Font Size
*********************************************************************************************/
@mixin font-size-for-all-screens($xsmall: 10px, $small: 11px, $medium: 12px, $large: 14px) {
  @media #{a.$large-and-up} {
    font-size: $large;
  }

  @media #{a.$medium-only} {
    font-size: $medium;
  }

  @media #{a.$small-only} {
    font-size: $small;
  }

  @media #{a.$xsmall-and-down} {
    font-size: $xsmall;
  }
}
