@use '../abstracts' as a;

/* Dialog; Dark Header */
/* For Outside Setup */
.custella-form-container-footer{
  .p-dialog{
    &-header{
      background: a.$headerblack  !important;
      color: a.$white  !important;
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
      justify-content: flex-start;
      padding: 0.65rem 1.5rem !important;
      .p-dialog-title{
        font-family: a.$form-font  !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }
    &-content {
      padding-top: 1rem !important;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      overflow-y: unset !important;
      overflow-x: hidden !important;
      .title{
        font-size: 14px;
        font-weight: a.text-weight(light);
        color: a.$lightGrey5;
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }
    &-footer {
      border-top: 1px solid a.$border-top-grey !important;
      padding: 0.95rem 1.75rem !important;
    }
  }
}
/* For Outside Setup */
.location{
  .p-dialog{
    .p-dialog-content{
      padding: 0rem !important;
    }
  }
}
/* For Outside Setup */

.select-time .p-dialog .p-dialog-content{
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.custella-custom-dialog .p-dialog{
    background: a.$white !important;
}

.custella-custom-dialog{
  position: absolute;
  z-index: 1003;
}
.custella-custom-dialog-no{
  // position: absolute;
  z-index: 1003;
}
/* For Outside Setup */
.custella-custom-dialog .p-dialog .p-dialog-header ,.custella-custom-dialog  .p-dialog-header{
    background: a.$headerblack !important;
    color: a.$white !important;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    height: 45px !important;
}
// .custella-custom-dialog .p-confirm-dialog-message{
//     margin-top: 1rem !important;
// }
.custella-custom-dialog .p-dialog .p-dialog-footer{
    border-top: 1px solid var(--surface-300) !important;
    padding: 0.95rem 0 !important;
    margin: 0 1.25rem;
}
.custella-custom-dialog{ 
  .p-dialog.p-confirm-dialog{
    .p-confirm-dialog-message{
      /* 14/8/24; commenting out break-all due to the words are breaking in common-delete*/
      // word-break: break-all;
      margin-left: 0.25rem !important;
    }
  }
  
}

/* Dialog; AppendTo Body*/
.p-dialog{
    &-header{
      background: a.$headerblack  !important;
      color: a.$white  !important;
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
      justify-content: flex-start;
      padding: 0.65rem 1.5rem !important;
      .p-dialog-title{
        font-family: a.$form-font  !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }
    &-content {
      padding-top: 1rem !important;
      // border-bottom-left-radius: 4px;
      // border-bottom-right-radius: 4px;
      .title{
        font-size: 14px;
        font-weight: a.text-weight(light);
        color: a.$lightGrey5;
        margin-bottom: 0;
        margin-top: 1rem;
      }
    }
    &-footer {
      border-top: 1px solid a.$border-top-grey !important;
      padding: 0.95rem 1.75rem !important;
    }
}
.modal-content{
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    border: none !important;
    .custella-modal .checkAv{
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
    background: #FAFAFA !important;
}
.custom-modal-sm
{
    width: 75% !important;
    margin: auto !important;
}

.modal-sm
{
    width: 500px !important;
    // margin: auto !important;
}

.modal-dialog {
    max-width: 1000px !important;
    margin-right: auto;
    margin-left: auto;
  }

  .modal-dialog-lg {
    // max-width: 97% !important;
    margin-right: auto;
    margin-left: auto;
  }

.modal-footer{
    border-top: 1px solid var(--surface-300) !important;
    padding: 0.95rem 0 !important;
    margin: 0 1.25rem;
}

// base modal
.custella-modal {
    .custella-modal-header {
      display: flex;
      justify-content: space-between;
      background: a.$headerblack  !important;
      color: a.$white  !important;
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
      padding: 0.65rem 1.75rem;
      .custella-modal-header-text {
        font-size: 14px !important;
        font-weight: a.text-weight(medium) !important;
      }
    }
  
    .custella-modal-body {
      padding: 20px;
      // max-height: 80vh;
      // overflow: scroll;
    }
    .location.custella-modal-body {
      padding: 0;
      // max-height: 80vh;
      // overflow: scroll;
    }
  
    .custella-modal-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .custella-modal-footer {
      border-top: 1px solid var(--surface-300) !important;
      padding: 0.95rem 0 !important;
      margin: 0 1.25rem;
      text-align: right;
    }
  }
  .custom-modal-lg {
    max-width: 1000px!important;
}
.userSkills .p-dialog .p-dialog-content{
  padding: 0 !important;
}
.scroll-content{
 height: 40vh;
 overflow-y: scroll;
 padding: 0 0.25rem;
}