.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext  {
    width: $colorPickerPreviewWidth;
    height: $colorPickerPreviewHeight;
}

.p-colorpicker-panel {
    background: $colorPickerBg;
    border-color: $colorPickerBorderColor;

    .p-colorpicker-color-handle,
    .p-colorpicker-hue-handle {
        border-color: $colorPickerHandleColor;
    }
}

.p-colorpicker-overlay-panel {
    box-shadow: $inputOverlayShadow;
}

.showOptionBig{
    width: 42px !important;
    height: 42px !important;
    background-color: red;
    border-radius: 50% !important;
    border: 2px solid #E5E5E5;
    padding: 0.54rem 0.5rem;
    cursor: pointer !important;
    i {
        margin-left: 34px;
         margin-top: -9px;
    }
}
.showOption{
    width: 32px !important;
    height: 32px !important;
    background-color: red;
    border-radius: 50% !important;
    border: 2px solid #E5E5E5;
    padding: 0.54rem 0.5rem;
    cursor: pointer !important;
}
.color1{
    background-color: #e7e7e7;
    color:  #1a1a1a;

}
.color2{
    background-color: #1a1a1a;
    color:  #ffffff;
}
.color3{
    background-color: #ffffff;
    color:  #1a1a1a;
}
.color4{
    background-color: #6366f1;
    color:  #ffffff;
}
.upd-theme{
    height: 3rem !important;
    width: 3rem !important;
}