/*Fixed Variables
/*Fonts
========================================================================== */
//@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

@font-face {
    font-family: "Inter-Regular";
    src: url('../../font/Inter/static/Inter-Regular.ttf');
}
@font-face {
    font-family: "Inter-Bold";
    src: url('../../font/Inter/static/Inter-Bold.ttf');
}
@font-face {
    font-family: "Inter-ExtraBold";
    src: url('../../font/Inter/static/Inter-ExtraBold.ttf');
}
@font-face {
    font-family: "Inter-ExtraLight";
    src: url('../../font/Inter/static/Inter-ExtraLight.ttf');
}
@font-face {
    font-family: "Inter-Light";
    src: url('../../font/Inter/static/Inter-Light.ttf');
}
@font-face {
    font-family: "Inter-Medium";
    src: url('../../font/Inter/static/Inter-Medium.ttf');
}
@font-face {
    font-family: "Inter-SemiBold";
    src: url('../../font/Inter/static/Inter-SemiBold.ttf');
}


$form-font: 'Inter-Regular';
$form-font-bold: 'Inter-Bold';
$form-font-extra-bold: 'Inter-ExtraBold';
$form-font-extra-light: 'Inter-ExtraLight';
$form-font-light: 'Inter-Light';
$form-font-medium: 'Inter-Medium';
$form-font-semi-bold: 'Inter-SemiBold';
/*Colors
========================================================================== */
$white: #fff;
$white2: #FAFAFA;
$white3:#F0F0F0;
$white4:#F4F4F4;
$black: #12191D;
$black2: #212121;
$purple: #6366F1;
$purple2: #8183f4;
$lightPurple: #DADBFF;
$lightPurple2: #E9ECEF;
$violet: #C7C8FE;
$violet2: #652266;
$violet3: #53389E;
$violet4: #7F56D9;
$lightViolet: #FFECFF;
$lightViolet2: #EEC6FD;
$lightViolet3: #F9F5FF;
$grey: #EAEAEA;
$grey2: #818181;
$grey3: #F1F1F1;
$grey4: #878787;
$grey5: #ced4da;
$grey6: #565656;
$grey7: #797979;
$grey8: #E0E0E0;
$grey9: #CDCDD7;
$grey10: #F2F4F7;
$grey11: #EAECF0;
$grey12: #EDEDED;
$dark-color: #324954;
$dark-color2: #27373F;
$dark-color3: #1a2428;
$dark-color4: #435861;
$dark-color5: #212529;
$light-dark-color: #32454E;
$lightGrey: #F5F5F5;
$lightGrey2: #C4C4C4;
$lightGrey3: #9E9E9E;
$lightGrey4: #EEE6E7;
$lightGrey5: #7C828D;
$green: #6FBC60;
$green2: #EFFFEC;
$green3: #6EEAB3;
$green4: #22C55E;
$green5: #C6FDC6;
$red: #D34747;
$red2: #FFECEC;
$red3: #F44336;
$red4: #c55757;
$red5: #FECFD4;
$red6: #FF8981 ;
$yellow: #E88901;
$yellow2: #FFF6DF;
$yellow3: #F59E0B;
$yellow4: #FFE8B1;
$orange: #ff6644;
$orange2: #ef7224;
$orange3: #ffd6bc;
$orange4: #FAC672;
$orange5: #e89901;
$blue: #469EE3;
$blue2: #EFF8FF;
$blue3: #D4F9FA;
$dark-blue: #00598c;
$gray: #777777;
$headerblack: #26363e;
$form-shadow:0px 2px 6px 0px #0000001F, 0px 0px 2px 0px #0000000F, 0px 4px 10px 0px #00000008;


$background-color: $white2;
$primary-color: $purple;
$dark-font: $grey6;
$dark-font2: $grey4;
$dark-font3: $dark-color5;
$light-font: $grey;
$green-font: $green;
$red-font: $red;
$blue-font: $blue;
$yellow-font: $yellow;
$orange-font: $orange2;
$grey-font: $grey7;
$violet-font2: $violet2;
$purple-font: $primary-color;
$primary-color-light: $lightPurple;


$green-online: $green3;
$red-offline: $red;
$violet-font: $violet;

$border-color: $light-font;
$border-color-hover: $lightPurple2;
$border-color-active: $primary-color;
$border-color-glow: $lightPurple;
$border-color-error: $red3;
$border-color-secondary: $dark-color;
$border-top-grey: var(--surface-300); 
$bg-error-delete: $red;
$bg-green-success: $green4;
$bg-yllw-warning: $yellow3;
$bg-orange-warning: $orange;

$bg-progress: $orange2;
$bg-progress-load: $orange3;
$bg-prog-tag-start: $dark-blue;
$bg-prog-tag-risk: $orange2;
$bg-prog-tag-violate: $red4;

$bg-team-status-open: $blue3;
$bg-team-status-cancel: $red5;
$bg-team-status-leave: $lightViolet2;
$bg-team-status-completed: $green5;
$bg-team-status-progress: $yellow4;
$bg-team-status-incomplete: $orange4;
$bg-team-status-pending: $lightGrey4;


$low-priority: $green;
$medium-priority: $yellow;
$high-priority: $red;


/*Typography
========================================================================== */
$h1-fontsize: 4.2rem !default;
$h2-fontsize: 3.56rem !default;
$h3-fontsize: 2.92rem !default;
$h4-fontsize: 2.28rem !default;
$h5-fontsize: 1.64rem !default;
$h6-fontsize: 1rem !default;

$xxl-fontsize: 1.5rem !default;
$xl-fontsize: 1.35rem !default;
$l-fontsize: 1.2rem !default;
$normal-fontsize: 12px !default;
$s-fontsize: 10px !default;
$xs-fontsize: 0.5rem !default;

$font-weights:(
    "thin": $form-font-light,
    "regular": $form-font,
    "medium": $form-font-medium,
    "semiBold": $form-font-semi-bold,
    "bold": $form-font-bold
);

/*Text Input + Textarea
========================================================================== */
$input-text: $light-font;
$input-active: $border-color-active;
$input-glow: $border-color-glow;
$input-invalid: $border-color-error;

/*Grid
========================================================================== */


/* Media Query Ranges
========================================================================== */
$xxsmall-screen: 376px !default;
$xsmall-screen: 400px !default;
// $small-screen: 625px !default; //original value
// $small-screen: 375px !default;
$small-screen: 767px !default;
$xmedium-screen: 768px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$min-width-laptop-screen: $large-screen !default;
$max-width-laptop-screen: 1600px !default;
$small-screen-up: $small-screen + 1px !default;
$xmedium-screen-up: $xmedium-screen + 1px !default;
$medium-screen-up: $medium-screen + 1px !default;
$large-screen-up: $large-screen + 1px !default;

$xmedium-and-up: "only screen and (min-width : #{$xmedium-screen-up})" !default;
$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$xlarge-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$small-only: "only screen and (min-width : #{$xxsmall-screen}) and (max-width : #{$small-screen})" !default;
$xsmall-and-down: "only screen and (max-width : #{$xsmall-screen})" !default;
$xxsmall-and-down: "only screen and (max-width : #{$xxsmall-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$xmedium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$xmedium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

/*Header
========================================================================== */
/*Body
========================================================================== */
/*Navbar
========================================================================== */
/*Side Navigation
========================================================================== */
/*Loader
========================================================================== */
/*Payment Module
========================================================================== */
/*Booking Summary
========================================================================== */


/*Buttons
========================================================================== */
// Shared Styles
$button-primary-color: $primary-color;
// Disabled Styles
// Raised Btns
// Large Btns
// Flat Btns
// Floating Btns
/*Checkbox
========================================================================== */
$checkbox-background-color: $primary-color;

/*Collapsible
========================================================================== */
/*Dropdown
========================================================================== */
/*Radio Buttons
========================================================================== */

/* Translate
========================================================================== */
/* Top */
$translate-top-5: 5%;
$translate-top-8: 8%;
$translate-top-10: 10%;
$translate-top-negative-10: -10%;
$translate-top-20: 20%;
$translate-top-80px: 80px;
$translate-top-150px: 150px;


$padding-10px: 10px;
$padding-top-50px: 50px;
$padding-24px: 24px;
/* Left */
$translate-left-10: 10%;
$translate-left-35px: 35px;

/* Bottom */
$padding-bottom-20px: 20px;