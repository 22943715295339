.p-tag {
    background: $badgeBg;
    color: #000;
    font-size: $badgeFontSize;
    font-weight: $badgeFontWeight;
    padding: $tagPadding;
    border-radius: $borderRadius;

    &.p-tag-success {
        background-color: $successTagBg;
        color: $successTagTextColor;
    }

    &.p-tag-info {
        background-color: $neutralTagBg;
        color: $neutralTagTextColor;
    }

    &.p-tag-misc {
        background-color: $miscTagBg;
        color: $miscTagTextColor;
    }


    &.p-tag-warning {
        background-color: $darkTagBg;
        color: $darkTagTextColor;
    }

    &.p-tag-danger {
        background-color: $dangerTagBg;
        color: $dangerTagTextColor;
    }

    .p-tag-icon {
        margin-right: calc($inlineSpacing / 2);
        font-size: $badgeFontSize;
    }
    
      
    &.p-tag-Pending-Activation {
        background-color: #e6b65a;
      }
      
      &.p-tag-Suspended {
        background-color: #de7778;
      }
      
      &.p-tag-Deactivate {
        background-color: #b7b7b7;
      }
}
