@use '../abstracts' as a;

// .ag-paging-panel.ag-unselectable{
//     display: none !important;
//     justify-content: space-between !important;
//     position: static !important;
//     bottom: 0 !important;
//     background: a.$white;
//     margin-top: 10% !important;
//     border: none !important;
// }
.ag-paging-row-summary-panel{
    #{&}-number {
        font-weight: 700 !important;
    }
}
// .ag-paging-page-summary-panel[role="presentation"]{
//     display: none !important;
// }
.custella-transp-button{
    background: none;
    border: 0;
}
.custella-ed-button{
    background: none;
    border: 0;
    color: a.$dark-color4;
    width: 24px;
    &:hover{
        color: a.$primary-color;
    }
}
.custella-del-button{
    background: none;
    border: 0;
    color: a.$dark-color4;
    width: 24px;
    &:hover{
        color: a.$red;
    }
}
.ag-theme-balham .ag-root-wrapper{
    border-left: 0 !important;
    border-right: 0!important;
    border-bottom:solid 1px #E0E0E0!important;
    border-top: solid 1px #E0E0E0 !important;
    --ag-header-background-color: rgb(255, 255, 255);
    // --ag-odd-row-background-color: #9e9fed10;
}
.ag-header-cell-text{
    color:#777777;
    font-family: a.$form-font;
}
.ag-cell{
    color: #212121;
    // font-family: a.$form-font-medium;
}
.ag-theme-balham .ag-header{
    border-bottom:solid 2px #E0E0E0!important;
}

.table-action_::after {
    width: 0 !important
}
.table_default_color{
    color: #6366F1;
    cursor: pointer;
}
.table_default_color-top{
    color: #6366F1;
    cursor: pointer;
    p{
        line-height: unset;
    }
}
.ag-record-row-summary-panel{
    color:a.$gray;
    // padding-top: 5px;
    // padding-right: 20px;
    font-size: 12px;
    
    
}
.ag-theme-balham{
    height: 98%;
}
.ag-theme-balham .hide-action-border::after{
    width: 0px !important;
}

.ag-theme-balham .hide-action-border-left::after{
    width: 0px !important;
}

.not-found{
    width: 100%;
    height: 98%;
    position: absolute;
    background-color: #fff;
    margin: auto;
    top:34px ;
    z-index: 9;
    padding: 20vh;

    span{
        transform: translateY(90px);
    }
}

.not-found-task{
    width: 100%;
    height: 98%;
    position: absolute;
    background-color: #fff;
    margin: auto;
    top:unset !important ;
    z-index: 9;
    padding: 20vh;

    span{
        transform: translateY(90px);
    }
}

.hide-action-border .ag-header-cell-label{
    justify-content: center;
    padding-left: 12px;
}

.hide-action-border-left .ag-header-cell-label{
    justify-content: left;
}
.hide-action-border-right .ag-header-cell-label{
    justify-content: right;
}

.ag-theme-balham .ag-popup{
    height: 0 !important;
}

/* Ticket Format table in modal*/
.tix .p-datatable-thead tr th{
    background: a.$grey3 !important;
}
.ag-overlay{
    background-color: #fff;
}
.ui-state-highlight {
    background: #f1f0f1 !important;
}
.check-list{
    cursor: pointer;
    &:hover{
        background: #f6f3f6 !important;
    }
}
  
.table_default_color-top{
    color: #6366F1;
    cursor: pointer;
    span p{
        line-height: unset;
    }
}

.ag-cell-value{
    p{
        line-height: unset;
    } 
}
// .ag-center-cols-clipper,
// .ag-center-cols-container {
//   height: 300px !important;
// }