/* dashboard */
.sidebar{
    .logo{
        padding-top: 7px !important; 
        padding-bottom: 12px !important; 
        position: fixed !important; 
        background-color: #1a2428 !important; 
        z-index: 100 !important;
    }
}

/* dynamic form */
.btn-group-toggle{
    &.space{
        display: grid !important;
        grid-template-columns: repeat(3, minmax(122px, auto)) !important;
    }
}

/* active-reports */
.h-95vh{
    height: 95vh !important;
}

/* completion form */
.img-form{
    object-fit: cover !important; 
    object-position: center !important; 
    border-radius: 6px !important; 
    height: 65px !important; 
    width: 65px !important;
}

/* messages */
.chat-title{
    padding: 3px 27px 2px 27px !important;
}

/* sales order */
.sub-amount{
    display: initial; 
    position: relative; 
    right: 0; 
    margin-top: 20px;
}

/* calendly - set up events */
.arrow-calendly{
    font-size: 2rem !important; 
    color: #AAA !important;
}

/* system fault */
.short-label{
    max-width: 210px !important;
    white-space: nowrap !important; 
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

/* job order: task template */
.task-template-table{
    padding-top: 0px !important;
    margin-top: 5px !important; 
    height: 200px !important;
    width: 100% !important;
}

/* workflows */
.btn-group{
    &.btn-cond{
        display: grid !important;
        grid-template-columns: repeat(2, minmax(22px, 50px));
    }
}

/* roles-skills user selection */
.user-select{
    display: flex !important; 
    flex-direction: column !important; 
    box-shadow: none !important;
}

/* task  */
.add-note{
    margin: 15% auto !important;
}
/* box-sizing */
.box-border{
    box-sizing: border-box !important;
}
/* text-size */
.text-15{
    font-size: 15px !important;
}
/* height */
.h-100-3px{
    height: calc(100% - 3px) !important;
}
.h-100-25px{
    height: calc(100% - 25px) !important;
}
.h-100-30px{
    height: calc(100% - 30px) !important;
}
.h-100-46px{
    height: calc(100% - 46px) !important;
}
.h-100-48px{
    height: calc(100% - 48px) !important;
}
.h-100-48px{
    height: calc(100% - 48px) !important;
}
.h-100-55px{
    height: calc(100% - 55px) !important;
}
.h-100-62px{
    height: calc(100% - 62px) !important;
}
.h-100-65px{
    height: calc(100% - 65px) !important;
}
.h-100-73px{
    height: calc(100% - 73px) !important;
}
.h-100-75px{
    height: calc(100% - 75px) !important;
}
.h-100-78px{
    height: calc(100% - 78px) !important;
}
.h-100-89px{
    height: calc(100% - 89px) !important;
}
.h-100-95px{
    height: calc(100% - 95px) !important;
}
.h-100-100px{
    height: calc(100% - 100px) !important;
}
.h-100-120px{
    height: calc(100% - 120px) !important;
}
.h-100-128px{
    height: calc(100% - 128px) !important;
}
.h-100-145px{
    height: calc(100% - 145px) !important;
}
.h-100-151px{
    height: calc(100% - 151px) !important;
}
.h-100-171px{
    height: calc(100% - 171px) !important;
}
.max-h-100-10rm{
    height: calc(100% - 10rem) !important;
}
.h-40vh{
    height: 40vh !important;
}
.h-42vh{
    height: 42vh !important;
}
.h-50vh{
    height: 50vh !important;
}
.h-60vh{
    height: 60vh !important;
}
.h-73vh{
    height: 73vh !important;
}
.h-74vh{
    height: 74vh !important;
}
.h-89vh{
    height: 89vh !important;
}
.h-100px{
    height: 100px !important;
}
.h-107px{
    height: 107px !important;
}
.h-117px{
    height: 117px !important;
}
.h-140px{
    height: 140px !important;
}
.h-200px{
    height: 200px !important;
}
.h-270px{
    height: 270px !important;
}
.h-300px{
    height: 300px !important;
}
.h-350px{
    height: 350px !important;
}
.h-400px{
    height: 400px !important;
}
.h-407px{
    height: 407px !important;
}
.h-450px{
    height: 450px !important;
}
.h-15px{
    height: 15px !important;
}
.h-24px{
    height: 24px !important;
}
.h-25px{
    height: 25px !important;
}
.h-27px{
    height: 27px !important;
}
.h-33px{
    height: 33px !important;
}
.h-35px{
    height: 35px !important;
}
.h-37px{
    height: 37px !important;
}
.h-40px{
    height: 40px !important;
}
.h-41px{
    height: 41px !important;
}
.h-45px{
    height: 45px !important;
}
.h-50px{
    height: 50px !important;
}
.h-62px{
    height: 62px !important;
}
.h-65px{
    height: 65px !important;
}
.h-70px{
    height: 70px !important;
}
.h-951px{
    height: 951px !important;
}
.h-2rem{
    height: 2rem !important;
}
.h-2p5rem{
    height: 2.5rem !important;
}
.h-87p5-prcnt{
    height: 87.5% !important;
}
.h-90-prcnt{
    height: 90% !important;
}
.h-92-prcnt{
    height: 92% !important;
}
.h-95-prcnt{
    height: 95% !important;
}
.height-0{
    height: 0 !important;
}
.height-fit-content{
    height: fit-content !important;
}
.height-unset{
    height: unset !important;
}

.min-h-18px{
    min-height: 18px !important;
}
.min-h-178px{
    min-height: 178px !important;
}
.min-h-350px{
    min-height: 350px !important;
}
.min-h-unset{
    min-height: unset !important;
}

.max-h-150px{
    max-height: 150px !important;
}
.max-h-200px{
    max-height: 200px !important;
}
/* background */
.shadow{
    box-shadow: 0px 4px 15px -4px rgb(117 115 115 / 80%) !important;
}
.bg-none{
    background: none !important;
}
.bg-overdue-db6c{
    background-color: #db6c6c !important;
}
.bg-ffc{
    background-color: #ffc300 !important;
}
.bg-839{
    background-color: #839190 !important;
}
.bg-f1f0{
    background: #f1f0f1 !important;
}
.bg-4b5{
    background: #4b5357 !important;
}
.bg-crimson{
    background-color: crimson !important;
}
.bg-fafa{
    background-color: #FAFAFA !important;
}
/* color */
.color-948{
    color: #948e8ed6 !important;
}
.color-2a3{
    color: #2A3A43 !important;
}
.color-e0{
    color: #e0e0e0 !important;
}
.color-ffb{
    color: #ffb1b1 !important;
}
.color-f57{
    color: #f57c00 !important;
}
.color-4ca{
    color: #4caf50 !important;
}
.color-d347{
    color: #d34747 !important;
}
/* width */
.w-100-30px{
    width: calc(100% - 30px) !important;
}
.w-100-110px{
    width: calc(100% - 110px) !important;
}
.w-100-125px{
    width: calc(100% - 125px) !important;
}
.w-100-171px{
    width: calc(100% - 171px) !important;
}
.w-100-223px{
    width: calc(100% - 223px) !important;
}
.w-20px{
    width: 20px !important;
}
.w-25px{
    width: 25px !important;
}
.w-30px{
    width: 30px !important;
}
.w-37px{
    width: 37px !important;
}
.w-65px{
    width: 65px !important;
}
.w-70px{
    width: 70px !important;
}
.w-75px{
    width: 75px !important;
}
.w-80px{
    width: 80px !important;
}
.w-85px{
    width: 85px !important;
}
.w-90px{
    width: 90px !important;
}
.w-100px{
    width: 100px !important;
}
.w-110px{
    width: 110px !important;
}
.w-120px{
    width: 120px !important;
}
.w-130px{
    width: 130px !important;
}
.w-140px{
    width: 140px !important;
}
.w-150px{
    width: 150px !important;
}
.w-200px{
    width: 200px !important;
}
.w-300px{
    width: 300px !important;
}
.w-900px{
    width: 900px !important;
}
.w-1rem{
    width: 1rem !important;
}
.width-0{
    width: 0 !important;
}
.w-12-prcnt{
    width: 12% !important; 
}
.w-15-prcnt{
    width: 15% !important; 
}
.w-17-prcnt{
    width: 17% !important; 
}
.w-20-prcnt{
    width: 20% !important; 
}
.w-24p5-prcnt{
    width: 24.5% !important; 
}
.w-25-prcnt{
    width: 25% !important; 
}
.w-28-prcnt{
    width: 28% !important; 
}
.w-30-prcnt{
    width: 30% !important; 
}
.w-40-prcnt{
    width: 40% !important; 
}
.w-65-prcnt{
    width: 65% !important; 
}
.w-68-prcnt{
    width: 68% !important; 
}
.w-90-prcnt{
    width: 90% !important; 
}
.w-92-prcnt{
    width: 92% !important; 
}
.w-95-prcnt{
    width: 95% !important; 
}

.w-web{
    width: -webkit-fill-available !important;
    width: -moz-available !important;
}
.w-fit{
    width: fit-content !important;
}

.max-w-210px{
    max-width: 210px !important;
}
.max-w-500px{
    max-width: 500px !important;
}
/* border */
.br-right{
    border-right: 1px solid #d4d4d4 !important;
}
.br-right-5656{
    border-right: 2px solid #565656 !important;
}
.br-top-e9ec{
    border-top: 1px solid #e9ecef !important;
}
.br-bottom-de{
    border-bottom: 1px solid #dedede !important;
}

.br-rad-s-0{
    border-top-left-radius: 0px !important; 
    border-bottom-left-radius: 0px !important;
}
.br-rad-t-2{
    border-top-left-radius: 2px !important; 
    border-top-right-radius: 2px !important; 
}
.br-rad-0{
    border-radius: unset !important;
}
.br-rad-4{
    border-radius: 4px !important;
}
.br-rad-6{
    border-radius: 6px !important;
}
.br-rad-12{
    border-radius: 12px !important;
}
.br-rad-s-10{
    border-radius: 10px 0px 0px 10px !important;
}
.br-rad-e-10{
    border-radius: 0px 10px 10px 0px !important;
}

.border-ad{
    border: 1px solid #adadad !important;
}
.border-dee{
    border: 1px solid #dee2e6 !important;
}
.border-4b5{
    border: 1px solid #4b5357 !important;
}
.border-eb{
    border: 1px solid #EBEBEB !important;
}
.border-ea{
    border: 1px solid #eaeaea !important;
}
.border-y-dee{
    border-top: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
}
.border-e-dee{
    border-right: 1px solid #dee2e6 !important;
}

/* overflow */
.overflow-y-scroll{
    overflow-y: scroll !important;
}
.overflow-y-auto{
    overflow-y: auto !important;
}
.overflow-x-auto{
    overflow-x: auto !important;
}
.overflow-x-hidden{
    overflow-x: hidden !important;
}

/* flex */
.flex-flow-column{
    flex-flow: column !important;
}
.flex-direction-column{
    flex-direction: column;
}

/* space */
.top-59px{
    top: 59px !important;
}
.end-15px{
    right: 15px !important;
}
.end-neg-50px{
    right: -50px !important;
}
.gap-10px{
    gap: 10px !important;
}
.gap-5rem{
    gap: 5rem !important;
}
.row-gap-10px{
    row-gap: 10px !important;
}
.column-gap-1p5rem{
    column-gap: 1.5rem !important;
}
.m-2px{
    margin: 2px !important;
}
.m-4px{
    margin: 4px !important;
}
.my-10px{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}
.my-12px{
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}
.mt-4rem{
    margin-top: 4rem !important;
}
.mt-neg-4px{
    margin-top: -4px !important;
}
.mt-neg-10px{
    margin-top: -10px !important;
}
.mt-4px{
    margin-top: 4px !important;
}
.mt-5px{
    margin-top: 5px !important;
}
.mt-10px{
    margin-top: 10px !important;
}
.mt-15px{
    margin-top: 15px !important;
}
.mt-18px{
    margin-top: 18px !important;
}
.mt-22px{
    margin-top: 22px !important;
}
.mt-30px{
    margin-top: 30px !important;
}
.mt-p8rem{
    margin-top: 0.8rem !important;
}
.mt-p85rem{
    margin-top: 0.85rem !important;
}
.ms-5px{
    margin-left: 5px !important;
}
.ms-10px{
    margin-left: 10px !important;
}
.ms-15px{
    margin-left: 15px !important;
}
.ms-18px{
    margin-left: 18px !important;
}
.ms-3p5rem{
    margin-left: 3.5rem !important;
}
.ms-10-prcnt{
    margin-left: 10% !important;
}
.me-4px{
    margin-right: 4px !important;
}
.me-5px{
    margin-right: 5px !important;
}
.me-7px{
    margin-right: 7px !important;
}
.me-14px{
    margin-right: 14px !important;
}
.mb-neg-15px{
    margin-bottom: -15px !important;
}
.mb-3px{
    margin-bottom: 3px !important;
}
.mb-15px{
    margin-bottom: 15px !important;
}
.mb-25px{
    margin-bottom: 25px !important;
}
.mx-5px{
    margin-left: 5px !important;
    margin-right: 5px !important;
}
.py-2px{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}
.py-5px{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.px-6px{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.px-30px{
    padding-right: 30px !important;
    padding-left: 30px !important;
}
.px-p75rem{
    padding-right: 0.75rem !important;
    padding-left: 0.75rem !important;
}
.px-2rem{
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}
.pt-2px{
    padding-top: 2px !important;
}
.pt-3px{
    padding-top: 3px !important;
}
.pt-14px{
    padding-top: 14px !important;
}
.pt-15px{
    padding-top: 15px !important;
}
.pt-18px{
    padding-top: 18px !important;
}
.pb-10px{
    padding-bottom: 10px !important;
}
.pb-2rem{
    padding-bottom: 2rem !important;
}
.pb-5rem{
    padding-bottom: 5rem !important;
}
.pe-4px{
    padding-right: 4px !important;
}
.pe-12px{
    padding-right: 12px !important;
}
.pe-25px{
    padding-right: 25px !important;
}
.ps-2px{
    padding-left: 2px !important;
}
.ps-3px{
    padding-left: 3px !important;
}
.ps-4px{
    padding-left: 4px !important;
}
.ps-35px{
    padding-left: 35px !important;
}
.ps-60px{
    padding-left: 60px !important;
}
.ps-2p15rem{
    padding-left: 2.15rem !important;
}
.p-10px{
    padding: 10px !important;
}
.p-20px{
    padding: 20px !important;
}

.top-51px{
    top: 51px !important;
}

.transform-x-10px{
    transform: translate(0px, 10px) !important;
}
/* alignment */
.vertical-top{
    vertical-align: top !important;
}
.vertical-baseline{
    vertical-align: baseline !important;
}
.justify-content-unset{
    justify-content: unset !important;
}
.align-items-normal{
    align-items: normal !important;
}

/* object */
.object-fit-cover{
    object-fit: cover !important;
}
.object-position-center{
    object-position: center !important;
}

/* text */
.text-pre-wrap{
    white-space: pre-wrap !important;
}
/* tag status */
.p-tag{
    &.jmp-approve{
        background-color: #1aac2050 !important; color: #1aac2050 !important;
    }
    &.jmp-reject{
        background-color: #db538350 !important; color: #db538350 !important;
    }
    &.jmp-submit{
        background-color: #c4c8cb !important; color: #c4c8cb !important;
    }
    &.leave-applied{
        background-color: #FEEDAF !important ;color: #8a5340 !important;
    }
    &.leave-approved{
        background-color: #C8E6C9 !important ;color: #256029 !important;
    }
    &.leave-rejected{
        background-color: #FFCDD2 !important ;color: #c63737 !important;
    }
}

/* cursor */
.cursor-unset{
    cursor: unset !important;
}

/* scrollbar */
.scroll-10px{
    scrollbar-width: 10px !important;
}

/* z-index */
.z-200{
    z-index: 200 !important;
}