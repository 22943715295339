.p-chip {
    background-color: $chipBg;
    color: $chipTextColor;
    border-radius: $chipBorderRadius;
    padding: 0 div($inputPadding, 2);

    .p-chip-text {
        line-height: 1.5;
        margin-top: div($inputPadding, 1) / 2;
        margin-bottom: div($inputPadding, 1) / 2;
    }

    .p-chip-icon {
        margin-right: $inlineSpacing;
    }

    .pi-chip-remove-icon {
        margin-left: $inlineSpacing;
    }

    img {
        width: calc(1.5 + div($inputPadding, 1));
        height: calc(1.5 + div($inputPadding, 1));
        margin-left: calc(-1 * div($inputPadding, 2));
        margin-right: $inlineSpacing;
    }

    .pi-chip-remove-icon {
        border-radius: $borderRadius;
        transition: $actionIconTransition;
        
        &:focus {
            @include focused();
        }
    }
}