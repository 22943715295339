@use '../abstracts' as a;

.p-button {
    background: a.$primary-color !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    border: 1px solid a.$primary-color !important;
    cursor: pointer;

    &:enabled:hover {
        @include a.btn-hover;
    }

    &.connect,
    &.disconnect {
        padding: 0.5rem 1.5rem !important;
        height: 28px !important;
        // width: 108px !important; Removing width as it is affecting translations and autoResize width
    }
    &.p-button-secondary {
        &.p-button-outlined {
            // background: a.$white !important;
            // color: a.$black !important;
            font-family: a.$form-font !important;
            font-size: a.$normal-fontsize !important;
            border: 1px solid a.$lightGrey3 !important;
    
            &:enabled:hover {
                background: #f4f4f4 !important;
                color: #3d3f40 !important;
                border: 1px solid #a4a4a4 !important;
            }
            &:disabled{
                background: #f3f3f3 !important;
                color: #7a7a7a !important;
                border: 1px solid #a4a4a4 !important;
            }
        }
    }
    &.p-button-danger {
        background: a.$bg-error-delete !important;
        border: 1px solid a.$bg-error-delete !important;
        &.p-button-outlined {
            background-color: a.$white !important;
            color: a.$bg-error-delete !important;
            border: 1px solid a.$bg-error-delete !important;
        }

        &:active {
            border: 1px solid a.$bg-error-delete !important;
        }

        &:focus {
            border: 1px solid a.$bg-error-delete !important;
        }

        &:enabled:hover {
            background: #c02929 !important;
            color: #ffffff !important;
            border-color: #c02929 !important;
        }
    }
    .p-button-label{
        word-break: keep-all !important; //This is used for Chinese, Japanese and Korean text words are not broken
    }
}

.p-button-outlined {
    background-color: a.$white !important;
    .p-button-danger{
        background-color: a.$white !important;
    }
}

.p-button.dark-alt {
    background: a.$dark-color !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    border: 1px solid a.$dark-color !important;
    height: 32px;
    cursor: pointer;
    &:enabled:hover {
        @include a.btn-dark-hover;
    }

    &:focus {
        @include a.temp-focus;
    }
}

/* File Upload */
.p-fileupload .p-button {
    height: 32px;
}

.p-fileupload-choose:not(.p-disabled):hover {
    @include a.btn-hover;
}

.opt-plan .custella-form-container-footer-float {
    .p-button.pre-assign {
        background: a.$dark-color !important;
        font-family: a.$form-font !important;
        font-size: a.$normal-fontsize !important;
        border: 1px solid a.$dark-color !important;
        cursor: pointer;

        &:focus {
            @include a.temp-focus;
        }
    }

    .p-button.pre-bulk {
        background: #1a2428 !important;
        font-family: a.$form-font !important;
        font-size: a.$normal-fontsize !important;
        border: 1px solid #1a2428 !important;
        cursor: pointer;

        &:focus {
            @include a.temp-focus;
        }
    }

}

.custella-dashboard-top-right {
    .p-button.dark {
        background: a.$dark-color !important;
        border: 1px solid a.$dark-color !important;
        height: 32px;
        cursor: pointer;
        &:enabled:hover {
            @include a.btn-dark-hover;
        }

        &:focus {
            @include a.temp-focus;
        }
    }

    .p-button.purple {
        background: a.$purple !important;
        font-family: a.$form-font !important;
        font-size: a.$normal-fontsize !important;
        border: 1px solid a.$purple !important;
        height: 32px;
        cursor: pointer;

        &:focus {
            @include a.temp-focus;
        }
    }
}

.p-button.p-button-secondary {
    background: a.$white !important;
    color: a.$black !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    border: 1px solid a.$grey5 !important;
}



.setup {
    margin-top: 0.15rem;

    .p-button.p-button-rounded {
        padding: 7px 12px;
    }

    .p-button.p-button-secondary {
        background: a.$white !important;
        color: a.$black !important;
        font-family: a.$form-font !important;
        font-size: a.$normal-fontsize !important;
        font-weight: a.text-weight(medium) !important;
        border: 1px solid a.$grey !important;
        padding: 7px 12px !important;
    }
}

.test {
    margin-top: 0.15rem;

    .p-button.p-button-rounded {
        padding: 2px 12px;
    }

    .p-button.p-button-secondary {
        background: a.$white !important;
        color: a.$black !important;
        font-family: a.$form-font !important;
        font-size: a.$normal-fontsize !important;
        font-weight: a.text-weight(medium) !important;
        border: 1px solid a.$grey5 !important;
        padding: 2px 12px !important;
    }
}

.p-button-outlined {
    border: 1px solid a.$primary-color-light !important;
    background-color: a.$white !important;
    color: a.$primary-color !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
}

.p-button-danger {
    background: a.$bg-error-delete !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    border: 1px solid a.$bg-error-delete !important;

    &:active {
        border: 1px solid a.$bg-error-delete !important;
    }

    &:focus {
        border: 1px solid a.$bg-error-delete !important;
    }

    &:enabled:hover {
        background: #c02929 !important;
        color: #ffffff !important;
        border-color: #c02929 !important;
    }
}

.p-button-success {
    background: a.$bg-green-success !important;
    border: 1px solid a.$bg-green-success !important;

    &:active {
        border: 1px solid a.$bg-green-success !important;
    }

    &:focus {
        border: 1px solid a.$bg-green-success !important;
    }
    &:enabled:hover {
        background: #1b9f4b !important;
        color: #ffffff !important;
        border-color: #1b9f4b !important;
    }
}
/* Icon Only Button */
.btn-list-icon {
    padding: 0.3rem 0.5rem;
    height: 32px;
    width: 32px;
    border-radius: 0.25rem;
    border: 1px solid #DEE2E6;
    background: #f0f0f0;
    color: a.$dark-color4;

    &:hover {
        @include a.btn-grey-hover;
    }
}
.btn-list-icon-active {
    background: #ffffff57 !important;
    color: a.$primary-color !important;
}

.btn-view-icon {
    padding: 0.3rem 0.4rem;
    height: 28px;
    width: 28px;
    border-radius: 0.25rem;
    border: none;
    background: #f5f5f5;
    &:hover {
        @include a.btn-view-hover;
    }
    &:focus {
        @include a.btn-view-hover;
    }
}
.btn-view-icon-form {
    padding: 0.2rem 0.4rem;
    height: 25px;
    width: 28px;
    border-radius: 0.25rem;
    border: none;
    background: #f5f5f5;
    &:hover, &.active {
        @include a.btn-view-hover-form;
    }
    // &:focus {
    //     @include a.btn-view-hover-form;
    // }
}
.btn-rmv-icon{
    // padding: 0.3rem 0.4rem;
    height: 32px;
    width: 32px;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    background: a.$bg-error-delete;
    color: a.$white;

    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        @include a.btn-rmv-hover;
    }
    &:focus {
        @include a.btn-rmv-hover;
    }
}
.fa-regular{
    &.hvr{
        &:hover{
            color: a.$primary-color;
        }
        &-del:hover{
            color: a.$red;
        }
    }
}

/* Inputgroup Buttons */
// List Search
.p-inputgroup.search {
    .p-button.p-button-icon-only {
        border: 1px solid #DEE2E6 !important;
        background: #f0f0f0 !important;
        color: a.$dark-color4;

        // .p-button-icon::before {
        //     // content: url("../../svg/search-dark.svg") !important;
        // }

        &:enabled:hover {
            @include a.btn-grey-hover;
        }
        &.p-button:focus {
            box-shadow: 0 0 0 0.09rem #c1c2f9 !important;
        }
    }
}
/* Long Button*/
.custella-btn-long{
    background: #4447ee!important;
    border: 1px solid a.$primary-color !important;
    width: 100%;
    color: a.$white;
    padding: 7px 0;
    border-radius: 8px;
    cursor: pointer;

    label {
        cursor: pointer;
    }
    &:enabled:hover {
        @include a.btn-hover-long;
    }
}

/* Colored */
.custella-btn-purple {
    background: a.$primary-color !important;
    color: a.$white;
    border: 1px solid a.$primary-color !important;
    padding: 7px 14px;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    cursor: pointer;
}

.custella-btn-white {
    background: a.$white;
    color: #5b5e61;
    border: 1px solid #bbbbbb;
    padding: 6px 14px;
    border-radius: 4px;
    height: 32px;
    font-size: a.$normal-fontsize;
    cursor: pointer;

    &:hover {
        background: #f4f4f4;
        color: #3d3f40;
        border: 1px solid #a4a4a4;
    }
}

.custella-btn-white-two {
    background: a.$white;
    color: #5b5e61;
    padding: 4px 12px;
    height: 28px;
    border: 1px solid #bbbbbb !important;
    border-radius: 4px;
    height: 28px;
    font-size: a.$normal-fontsize;
    cursor: pointer;

    &:hover {
        background: #f4f4f4;
        color: #3d3f40;
        border: 1px solid #a4a4a4;
    }
}

.custella-btn-white-three {
    background: a.$white;
    color: a.$black;
    height: 32px;
    width: 32px;
    border: 1px solid a.$grey5 !important;
    padding: 7px 14px;
    border-radius: 4px;
    cursor: pointer;
}

.custella-btn-white-28 {
    background: a.$white;
    color: a.$black;
    height: 28px;
    border: 1px solid a.$grey5 !important;
    padding: 4px 14px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background: #f4f4f4;
        color: #3d3f40;
        border: 1px solid #a4a4a4;
    }
}

.custella-btn-grey {
    display: inline-flex;
    background: a.$grey;
    color: a.$black;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    font-weight: a.text-weight(medium);
    border: 1px solid a.$grey !important;
    padding: 7px 15px;
    border-radius: 4px;
    cursor: pointer;

    label {
        cursor: pointer;
    }
}

.custella-btn-grey-outlined {
    border: 1px solid a.$border-color-secondary !important;
    background-color: a.$white !important;
    color: a.$border-color-secondary !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    padding: 7px 15px;
    border-radius: 4px;
    cursor: pointer;

    label {
        cursor: pointer;
    }
}

/* Search button for dropdowns (WILL BE REMOVED) */
.lookup-button {
    height: 32px;
    background: none;
    padding-top: 0.332rem;
    padding-bottom: 0.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    border-radius: var(--bs-border-radius-sm);
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

/* Delete button in attahments */
.attachment-delete {
    height: 37px;
}

/* Radio Button rounded */
.rounded-default-button {
    background: a.$white !important;
    color: a.$black !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    border: 1px solid a.$grey5 !important;
    border-radius: 2rem !important;

}

.rounded-chosen-button {
    background: a.$primary-color !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    border: 1px solid a.$primary-color !important;
    cursor: pointer;
    border-radius: 2rem !important;
    color: a.$white !important;
}

.rounded-unchosen-button {
    background: a.$white3 !important;
    color: a.$black !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    border: 1px solid a.$grey5 !important;
    border-radius: 2rem !important;
}
.sorted{
    .p-button.p-button-icon-only{
        width: 32px !important;
        height: 32px !important;
        background: transparent !important;
        border: 1px solid #CED4D4 !important;
        .p-button-icon::before{
            // content: url("../../svg/table/arrows-up.svg") !important;
            color: #435861 !important;
        }
    }
}
.df-lv-btn{
    button{
        border: none;
        background: transparent;
        width: 20px;
    }
}

.p-button.dark {
    background: a.$dark-color  !important;
    font-family: a.$form-font !important;
    font-size: a.$normal-fontsize !important;
    border: 1px solid a.$dark-color !important;
    height: 32px;
    cursor: pointer;
    &:enabled:hover {
        @include a.btn-dark-hover;
    }
    &:focus{
        @include a.temp-focus;
    }
}
/* Import/Export */
.import-button button {
    color: #ffffff;
    background: #6366F1;
    border: 1px solid #6366F1;
    padding: 6px 14px;
    font-size: 12px;
    border-radius: 4px;
    &:enabled:hover {
        @include a.btn-hover;
    }
}

.ed-button{
    background: none;
    border: 0;
    color: a.$white;
    width: 24px;
    &:hover{
        color: a.$primary-color;
    }
}
.ed-button-form{
    background: none;
    border: 0;
    color: a.$black;
    width: 24px;
    &:hover{
        color: a.$primary-color;
    }
}
.del-button{
    background: none;
    border: 0;
    color: a.$white;
    width: 24px;
    &:hover{
        color: a.$red;
    }
}
.del-button-form{
    background: none;
    border: 0;
    color: a.$black;
    width: 24px;
    &:hover{
        color: a.$red;
    }
}
.question-img{ 
    cursor: pointer;
    position: relative;          // Ensures the hidden-div is positioned relative to the parent
    display: inline-block;       // Keeps the container as an inline-block element
    .hidden-image{
        display: none;
        position: absolute;
        background-color: a.$white;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid a.$lightGrey;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 1;
        top: 30%;
        left: 80%;
        img{
            width: 150px; 
            height: 150px;
        }
    }
    &:hover .hidden-image {
        display: block;            // Show when hovered
    }
}
/* Bootstrap btn-group btn-dark */
/* Disable hover and clicks for view mode */
.view-mode {
    .btn {
      pointer-events: none; /* Disable interaction */
      background-color: a.$white3 !important; /* Light gray background */
      color: #6c757d !important; /* Gray text */
  
      &:hover {
        background-color: a.$white3 !important;
        color: #6c757d !important;
      }
    }
  
    /* Keep the active button visually highlighted */
    .btn.active {
      pointer-events: none; /* Allow pointer interaction for visual purposes */
      background-color: #212529 !important;
      color: a.$white !important;
    }
  }
  
  /* Default hover styles for edit mode */
  .btn-group .btn {
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #212529;
      color: a.$white;
    }
  }
