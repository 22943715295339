@use '../abstracts' as a;

/* Breadcrumb styling */
.p-breadcrumb {
  position: absolute !important;
  margin-left: -30px;
  margin-top: -0.2rem;
  width: 100% !important;
  padding: 1rem 30px 1rem 30px !important;
  font-family: a.$form-font !important;
  font-size: a.$s-fontsize !important;
  font-weight: a.text-weight(medium) !important;
  z-index: 100;
  li{
    text-overflow: ellipsis;
    max-width: 30%;
    overflow: hidden;
    white-space: nowrap;
  }
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  font-family: a.$form-font-medium !important;
  font-size: a.$normal-fontsize !important;
  color: #212529 !important;
}

/* Muqriz's Custom Navbar*/
.navigation-container {
  background: a.$white;
  display: flex;
  height: 3rem;
  align-items: center;
  z-index: 99;
  padding: 10px 16px;
  position: fixed;

  color: a.$black;
  border-bottom: 1px solid a.$grey11;
  // border-bottom: 1px solid grey;

  width: 100%;
}

// @media (min-width: 1920px) {
//   .navigation-container {
//       width: 91% !important;
//   }
// }

// @media (max-width: 1600px) {
//   .navigation-container {
//       width: 88.8% !important;
//   }
// }

.navigation-inner {
  display: inherit;
  white-space: nowrap;
  transition: width 500ms;
  overflow-y: scroll;
  // // leave this bottom one for debugging
  // border: 1px solid red;
  scrollbar-width: none;
  width: 75% !important;
}

@media (max-width: 1200px) {
  .navigation-inner {
    width: 70% !important;
  }
}

.navigation-inner::-webkit-scrollbar {
  background: transparent;
  /* make scrollbar transparent */
  width: 0px;
  display: none;
}

.navigation-item {
  display: flex;
  align-items: center;
  gap: 0.5rem !important;
}