@use '../abstracts' as a;

/* Checkbox Template 
=============================================================*/
/* PrimeNg Custom */
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: a.$primary-color ;
    background: a.$checkbox-background-color ;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: a.$primary-color  !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 3px solid a.$input-glow  !important;
    outline-offset: 0;
    box-shadow: 0 0 5px a.$input-glow  !important;
    border-color: a.$primary-color  !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: a.$primary-color  !important;
    background: a.$checkbox-background-color  !important;
    color: a.$white !important;
}

/* Disabled checkbox */
.p-checkbox{
    &.p-checkbox-checked.p-checkbox-disabled{
        .p-checkbox-box{
            &.p-disabled{
                background-color: #c8c3c3 !important;
                // .p-checkbox-icon{
                //     color: a.$black !important;

                // }
            }
        }
    }
}


/* Custom size beside label */
.side .p-checkbox{
    width: 15px !important;
    height: 15px !important;
    .p-checkbox-box {
        width: 15px !important;
        height: 15px !important;
    
        .p-checkbox-icon {
            font-size: 9px !important;
        }
    }
}
