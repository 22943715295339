.layout-filter {
    width: 250px;
    height: 100%;
    right: -32px;
    position: absolute;
    background: #ffffff;
    z-index: 97;
    border-left: 0 none;
    transform: translateX(100%);
    transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
    // overflow: hidden;
    &.layout-filter-active {
      right: 0px;
      transform: translateX(0);
    }
  }

.layout-filter-task {
    width: 250px;
    height: 100%;
    right: -32px;
    position: fixed;
    background: #ffffff;
    z-index: 97;
    border-left: 0 none;
    transform: translateX(100%);
    transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
    // overflow: hidden;
    &.layout-filter-active {
      right: 0px;
      transform: translateX(0);
    }
  }
.layout-filter-static {
    width: 250px;
    height: 100%;
    right: -32px;
    position: absolute;
    background: #ffffff;
    z-index: 97;
    border-left: 0 none;
    transform: translateX(100%);
      &.layout-filter-active {
        right: 0px;
        transform: translateX(0); 
      }
}
.ag-grid-table-full{
  width: 100%;
  margin-right: 0;
  transition: transform 10s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}
.ag-grid-table{
  width: calc(100% - 265px) !important;

}

.cs-ov-info{
  right: -18px;
  position: fixed;
  background: #ffffff;
  z-index: 97;
  border-left: 0 none;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  // width: 418.5px;
  &.active{
    right: 0;///////////// ======= Preeti chnage 23/sep/2024 =========////////////////
    transform: translateX(0);
  }
}
.ast-ov-info{
  right: -18px;
  position: fixed;
  background: #ffffff;
  z-index: 97;
  border-left: 0 none;
  transform: translateX(100%);
  transition: transform 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  // width: 408.5px;
  &.active{
    right: 0;///////////// ======= Preeti chnage 23/sep/2024 =========////////////////
    transform: translateX(0);
  }
}
.form-details-full{
  width: calc(100% - 195px);
  margin-right: 0;
  transition: transform 10s cubic-bezier(0.05, 0.74, 0.2, 0.99);
  height: max-content;
}
.form-details{
  width: calc(100% - 621px);
  overflow: overlay;
  height: max-content;
}
