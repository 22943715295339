@use '../abstracts' as a;

/* Token */
.p-chips .p-component{
    width: 100% !important;
}
.p-chips .p-chips-multiple-container .p-chips-token{
    background: a.$primary-color !important;
    border-radius: 15px !important;
    color: a.$white !important;
    &-label{
        margin-top: -2px !important;
    }
}
.p-tag-value{
    font-family: a.$form-font-light !important;
    font-size: a.$normal-fontsize;
}

.status-ball{
    height: 10px;
    width: 10px;
    border-radius: 50%;
}